import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from "react";
import { TextField } from "@mui/material";

const Input = forwardRef((props, ref) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
    }
  }, [props.value]);

  const handleChange = (e) => {
    if (props.handleChange) {
      props.handleChange(e.target.value);
    }
    setInputValue(e.target.value);
  };

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      ref.current.focus();
    },
    value: inputValue,
  }));

  return (
    <TextField
      type={props.type}
      label={props.label}
      variant="outlined"
      fullWidth
      size="small"
      inputRef={ref}
      sx={{ my: 1 }}
      value={inputValue}
      onChange={handleChange}
      InputProps={{
        readOnly: props.readOnly,
      }}
      inputProps={{
        max:
          props.type === "date" && props.max == null
            ? new Date().toISOString().split("T")[0]
            : undefined,
        rows: props.type === "textarea" ? 5 : undefined,
      }}
      multiline={props.type === "textarea"}
    />
  );
});

export default Input;
