import { faArrowRight, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import "../../../Styles/dataentry.scss";
import Button from "../../Util/Button";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import MultiSelect from "../../Util/MultiSelect";
import SignatureInput from "../../Util/SignatureInput";
import Select from "../../Util/Select";
import { useNavigate } from "react-router-dom";
export default function TAFNew(props) {
  const [active, setActive] = useState("Basic Details");
  const [editing, setEditing] = useState(null);
  //const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const e = localStorage.getItem("tafediting");

    setEditing(e);
  }, [active]);

  return (
    <div className="dataentry">
      <div className="new">
        <div className="topbar">
          <h4>New Technical Assistance Form</h4>
          <p
            onClick={() => {
              window.location.href = "/dataentry/taf-list";
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} /> List
          </p>
        </div>
        <div className="rmf">
          <Step1 setActive={setActive} editing={editing} />
        </div>
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <div
      onClick={() => {
        props.setActive(props.txt);
      }}
      className="item"
    >
      <p className={props.txt === props.active ? "active" : ""}>{props.txt}</p>
    </div>
  );
};

const Step1 = (props) => {
  const TATypes = [
    { value: "Operational", label: "Operational" },
    { value: "Management", label: "Management" },
    { value: "Engineering", label: "Engineering" },
    { value: "Capacity Building", label: "Capacity Building" },
    { value: "Finance", label: "Finance" },
    { value: "Data & Digital Solutions", label: "Data & Digital Solutions" },
  ];
  const TAThroughList = [
    { value: "Training", label: "Training" },
    { value: "Grant", label: "Grant" },
    {
      value: "Meeting/Coaching/Mentoring",
      label: "Meeting/Coaching/Mentoring",
    },
    { value: "Other", label: "Other" },
  ];

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [beneficiaryList, setBeneficiaryList] = useState();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [TATypeOptions, setTATypeOptions] = useState("");
  const [TAThroughOptions, setTAThroughOptions] = useState("");
  const [so, setSO] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [body, setBody] = useState({
    Beneficiary: null,
    BeneficiaryType: null,
    County: null,
    SubCounty: null,
    Ward: null,
    Village: null,
    Date: null,
    Longitude: null,
    Latitude: null,
    ContactName: null,
    ContactPhone: null,
    Designation: null,
    TA_Provided: null,
    TAThrough: null,
    TADescription: null,
    ERepName: null,
    ERepDesignation: null,
    WKWPRepName: null,
    WKWPRepDesignation: null,
    File: null,
    UserID: null,
  });
  const date = useRef();
  const Longitude = useRef();
  const Latitude = useRef();
  const ContactName = useRef();
  const ContactPhone = useRef();
  const Designation = useRef();
  const TA_Provided = useRef();
  const TAThrough = useRef();
  const TADescription = useRef();
  const ERepName = useRef();
  const ERepDesignation = useRef();
  const ERepSignature = useRef();
  const WKWPRepName = useRef();
  const WKWPRepDesignation = useRef();
  const WKWPRepSignature = useRef();
  const myFile = useRef();
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/auth`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          navigate("/");
          return;
        }
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((err) => {});
  }, []);

  const handleTATypeChange = (e) => {
    let string = e.join(", ");
    setTATypeOptions(string);
  };

  const handleTAThroughChange = (e) => {
    let string = e.join(", ");
    setTAThroughOptions(string);
  };

  const searchBeneficiary = (q) => {
    fetch(`/api/waterproviders/search/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          navigate("/");
          return;
        }
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setBeneficiaryList(data);
      })
      .catch((e) => {});
  };

  function searchRepName(role, q) {
    fetch(`/api/auth/seachbyname/${role}/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
         if (res.status === 401) {
           navigate("/");
           return;
         }  
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        console.log(data);
        setSO(data);
      })
      .catch((e) => {});
  }

  const createDocument = async (e) => {
    setError("");
    if (selectedBeneficiary == null) return setError("Select a beneficiary!");

    let d = body;
    d.Beneficiary = selectedBeneficiary.Name;
    d.BeneficiaryType = selectedBeneficiary.Type;
    d.County = selectedBeneficiary.County;
    d.SubCounty = selectedBeneficiary.SubCounty;
    d.Ward = selectedBeneficiary.Ward;
    d.Village = selectedBeneficiary.Village;
    d.Date = date.current.value;
    d.Longitude = Longitude.current.value;
    d.Latitude = Latitude.current.value;
    d.ContactName = ContactName.current.value;
    d.ContactPhone = ContactPhone.current.value;
    d.Designation = Designation.current.value;
    d.TA_Provided = TATypeOptions;
    d.TAThrough = TAThroughOptions;
    d.TADescription = TADescription.current.value;
    d.ERepName = ERepName.current.value;
    d.ERepDesignation = ERepDesignation.current.value;
    d.WKWPRepName = selectedRep ? selectedRep.Name : null;
    d.WKWPRepDesignation = selectedRep ? selectedRep.Position : null;
    d.File = myFile.current.files.length > 0 ? myFile.current.files[0] : null;
    d.UserID = selectedRep ? selectedRep.UserID : null;

    const cols = Object.keys(d);

    const keysToExclude = ["File"];
    const chck = Object.keys(d)
      .filter((key) => !keysToExclude.includes(key))
      .map((key) => d[key]);
    let valid = true;
    chck.map((item, i) => {
      if (item === null || item === "" || item === "Select") {
        valid = false;
        if (!valid) return setError(`${cols[i]} is required!`);
      }
    });
    

    if (d.File !== null) {
      d.File = await convertFileToBase64(d.File);
    }
    d.ERepSignature = ERepSignature.current.toDataURL();
    d.WKWPRepSignature = WKWPRepSignature.current.toDataURL();
    setBody(d);
    console.log(d);
    setLoading(true);

    fetch("/api/taf/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/");
          return;
        }
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/taf-list";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const BenList = (params) => {
    return (
      <div
        onClick={() => {
          setSelectedBeneficiary(params.item);
          setBeneficiaryList(null);
        }}
        className="so_list"
      >
        <h4>{params.item.Name}</h4>
        <p>Type of Beneficiary: {params.item.Type}</p>
        <p>County: {params.item.County}</p>
        <hr height="1" />
      </div>
    );
  };

  const SoList = (params) => {
    return (
      <div
        className="so_list"
        onClick={() => {
          setSelectedRep(params.item);
          setSO(null);
        }}
      >
        <h4>{params.item.Name}</h4>
        <p>Phone: {params.item.Phone}</p>
        <p>Position: {params.item.Position}</p>
      </div>
    );
  };

  return (
    <div className="dataentry">
      <div className="taf">
        <div className="search">
          <Input
            type="text"
            handleChange={(e) => {
              searchBeneficiary(e);
            }}
            label="Name of Beneficiary *"
            hint="Type to search"
            value={selectedBeneficiary ? selectedBeneficiary?.Name : ""}
          />
          {beneficiaryList && (
            <div className="search_list">
              {beneficiaryList.map((item, i) => {
                return <BenList key={i} item={item} />;
              })}
            </div>
          )}
        </div>
        <Input
          ref={date}
          type="date"
          value={new Date().toISOString().split("T")[0]}
          label="Date of Visit/TA *"
        />
        <div className="div2equal">
          <Input
            ref={Latitude}
            value={props?.data?.Latitude}
            type="number"
            label="Latitude (Use negative for values South of equator) *"
          />
          <Input
            ref={Longitude}
            value={props?.data?.Longitude}
            type="number"
            label="Longitude *"
          />
        </div>
        <div className="div3equal">
          <Input ref={ContactName} type="text" label="Contact Person Name *" />
          <Input
            ref={ContactPhone}
            type="number"
            label="Contact Person Phone *"
          />
          <Input
            ref={Designation}
            type="text"
            label="Contact Person Designation *"
          />
        </div>
        <div className="div2equal">
          <MultiSelect
            ref={TA_Provided}
            label="Type of TA Provided *"
            options={TATypes}
            name="TAType"
            setChanged={handleTATypeChange}
          />
          <MultiSelect
            ref={TAThrough}
            label="TA Through *"
            options={TAThroughList}
            name="TAThrough"
            setChanged={handleTAThroughChange}
          />
        </div>
        <br />
        <Input
          ref={TADescription}
          type="textarea"
          label="Brief Description of TA Provided *"
        />

        <h3>Sign Off - WKWP Representative</h3>
        <div className="div2equal">
          <div className="search">
            <Select
              setChanged={(v) => {
                let d = data.filter((obj) => obj.Name === v);
                if (d.length > 0) {
                  setSelectedRep(d[0]);
                }
                console.log(selectedRep);
              }}
              data={data.map((obj) => obj.Name)}
              label="Select WKWP Representative *"
            />
            {selectedRep && (
              <div>
                <h4>
                  <b>Name:</b> {selectedRep.Name}
                </h4>
                <h4>
                  <b>Designation:</b> {selectedRep.Position}
                </h4>
              </div>
            )}
          </div>
          <SignatureInput ref={WKWPRepSignature} label="Entity Represenative" />
        </div>

        <h3>Sign Off - Entity Representative</h3>
        <div className="div2equal">
          <div>
            <Input ref={ERepName} type="text" label="Name *" />
            <br />
            <Input ref={ERepDesignation} type="text" label="Designation *" />
          </div>
          <SignatureInput
            ref={ERepSignature}
            label="Entity Represenative Signature"
          />
        </div>

        <h3>Upload Scanned TAF (optional)</h3>

        <div className="file-input">
          <input ref={myFile} type="file" accept=".pdf" />
          <FontAwesomeIcon onClick={() => {}} icon={faTrash} className="ic" />
        </div>

        <h6>{error}</h6>
        <Button handleClick={createDocument} value="Submit" />
      </div>
      {loading && <Loading />}
    </div>
  );
};
