import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function ProtectedRoute({
  component: Component,
  ...restOfProps
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);


  useEffect(() => {
      setIsAuthenticated(true);
  }, []);


  return isAuthenticated ? (
    <Component {...restOfProps} />
  ) : (
    <Navigate to="/" replace />
  );
}
