import { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Input from "../Util/Input";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Alert } from "@mui/material";

export default function ForgetPassword(props) {
  const rfEmail = useRef();
  const [isError, setIsError] = useState();
  const [body, updateBody] = useState({
    Email: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const forgetPassword = () => {
    let d = body;
    d.Email = rfEmail.current.value.toLowerCase().trim();
    updateBody(d);

    if (!verifyEmail(body.Email)) {
      return setIsError("Please provide a valid email address!");
    }

    if (verifyEmail(body.Email)) {
      setIsSubmitting(true);
      props.setLoading(true);
      fetch("/api/auth/forgot", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("Failed");
        })
        .then((data) => {
          props.setLoading(false);
          setIsSubmitting(false);
          if (data.success) {
            setIsError(data.success);
            setTimeout(() => {
              props.setToggleForgot(false);
            }, 2000);
          } else {
            setIsError(data.error);
          }
        })
        .catch((error) => {
          props.setLoading(false);
          setIsSubmitting(false);
          setIsError("Something went wrong!");
        });
    }
  };

  const verifyEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/.,;:\s@"]+(\.[^<>()[\]/.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Dialog open={true} onClose={() => props.setToggleForgot(false)}>
      <DialogTitle>
        Reset Password
        <IconButton
          aria-label="close"
          onClick={() => props.setToggleForgot(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography textAlign="center" variant="body2" gutterBottom>
          Enter a valid email address. A password will be generated and sent to
          your email account
        </Typography>
        <Input ref={rfEmail} type="email" label="Email address" />
        {isError && <Alert severity="error">{isError}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={forgetPassword}
          color="primary"
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
