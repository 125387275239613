import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import NotFound from "./Pages/404";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import TBQuestionnaire from "./components/ToolBuilder/TBQuestionnaire";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/wkwp/questionnaire/*" element={<TBQuestionnaire />} />
          <Route path="/*" element={<ProtectedRoute component={Home} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
