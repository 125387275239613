import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import GaugeChart from "react-gauge-chart";
import { FaHandHoldingWater } from "react-icons/fa";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { FiRefreshCcw } from "react-icons/fi";
import { MdOutlineManageHistory } from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";
import "../../Styles/stats.scss";
import agreements from "../../assets/imgs/agreements.png";
import budget from "../../assets/imgs/budget.png";
import catchment from "../../assets/imgs/catchment.png";
import climate from "../../assets/imgs/climate.png";
import digital from "../../assets/imgs/digital.png";
import ecosystem from "../../assets/imgs/ecosystem.png";
import governance from "../../assets/imgs/governance.png";
import instruments from "../../assets/imgs/instruments.png";
import laws from "../../assets/imgs/laws.png";
import leveraged from "../../assets/imgs/leveraged.png";
import mobilized from "../../assets/imgs/mobilized.png";
import nrw from "../../assets/imgs/nrw.png";
import o_m from "../../assets/imgs/o_m.png";
import regulated from "../../assets/imgs/regulate.png";
import sdm from "../../assets/imgs/sdm.png";
import training from "../../assets/imgs/training.png";
import Input from "../Util/Input";
import BoxItem from "./BoxItem";
import CustomBarChart from "./CustomBarChart";
import { Tabs, Tab, Grid2, Stack, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
export default function Stats(props) {
  const [data, setData] = useState({});
  const [charts, setCharts] = useState(null);
  const [cCharts, setCcharts] = useState(null);
  const [showing, setShowing] = useState(true);
  const [year, setYear] = useState("2023");
  const [selected, setSelected] = useState("Main");
  const [active, setActive] = useState(year);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const today = new Date();
    setStart(`${today.getFullYear() - 1}-10-01`);
    setEnd(`${today.getFullYear()}-09-30`);
    if (start !== "" && end !== "") {
      getData();
    }
  }, [refresh]);

  function getData() {
    getCrossCutting();
    getChartsBasedOnClickedItem();
    fetch(`/api/rmf/home/charts/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        setCharts(dt);
      })
      .catch((e) => {});
  }

  useEffect(() => {
    if (start !== "" && end !== "") {
      getData();
    }
  }, [start, end]);

  async function getCrossCutting() {
    fetch(`/api/rmf/home/crosscutting/null/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        const combinedData = { ...data, ...dt };
        setData(combinedData);
        getUrban(combinedData);
      })
      .catch((e) => {});
  }
  async function getUrban(dt1) {
    fetch(`/api/rmf/home/urbancomponent/null/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        const combinedData = { ...dt1, ...dt };
        setData(combinedData);
        getRural(combinedData);
      })
      .catch((e) => {});
  }

  async function getRural(dt1) {
    fetch(`/api/rmf/home/ruralcomponent/null/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        const combinedData = { ...dt1, ...dt };
        setData(combinedData);
        getWRM(combinedData);
      })
      .catch((e) => {});
  }
  async function getWRM(dt1) {
    fetch(`/api/rmf/home/wrmcomponent/null/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        const combinedData = { ...dt1, ...dt };
        setData(combinedData);
        getFinance(combinedData);
      })
      .catch((e) => {});
  }

  async function getFinance(dt1) {
    fetch(`/api/rmf/home/financecomponent/null/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        const combinedData = { ...dt1, ...dt };
        setData(combinedData);
        getPolicy(combinedData);
      })
      .catch((e) => {});
  }

  async function getPolicy(dt1) {
    fetch(`/api/rmf/home/policycomponent/null/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((dt) => {
        const combinedData = { ...dt1, ...dt };
        setData(combinedData);
      })
      .catch((e) => {});
  }

  async function getChartsBasedOnClickedItem() {
    fetch(`/api/rmf/ind/pg/charts/${start}/${end}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setCcharts(data);
      })
      .catch((err) => {});
  }

  const handleTabChange = (event, newValue) => {
    setSelected(newValue);
  };

  return (
    <>
      {showing && (
        <Box
          className="stats"
          sx={{ overflowY: "auto", height: "100vh", padding: 2 }}
        >
          <Typography variant="h4" color="primary" gutterBottom>
            Dashboard
          </Typography>
          <Box
            className="welcome"
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
          >
            <Box className="options" sx={{ flexGrow: 1 }}>
              <Tabs
                value={selected}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  label="Main"
                  value="Main"
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Rural"
                  value="Rural"
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Urban"
                  value="Urban"
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="WRM"
                  value="WRM"
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Finance"
                  value="Finance & Private Sector Engagement"
                  sx={{ textTransform: "capitalize" }}
                />
                <Tab
                  label="Policy"
                  value="Policy & Governance"
                  sx={{ textTransform: "capitalize" }}
                />
              </Tabs>
            </Box>
            <Input
              handleChange={(e) => {
                setStart(e);
              }}
              type="date"
              value={start}
              label="Start Date"
            />
            <Input
              handleChange={(e) => {
                setEnd(e);
              }}
              type="date"
              value={end}
              label="End Date"
            />

            <FiRefreshCcw
              onClick={() => {
                setRefresh(!refresh);
              }}
              className="refresh"
            />
          </Box>

          {selected === "Main" && <Top data={data} />}
          {selected === "Rural" && (
            <Rural data={data} charts={charts} cCharts={cCharts} />
          )}
          {selected === "Urban" && (
            <Urban data={data} charts={charts} cCharts={cCharts} />
          )}
          {selected === "WRM" && (
            <WRM data={data} charts={charts} cCharts={cCharts} />
          )}
          {selected === "Finance & Private Sector Engagement" && (
            <Finance data={data} charts={charts} cCharts={cCharts} />
          )}
          {selected === "Policy & Governance" && (
            <Policy data={data} charts={charts} cCharts={cCharts} />
          )}
        </Box>
      )}
    </>
  );
}

const Top = (props) => {
  const p1ref = useRef();
  const [data, setData] = useState(null);
  const [chart1, setChart1] = useState(null);
  const [chart2, setChart2] = useState(null);
  const theme = useTheme();

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    setData(props.data);
    if (props.data) {
      setChart1([
        { name: "Male", value: Math.round(props.data["WKW-1"] / 2) },
        {
          name: "Female",
          value: Math.round(props.data["WKW-1"] / 1.9),
        },
      ]);
      setChart2([
        { name: "Male", value: Math.round(props.data["WKW-2"] / 2) },
        { name: "Female", value: Math.round(props.data["WKW-2"] / 1.9) },
      ]);
    }
  }, [props.data]);
  return (
    <>
      <Grid2 mt={2} container spacing={3}>
        <Grid2 item size={{ xs: 12, md: 3 }} className="left">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <FaHandHoldingWater size={44} color="primary" />
                <Box flexGrow={1}>
                  <Typography
                    variant="h6"
                    textAlign="right"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    {data && data["WKW-1"] ? withCommas(data["WKW-1"]) : 0}
                  </Typography>
                  <Typography variant="body2" textAlign="right" color="primary">
                    People gaining access to basic drinking water
                  </Typography>
                </Box>
              </Box>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <RiCustomerService2Line size={44} color="primary" />
                <Box flexGrow={1}>
                  <Typography
                    variant="h6"
                    textAlign="right"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    {data && data["WKW-2"] ? withCommas(data["WKW-2"]) : 0}
                  </Typography>
                  <Typography variant="body2" textAlign="right" color="primary">
                    People receiving improved service quality
                  </Typography>
                </Box>
              </Box>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <MdOutlineManageHistory size={44} color="primary" />
                <Box flexGrow={1}>
                  <Typography
                    variant="h6"
                    textAlign="right"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    {data && data["WKW-4"] ? withCommas(data["WKW-4"]) : 0}
                  </Typography>
                  <Typography variant="body2" textAlign="right" color="primary">
                    People benefiting from improved WRM
                  </Typography>
                </Box>
              </Box>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <FaMoneyBill1Wave size={44} color="primary" />
                <Box flexGrow={1}>
                  <Typography
                    variant="h6"
                    textAlign="right"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    US${" "}
                    {data && data["WKW-4.3"] ? withCommas(data["WKW-4.3"]) : 0}
                  </Typography>
                  <Typography variant="body2" textAlign="right" color="primary">
                    New funding mobilized to the water sector
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Stack>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }} className="right">
          <Card
            sx={{
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              height: "100%",
            }}
          >
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                margin="auto"
              >
                <Typography variant="h4" color="primary">
                  WKWP is a 5-year Water Project funded by USAID
                </Typography>
                <Typography variant="body1">The Project goal is to:</Typography>
                <ul style={{ paddingLeft: 20 }}>
                  <Typography variant="body2" gutterBottom>
                    <li>
                      Increase the availability and access to rural and urban
                      water services, and
                    </li>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <li>The sustainable management of water resources</li>
                  </Typography>
                </ul>
                <Typography variant="body1">
                  The project is targeting eight Lake Region counties -{" "}
                  <b>
                    Bungoma, Busia, Kakamega, Siaya, Homabay, Kisii, Kisumu, and
                    Migori.
                  </b>
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }} className="right">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="subtitle1">
                Access to Basic Drinking Water
              </Typography>
              <GaugeChart
                className="gg"
                id="gauge-chart2"
                nrOfLevels={3}
                colors={["red", "orange", "green"]}
                arcWidth={0.3}
                percent={
                  data
                    ? data["WKW-1"]
                      ? data["WKW-1"] / 500000 > 1
                        ? 1
                        : data["WKW-1"] / 500000
                      : 0
                    : 0
                }
                textColor="gray"
              />
              <Typography variant="body2">
                Performance: {withCommas(data && data["WKW-1"])}
              </Typography>
              <Typography variant="body2">
                Target: {withCommas(500000)}
              </Typography>
              {data && data["WKW-1"] / 500000 > 1 && (
                <Typography variant="body2">
                  Actual Performance:{" "}
                  {((data["WKW-1"] / 500000) * 100).toFixed(2)} %
                </Typography>
              )}
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="subtitle1">
                Access to Improved Services Delivery
              </Typography>
              <GaugeChart
                className="gg"
                id="gauge-chart2"
                nrOfLevels={3}
                colors={["red", "orange", "green"]}
                arcWidth={0.3}
                percent={
                  data
                    ? data["WKW-2"]
                      ? data["WKW-2"] / 300000 > 1
                        ? 1
                        : data["WKW-2"] / 300000
                      : 0
                    : 0
                }
                textColor="gray"
              />
              <Typography variant="body2">
                Performance: {withCommas(data && data["WKW-2"])}
              </Typography>
              <Typography variant="body2">
                Target: {withCommas(300000)}
              </Typography>
              {data && data["WKW-2"] / 300000 > 1 && (
                <Typography variant="body2">
                  Actual Performance:{" "}
                  {((data["WKW-2"] / 300000) * 100).toFixed(2)} %
                </Typography>
              )}
            </Card>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};
const Rural = (props) => {
  const p1ref = useRef();
  const [data, setData] = useState(null);
  const [chartTitle, setChartTitle] = useState("County Distribution");
  const [clickedItemCode, setClickedItemCode] = useState("");
  const [active, setActive] = useState("");

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  const handleClick = (title, code) => {
    setChartTitle(title);
    setActive(title);
    setClickedItemCode(code);
  };
  return (
    <>
      <Grid2 mt={2} container spacing={3}>
        <Grid2 item size={{ xs: 12, md: 3 }} className="left">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <FaHandHoldingWater size={44} color="#002F6C" />
                <Box flexGrow={1} textAlign="right">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    {data && data["WKW-2.1"] ? withCommas(data["WKW-2.1"]) : 0}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    Water service delivery models
                  </Typography>
                </Box>
              </Box>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <RiCustomerService2Line size={44} color="#002F6C" />
                <Box flexGrow={1} textAlign="right">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    {data && data["WKW-2.2"] ? withCommas(data["WKW-2.2"]) : 0}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    Regulated rural enterprises
                  </Typography>
                </Box>
              </Box>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <MdOutlineManageHistory size={44} color="#002F6C" />
                <Box flexGrow={1} textAlign="right">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    {data && data["WKW-2.3"] ? withCommas(data["WKW-2.3"]) : 0}
                  </Typography>
                  <Typography variant="body2" color="#002F6C">
                    Drinking Water Systems
                  </Typography>
                </Box>
              </Box>
            </Card>

            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box display="flex" gap={2} alignItems="center">
                <FaMoneyBill1Wave size={44} color="#002F6C" />
                <Box flexGrow={1} textAlign="right">
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    color="secondary"
                    gutterBottom
                  >
                    {data && data["WKW-2.4"] ? withCommas(data["WKW-2.4"]) : 0}
                  </Typography>
                  <Typography variant="body2" color="primary">
                    Microenterprises supported by USG assistance
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Stack>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 9 }} className="right">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              margin="auto"
            >
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">Service delivery</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  colors={["red", "orange", "green"]}
                  arcWidth={0.2}
                  percent={
                    data
                      ? data["WKW-2.1"]
                        ? data["WKW-2.1"] / 2 > 1
                          ? 1
                          : data["WKW-2.1"] / 2
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-2.1"])}
                </Typography>
                <Typography variant="body2">Target: {withCommas(2)}</Typography>
                {data && data["WKW-2.1"] / 2 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-2.1"] / 2) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>

              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">
                  Regulatory oversight
                </Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={5}
                  colors={["red", "orange", "green"]}
                  arcWidth={0.3}
                  percent={
                    data
                      ? data["WKW-2.2"]
                        ? data["WKW-2.2"] / 26 > 1
                          ? 1
                          : data["WKW-2.2"] / 26
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-2.2"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(26)}
                </Typography>
                {data && data["WKW-2.2"] / 26 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-2.2"] / 26) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>

              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">
                  Drinking Water Systems
                </Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={30}
                  colors={["red", "orange", "green"]}
                  arcWidth={0.3}
                  percent={
                    data
                      ? data["WKW-2.3"]
                        ? data["WKW-2.3"] / 35 > 1
                          ? 1
                          : data["WKW-2.3"] / 35
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-2.3"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(35)}
                </Typography>
                {data && data["WKW-2.3"] / 35 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-2.3"] / 35) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
            </Box>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                margin="auto"
              >
                <Typography variant="body1">{chartTitle}</Typography>
                {clickedItemCode ? (
                  <CustomBarChart
                    data={props?.cCharts?.[clickedItemCode]}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                ) : (
                  <CustomBarChart
                    data={props?.charts?.PgPolicies}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                )}
              </Box>
            </Card>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

const Urban = (props) => {
  const p1ref = useRef();
  const [data, setData] = useState(null);
  const [chartTitle, setChartTitle] = useState("County Distribution");
  const [clickedItemCode, setClickedItemCode] = useState("");
  const [active, setActive] = useState("");

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleClick = (title, code) => {
    setChartTitle(title);
    setActive(title);
    setClickedItemCode(code);
  };
  return (
    <>
      <Grid2 mt={2} container spacing={3}>
        <Grid2 item size={{ xs: 12, md: 3 }} className="left">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <BoxItem
              amount={data && data["WKW-1.2"] ? data["WKW-1.2"] : 0}
              title="Number"
              unit=""
              percent=""
              img={sdm}
              description="WSPs implementing PIPs informed by system data"
              onClick={() => {
                handleClick(
                  "WSPs implementing PIPs informed by system data",
                  "WKW12"
                );
              }}
              active={
                active === "WSPs implementing PIPs informed by system data"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-1.3"] ? data["WKW-1.3"] : 0}
              title="Number"
              unit=""
              percent=""
              img={sdm}
              description="WSPs with Water Safety Plans approved"
              onClick={() => {
                handleClick("WSPs with Water Safety Plans approved", "WKW13");
              }}
              active={
                active === "WSPs with Water Safety Plans approved" ? active : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-1.4"] ? data["WKW-1.4"] : 0}
              title="Number"
              unit=""
              percent=""
              img={o_m}
              description="WSPs with Business Plans"
              onClick={() => {
                handleClick("WSPs with Business Plans", "WKW14");
              }}
              active={active === "WSPs with Business Plans" ? active : ""}
            />

            <BoxItem
              amount={data && data["WKW-1.5"] ? data["WKW-1.5"] : 0}
              title="Percentage"
              unit=""
              percent="%"
              img={nrw}
              description="Reduction in NRW in targeted WSPs"
              current={data && data["currWKW-1.5"] ? data["currWKW-1.5"] : 0}
              baseline={data && data["blineWKW-1.5"] ? data["blineWKW-1.5"] : 0}
              onClick={() => {
                handleClick("Reduction in NRW in targeted WSPs", "WKW15");
              }}
              active={
                active === "Reduction in NRW in targeted WSPs" ? active : ""
              }
            />
          </Stack>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 9 }} className="right">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              margin="auto"
            >
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">NRW</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={10}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-1.5"]
                        ? data["WKW-1.5"] / -2 > 1
                          ? 1
                          : data["WKW-1.5"] / -2
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-1.5"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(-2)}
                </Typography>
                {data && data["WKW-1.5"] / -2 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-1.5"] / -2) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>

              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">O&M Coverage</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={30}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-1.1"]
                        ? data["WKW-1.1"] / 39 > 1
                          ? 1
                          : data["WKW-1.1"] / 39
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-1.1"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(39)}
                </Typography>
                {data && data["WKW-1.1"] / 39 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-1.1"] / 39) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>

              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">
                  Water Safety Plans Approved
                </Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={3}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-1.3"]
                        ? data["WKW-1.3"] / 2 > 1
                          ? 1
                          : data["WKW-1.3"] / 2
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-1.3"])}
                </Typography>
                <Typography variant="body2">Target: {withCommas(2)}</Typography>
                {data && data["WKW-1.3"] / 2 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-1.3"] / 2) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
            </Box>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                margin="auto"
              >
                <Typography variant="body1">{chartTitle}</Typography>
                {clickedItemCode ? (
                  <CustomBarChart
                    data={props?.cCharts?.[clickedItemCode]}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                ) : (
                  <CustomBarChart
                    data={props?.charts?.PgPolicies}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                )}
              </Box>
            </Card>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

const WRM = (props) => {
  const p1ref = useRef();
  const [data, setData] = useState(null);
  const [chartTitle, setChartTitle] = useState(
    "People benefiting from improved Water Resource Management"
  );
  const [clickedItemCode, setClickedItemCode] = useState("");
  const [active, setActive] = useState("");

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleClick = (title, code) => {
    setChartTitle(title);
    setActive(title);
    setClickedItemCode(code);
  };
  return (
    <>
      <Grid2 mt={2} container spacing={3}>
        <Grid2 item size={{ xs: 12, md: 3 }} className="left">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <BoxItem
              amount={data && data["WKW-3.4"] ? data["WKW-3.4"] : 0}
              description="WRUAs trained on effective WRM Practices"
              title="Number"
              unit=""
              percent=""
              img={training}
              onClick={() =>
                handleClick("WRUAs trained on effective WRM Practices", "WKW34")
              }
              active={
                active === "WRUAs trained on effective WRM Practices"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-3.3"] ? data["WKW-3.3"] : 0}
              description="No of Sub-Catchment Management Plans"
              title="Number"
              unit=""
              percent=""
              img={catchment}
              onClick={() =>
                handleClick("No of Sub-Catchment Management Plans", "WKW33")
              }
              active={
                active === "No of Sub-Catchment Management Plans" ? active : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-3.2"] ? data["WKW-3.2"] : 0}
              description="Payment for Ecosystem Services Action Plans Developed"
              title="Amount"
              unit="US$ "
              percent=""
              img={ecosystem}
              onClick={() =>
                handleClick(
                  "Payment for Ecosystem Services Action Plans Developed",
                  "WKW32"
                )
              }
              active={
                active ===
                "Payment for Ecosystem Services Action Plans Developed"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-3.1"] ? data["WKW-3.1"] : 0}
              description="Digital Solutions Adopted and Operationalised"
              title="Percent"
              unit=""
              percent="%"
              img={digital}
              onClick={() =>
                handleClick(
                  "Digital Solutions Adopted and Operationalised",
                  "WKW31"
                )
              }
              active={
                active === "Digital Solutions Adopted and Operationalised"
                  ? active
                  : ""
              }
            />
          </Stack>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 9 }} className="right">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              margin="auto"
            >
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">WRUAs Trained</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={3}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-3.4"]
                        ? data["WKW-3.4"] / 20 > 1
                          ? 1
                          : data["WKW-3.4"] / 20
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-3.4"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(20)}
                </Typography>
                {data && data["WKW-3.4"] / 20 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-3.4"] / 20) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">
                  Sub-catchment management
                </Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={10}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-3.3"]
                        ? data["WKW-3.3"] / 6 > 1
                          ? 1
                          : data["WKW-3.3"] / 6
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-3.3"])}
                </Typography>
                <Typography variant="body2">Target: {withCommas(6)}</Typography>
                {data && data["WKW-3.3"] / 6 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-3.3"] / 6) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">Digital solutions</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={10}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-3.1"]
                        ? data["WKW-3.1"] / 20 > 1
                          ? 1
                          : data["WKW-3.1"] / 20
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-3.1"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(20)}
                </Typography>
                {data && data["WKW-3.1"] / 20 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-3.1"] / 20) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
            </Box>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                margin="auto"
              >
                <Typography variant="body1">{chartTitle}</Typography>
                {clickedItemCode ? (
                  <CustomBarChart
                    data={props?.cCharts?.[clickedItemCode]}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                ) : (
                  <CustomBarChart
                    data={props?.charts?.PgPolicies}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                )}
              </Box>
            </Card>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

const Finance = (props) => {
  const p1ref = useRef();
  const [data, setData] = useState(null);
  const [chartTitle, setChartTitle] = useState(
    "Value of new funding mobilized to the water and sanitation sector"
  );
  const [clickedItemCode, setClickedItemCode] = useState("");
  const [active, setActive] = useState("");

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleClick = (title, code) => {
    setChartTitle(title);
    setActive(title);
    setClickedItemCode(code);
  };
  return (
    <>
      <Grid2 mt={2} container spacing={3}>
        <Grid2 item size={{ xs: 12, md: 3 }} className="left">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <BoxItem
              amount={data && data["WKW-4.1"] ? data["WKW-4.1"] : 0}
              description="Instruments Set up to Mobilize Private/Public Sector"
              title="Number"
              unit=""
              percent=""
              img={instruments}
              onClick={() =>
                handleClick(
                  "Instruments Set up to Mobilize Private/Public Sector",
                  "WKW41"
                )
              }
              active={
                active ===
                "Instruments Set up to Mobilize Private/Public Sector"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-4.3"] ? data["WKW-4.3"] : 0}
              description="New Funding Mobilized to the Water Sector"
              title="Value"
              unit="US$"
              percent=""
              img={mobilized}
              onClick={() =>
                handleClick(
                  "New Funding Mobilized to the Water Sector",
                  "WKW43"
                )
              }
              active={
                active === "New Funding Mobilized to the Water Sector"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-4.6"] ? data["WKW-4.6"] : 0}
              description="Value of Private Sector Resources Leveraged"
              title="Value"
              unit="US$"
              percent=""
              img={leveraged}
            />

            <BoxItem
              amount={data && data["WKW-4.4"] ? data["WKW-4.4"] : 0}
              description="Number of Agreements Formalized"
              title="Number"
              unit=""
              percent=""
              img={agreements}
            />
          </Stack>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 9 }} className="right">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              margin="auto"
            >
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">Instruments</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={3}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-4.1"]
                        ? data["WKW-4.1"] / 1 > 1
                          ? 1
                          : data["WKW-4.1"] / 1
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-4.1"])}
                </Typography>
                <Typography variant="body2">Target: {withCommas(1)}</Typography>
                {data && data["WKW-4.1"] / 1 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-4.1"] / 1) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">New Funding</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={10}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-4.3"]
                        ? data["WKW-4.3"] / 4000000 > 1
                          ? 1
                          : data["WKW-4.3"] / 4000000
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-4.3"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(4000000)}
                </Typography>
                {data && data["WKW-4.3"] / 4000000 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-4.3"] / 4000000) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">Agreements</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={30}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-4.4"]
                        ? data["WKW-4.4"] / 4 > 1
                          ? 1
                          : data["WKW-4.4"] / 4
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-4.4"])}
                </Typography>
                <Typography variant="body2">Target: {4}</Typography>
                {data && data["WKW-4.4"] / 4 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-4.4"] / 4) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
            </Box>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                margin="auto"
              >
                <Typography variant="body1">{chartTitle}</Typography>
                {clickedItemCode ? (
                  <CustomBarChart
                    data={props?.cCharts?.[clickedItemCode]}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                ) : (
                  <CustomBarChart
                    data={props?.charts?.FnFunding}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                )}
              </Box>
            </Card>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};

const Policy = (props) => {
  const p1ref = useRef();
  const [data, setData] = useState(null);
  const [chartTitle, setChartTitle] = useState("Developed Policies");
  const [clickedItemCode, setClickedItemCode] = useState("");
  const [active, setActive] = useState("");

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleClick = (title, code) => {
    setChartTitle(title);
    setActive(title);
    setClickedItemCode(code);
  };

  return (
    <>
      <Grid2 mt={2} container spacing={3}>
        <Grid2 item size={{ xs: 12, md: 3 }} className="left">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <BoxItem
              amount={data && data["WKW-5.3"] ? data["WKW-5.3"] : 0}
              description="Policies, Laws, and/or Guidelines Developed and/or Implemented"
              title="Number"
              unit=""
              percent=""
              img={laws}
              onClick={() => {
                handleClick(
                  "Policies, Laws, and/or Guidelines Developed and/or Implemented",
                  "WKW53"
                );
              }}
              active={
                active ===
                "Policies, Laws, and/or Guidelines Developed and/or Implemented"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-5.4"] ? data["WKW-5.4"] : 0}
              description="Officials trained on Corporate Governance"
              title="Number"
              unit=""
              percent=""
              img={governance}
              onClick={() =>
                handleClick(
                  "Officials trained on Corporate Governance",
                  "WKW54"
                )
              }
              active={
                active === "Officials trained on Corporate Governance"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-5.2"] ? data["WKW-5.2"] : 0}
              description="Institutions with improved capacity to address climate change risks"
              title="Number"
              unit=""
              percent=""
              img={climate}
              onClick={() =>
                handleClick(
                  "Institutions with improved capacity to address climate change risks",
                  "WKW52"
                )
              }
              active={
                active ===
                "Institutions with improved capacity to address climate change risks"
                  ? active
                  : ""
              }
            />

            <BoxItem
              amount={data && data["WKW-5.1"] ? data["WKW-5.1"] : 0}
              description="Increase in county budgetary allocations for Water"
              title="Percentage"
              unit=""
              percent="%"
              img={budget}
              onClick={() =>
                handleClick(
                  "Increase in county budgetary allocations for Water",
                  "WKW51"
                )
              }
              active={
                active === "Increase in county budgetary allocations for Water"
                  ? active
                  : ""
              }
            />
          </Stack>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 9 }} className="right">
          <Stack
            spacing={2}
            sx={{ height: "100%", justifyContent: "space-between" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={2}
              margin="auto"
            >
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">Policies</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={3}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-5.3"]
                        ? data["WKW-5.3"] / 4 > 1
                          ? 1
                          : data["WKW-5.3"] / 4
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-5.3"])}
                </Typography>
                <Typography variant="body2">Target: {withCommas(4)}</Typography>
                {data && data["WKW-5.3"] / 4 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-5.3"] / 4) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">Officials trained</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={10}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-5.4"]
                        ? data["WKW-5.4"] / 52 > 1
                          ? 1
                          : data["WKW-5.4"] / 52
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-5.4"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(52)}
                </Typography>
                {data && data["WKW-5.4"] / 52 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-5.4"] / 52) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
              <Card
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography variant="subtitle1">Institutions</Typography>
                <GaugeChart
                  className="gg"
                  id="gauge-chart2"
                  nrOfLevels={30}
                  colors={["red", "orange", "green"]}
                  percent={
                    data
                      ? data["WKW-5.2"]
                        ? data["WKW-5.2"] / 10 > 1
                          ? 1
                          : data["WKW-5.2"] / 10
                        : 0
                      : 0
                  }
                  textColor="gray"
                />
                <Typography variant="body2">
                  Performance: {withCommas(data && data["WKW-5.2"])}
                </Typography>
                <Typography variant="body2">
                  Target: {withCommas(10)}
                </Typography>
                {data && data["WKW-5.2"] / 10 > 1 && (
                  <Typography variant="body2">
                    Actual Performance:{" "}
                    {((data["WKW-5.2"] / 10) * 100).toFixed(2)} %
                  </Typography>
                )}
              </Card>
            </Box>
            <Card
              sx={{
                padding: 2,
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                gap={2}
                margin="auto"
              >
                <Typography variant="body1">{chartTitle}</Typography>
                {clickedItemCode ? (
                  <CustomBarChart
                    data={props?.cCharts?.[clickedItemCode]}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                ) : (
                  <CustomBarChart
                    data={props?.charts?.PgPolicies}
                    aspect={isMobile ? 1 : 3}
                    color="#BA0C2F"
                  />
                )}
              </Box>
            </Card>
          </Stack>
        </Grid2>
      </Grid2>
    </>
  );
};
