import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { FormControl, InputLabel, Select as MUISelect, MenuItem } from "@mui/material";

const Select = forwardRef((props, ref) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (props.value != null && props.value !== "") {
      setInputValue(props.value);
    }
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      ref.current.focus();
    },
    value: inputValue,
  }));

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (props.setChanged) {
      props.setChanged(e.target.value);
    }
  };

  return (
    <FormControl fullWidth variant="outlined" size="small" sx={{ my: 1 }}>
      <InputLabel>{props.label}</InputLabel>
      <MUISelect
        value={inputValue}
        onChange={handleChange}
        label={props.label}
        inputRef={ref}
        disabled={props.readOnly}
      >
        {props.data &&
          props.data.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
      </MUISelect>
    </FormControl>
  );
});

export default Select;
