import {
  faArrowRight,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import "../../../Styles/dataentry.scss";
import Button from "../../Util/Button";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import MultiSelect from "../../Util/MultiSelect";
import Select from "../../Util/Select";
import SignatureInput from "../../Util/SignatureInput";
import {
  Alert,
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid2,
  InputLabel,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function RMFUpdate(props) {
  const [active, setActive] = useState("Basic Details");
  const [data, setData] = useState(null);

  const pathname = window.location.pathname.split("/");
  const id = pathname[3];

  useEffect(() => {
    fetch(`/api/rmf/${id}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [active]);

  return (
    <div className="dataentry">
      <div className="new">
        <div className="topbar">
          <h4>Update Result Monitoring Form</h4>
          <p
            onClick={() => {
              window.location.href = "/dataentry/rmf-list";
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} /> List
          </p>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "left",
          }}
        >
          <Tabs
            sx={{ width: "fit-content", marginRight: "auto", display: "block" }}
            value={active}
            onChange={(e, v) => setActive(v)}
          >
            <Tab
              label="Basic Details"
              value="Basic Details"
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              label="Urban"
              value="Urban"
              sx={{ textTransform: "capitalize" }}
            />
            <Tab
              label="Rural"
              value="Rural"
              sx={{ textTransform: "capitalize" }}
            />
            <Tab label="WRM" value="WRM" sx={{ textTransform: "capitalize" }} />
            <Tab
              label="FSPE"
              value="FSPE"
              sx={{ textTransform: "capitalize" }}
            />
            <Tab label="P&G" value="P&G" sx={{ textTransform: "capitalize" }} />
            <Tab
              label="Sign Off"
              value="Sign Off"
              sx={{ textTransform: "capitalize" }}
            />
          </Tabs>
          <Divider sx={{ width: "100%", mt: "-5px" }} />
        </Box>
        <div className="rmf">
          {active === "Basic Details" && (
            <Step1 data={data} setActive={setActive} id={id} />
          )}
          {active === "Urban" && (
            <>
              <Step2A data={data} setActive={setActive} id={id} />
            </>
          )}
          {active === "Rural" && (
            <>
              <Step2B data={data} setActive={setActive} id={id} />
            </>
          )}
          {active === "WRM" && (
            <>
              <Step3 data={data} setActive={setActive} id={id} />
            </>
          )}
          {active === "FSPE" && (
            <>
              <Step4 data={data} setActive={setActive} id={id} />
            </>
          )}
          {active === "P&G" && (
            <>
              <Step5 data={data} setActive={setActive} id={id} />
            </>
          )}
          {active === "Sign Off" && (
            <>
              <Step6
                data={data}
                setActive={setActive}
                id={id}
                user={props.user}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const Step1 = (props) => {
  const TATypes = [
    { value: "Operational", label: "Operational" },
    { value: "Management", label: "Management" },
    { value: "Engineering", label: "Engineering" },
    { value: "Capacity Building", label: "Capacity Building" },
    { value: "Finance", label: "Finance" },
    { value: "Data & Digital Solutions", label: "Data & Digital Solutions" },
  ];
  const TAThroughList = [
    { value: "Training", label: "Training" },
    { value: "Grant", label: "Grant" },
    {
      value: "Meeting/Coaching/Mentoring",
      label: "Meeting/Coaching/Mentoring",
    },
    { value: "Other", label: "Other" },
  ];
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [stakeholderList, SetStakeholderList] = useState();
  const [selectedStakeholder, setSelectedStakeholder] = useState();
  const [countyList, setCountyList] = useState([]);
  const [subcountyList, setSubCountyList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [selCounty, setSelCounty] = useState(null);
  const [selSubCounty, setSelSubCounty] = useState(null);
  const [TATypeOptions, setTATypeOptions] = useState("");
  const [TAThroughOptions, setTAThroughOptions] = useState("");
  const [body, setBody] = useState({
    SectorStakeholder: null,
    StakeholderType: null,
    County: null,
    SubCounty: null,
    Ward: null,
    Village: null,
    Date: null,
    Longitude: null,
    Latitude: null,
    ContactName: null,
    ContactPhone: null,
    Designation: null,
    TA_Provided: null,
    TAThrough: null,
    // ChangeInSD: null,
    // Change: "N/A",
  });
  const date = useRef();
  const TA_Provided = useRef();
  const TAThrough = useRef();
  // const ChangeInSD = useRef();
  // const Change = useRef();

  const handleTATypeChange = (e) => {
    let string = e.join(", ");
    setTATypeOptions(string);
  };

  const handleTAThroughChange = (e) => {
    let string = e.join(", ");
    setTAThroughOptions(string);
  };

  const searchStakeholder = (q) => {
    fetch(`/api/waterproviders/search/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        SetStakeholderList(data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    fetch("/api/counties/uniquecounties", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setCountyList(data);
        if (data.length > 0) {
          setSelCounty(data[0]);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (selCounty) {
      fetch(`/api/counties/getsubcounties/${selCounty}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setSubCountyList(data);
          if (data.length > 0) setSelSubCounty(data[0]);
        })
        .catch((e) => {});
    }
  }, [selCounty]);

  useEffect(() => {
    if (selSubCounty) {
      fetch(`/api/counties/getwards/${selSubCounty}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWardList(data);
        })
        .catch((e) => {});
    }
  }, [selSubCounty]);

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.SectorStakeholder = selectedStakeholder.Name;
    d.StakeholderType = selectedStakeholder.Type;
    d.County = selectedStakeholder.County;
    d.SubCounty = selectedStakeholder.SubCounty;
    d.Ward = selectedStakeholder.Ward;
    d.Village = selectedStakeholder.Village
      ? selectedStakeholder.Village
      : "N/A";
    d.Date = date.current.value;
    d.Longitude = selectedStakeholder.Longitude;
    d.Latitude = selectedStakeholder.Latitude;
    d.ContactName = selectedStakeholder.ContactName;
    d.ContactPhone = selectedStakeholder.ContactPhone;
    d.Designation = selectedStakeholder.ContactDesignation;
    d.TA_Provided = TATypeOptions;
    d.TAThrough = TAThroughOptions;
    // d.ChangeInSD = ChangeInSD.current.value;
    // d.Change = Change.current.value;
    setBody(d);

    const cols = Object.keys(d);

    const keysToExclude = ["Change"];
    const chck = Object.keys(d)
      .filter((key) => !keysToExclude.includes(key))
      .map((key) => d[key]);
    let valid = true;
    chck.map((item, i) => {
      if (item === null || item === "" || item === "Select") {
        valid = false;
        if (!valid) return setError(`${cols[i]} is required!`);
      }
    });
    if (!valid) return;

    setLoading(true);

    fetch("/api/rmf/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          localStorage.setItem("editing", data.ID);
          setTimeout(() => {
            props.setActive("Urban");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const BenList = (params) => {
    return (
      <div
        onClick={() => {
          setSelectedStakeholder(params.item);
          SetStakeholderList(null);
        }}
        className="so_list"
      >
        <h4>{params.item.Name}</h4>
        <p>Type of Beneficiary: {params.item.Type}</p>
        <p>County: {params.item.County}</p>
        <hr height="1" />
      </div>
    );
  };

  const handleSignatureChange = (e) => {
    console.log(e);
  };

  return (
    <div className="taf">
      {props.id === null ? (
        <div className="search">
          <Input
            type="text"
            handleChange={(e) => {
              searchStakeholder(e);
            }}
            label="Search Beneficiary *"
            hint="Type to search"
            value={selectedStakeholder?.Name && selectedStakeholder.Name}
          />
          {stakeholderList && stakeholderList?.length > 0 && (
            <div className="search_list">
              {stakeholderList?.map((item, i) => {
                return <BenList key={i} item={item} />;
              })}
            </div>
          )}
        </div>
      ) : (
        <div className="benef-details">
          <p>
            <span className="info-label">Beneficiary:</span>{" "}
            <span className="info-value">{props?.data?.SectorStakeholder}</span>
          </p>
          <p>
            <span className="info-label">BeneficiaryType:</span>{" "}
            <span className="info-value">{props?.data?.StakeholderType}</span>
          </p>
          <p>
            <span className="info-label">Latitude:</span>{" "}
            <span className="info-value">{props?.data?.Latitude}</span>
          </p>
          <p>
            <span className="info-label">Longitude:</span>{" "}
            <span className="info-value">{props?.data?.Longitude}</span>
          </p>
          <p>
            <span className="info-label">County:</span>{" "}
            <span className="info-value">{props?.data?.County}</span>
          </p>
          <p>
            <span className="info-label">SubCounty:</span>{" "}
            <span className="info-value">{props?.data?.SubCounty}</span>
          </p>
          <p>
            <span className="info-label">Ward:</span>{" "}
            <span className="info-value">{props?.data?.SubCounty}</span>
          </p>
          <p>
            <span className="info-label">Village:</span>{" "}
            <span className="info-value">{props?.data?.Village}</span>
          </p>
          <p>
            <span className="info-label">Contact's Name:</span>{" "}
            <span className="info-value">{props?.data?.ContactName}</span>
          </p>
          <p>
            <span className="info-label">Contact's Phone:</span>{" "}
            <span className="info-value">{props?.data?.ContactPhone}</span>
          </p>
          <p>
            <span className="info-label">Contact's Designation:</span>{" "}
            <span className="info-value">{props?.data?.Designation}</span>
          </p>
        </div>
      )}
      <Input
        ref={date}
        value={new Date().toISOString().split("T")[0]}
        type="date"
        label="Date *"
      />
      <div className="div2equal">
        <MultiSelect
          ref={TA_Provided}
          label="Type of TA Provided *"
          options={TATypes}
          value={props?.data?.TA_Provided}
          name="TAType"
          setChanged={handleTATypeChange}
        />
        <MultiSelect
          ref={TAThrough}
          label="TA Through *"
          value={props?.data?.TAThrough}
          options={TAThroughList}
          name="TAThrough"
          setChanged={handleTAThroughChange}
        />
      </div>
      <br />
      {/* <Select
        ref={ChangeInSD}
        value={props?.data?.ChangeInSD}
        data={[" ", "Yes", "No"]}
        label="Has there been any change in service delivery since the last Technical Assistance? *"
      /> */}
      {/* <Input
        ref={Change}
        value={props?.data?.Change}
        label="If yes, what has changed (optional)"
      /> */}

      <h6>{error}</h6>
      {props.data === null && (
        <Button handleClick={createDocument} value="Next" />
      )}
      <br />
      {loading && <Loading />}
    </div>
  );
};
const Step2A = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [otherValue, setOtherValue] = useState("");
  const [body, setBody] = useState({
    RU_NRWM3: "",
    RU_NRW: "",
    RU_TotalBilled: "",
    RU_TotalCollected: "",
    RU_RevenueCollectionEfficiency: "",
    RU_OperatingCost: "",
    RU_CostCoverage: "",
    RU_BacterialTestDone: "",
    RU_BacterialTestsPassed: "",
    RU_ChemicalTestDone: "",
    RU_ChemicalTestsPassed: "",
    RU_WaterQualityTestDone: "",
    RU_WaterQualityResult: "",
    RU_Comment: "",
    RU_WaterPipelineConstructed: "",
    RU_NoWaterSystemsConstructed: "",
    RU_ConstructedWaterSystems: "",
    RU_OtherConstructedWaterSystems: "",
    RU_NWCIndividualConnection: "",
    RU_NWCYardTaps: "",
    RU_NWCMDU: "",
    RU_NWCKiosks: "",
    RU_Newbeneficiaries_NewConnections: "",
    RU_Beneficiaries_ImprovedService: "",
    RU_Newbeneficiaries_ProtectedSprings: "",
    RU_Beneficiaries_IS_ProtectedSprings: "",
    RU_QualityStandard: "",
    RU_WaterProduced: "",
    RU_RegistrationStatus: "",
    RU_RegulationStatus: "",
    RU_SDM_From: "",
    RU_SDM_To: "",
    RU_WaterStorage: "",
    RU_YieldCapacity: "",
    RU_ActualProduction: "",
    RU_SupplyHours: "",
    RU_New_Meters: "",
    RU_NonfunctionalMeters: "",
    RU_ManagementOrUserCom_Trained_Male: "",
    RU_ManagementOrUserCom_Trained_Female: "",
    RU_ManagementOrUserCom_Trained_Youth1: "",
    RU_ManagementOrUserCom_Trained_Youth2: "",
    RU_ManagementOrUserCom_Trained_Total: "",
  });

  const RU_NRWM3 = useRef();
  const RU_NRW = useRef();
  const RU_TotalBilled = useRef();
  const RU_TotalCollected = useRef();
  const RU_RevenueCollectionEfficiency = useRef();
  const RU_OperatingCost = useRef();
  const RU_CostCoverage = useRef();
  const RU_BacterialTestDone = useRef();
  const RU_BacterialTestsPassed = useRef();
  const RU_ChemicalTestDone = useRef();
  const RU_ChemicalTestsPassed = useRef();
  const RU_NoWaterSystemsConstructed = useRef();
  const RU_NWCIndividualConnection = useRef();
  const RU_NWCYardTaps = useRef();
  const RU_NWCMDU = useRef();
  const RU_NWCKiosks = useRef();
  const RU_Newbeneficiaries_NewConnections = useRef();
  const RU_Beneficiaries_ImprovedService = useRef();
  const RU_RegistrationStatus = useRef();
  const RU_WaterStorage = useRef();
  const RU_YieldCapacity = useRef();
  const RU_ActualProduction = useRef();
  const RU_SupplyHours = useRef();
  const RU_New_Meters = useRef();
  const RU_NonfunctionalMeters = useRef();

  const PG_County_Officials_Trained_Male = useRef();
  const PG_County_Officials_Trained_Female = useRef();
  const PG_County_Officials_Trained_Youth1 = useRef();
  const PG_County_Officials_Trained_Youth2 = useRef();
  const PG_County_Officials_Trained_Total = useRef();

  const PG_WSP_Committee_Trained_Male = useRef();
  const PG_WSP_Committee_Trained_Female = useRef();
  const PG_WSP_Committee_Trained_Youth1 = useRef();
  const PG_WSP_Committee_Trained_Youth2 = useRef();
  const PG_WSP_Committee_Trained_Total = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.RU_NRWM3 = RU_NRWM3.current.value;
    d.RU_NRW = RU_NRW.current.value;
    d.RU_TotalBilled = RU_TotalBilled.current.value;
    d.RU_TotalCollected = RU_TotalCollected.current.value;
    d.RU_RevenueCollectionEfficiency =
      RU_RevenueCollectionEfficiency.current.value;
    d.RU_OperatingCost = RU_OperatingCost.current.value;
    d.RU_CostCoverage = RU_CostCoverage.current.value;
    d.RU_BacterialTestDone = RU_BacterialTestDone.current.value;
    d.RU_BacterialTestsPassed = RU_BacterialTestsPassed.current.value;
    d.RU_ChemicalTestDone = RU_ChemicalTestDone.current.value;
    d.RU_ChemicalTestsPassed = RU_ChemicalTestsPassed.current.value;
    d.RU_NoWaterSystemsConstructed = RU_NoWaterSystemsConstructed.current.value;
    d.RU_NWCIndividualConnection = RU_NWCIndividualConnection.current.value;
    d.RU_NWCYardTaps = RU_NWCYardTaps.current.value;
    d.RU_NWCMDU = RU_NWCMDU.current.value;
    d.RU_NWCKiosks = RU_NWCKiosks.current.value;
    d.RU_Newbeneficiaries_NewConnections =
      RU_Newbeneficiaries_NewConnections.current.value;
    d.RU_Beneficiaries_ImprovedService =
      RU_Beneficiaries_ImprovedService.current.value;
    d.RU_RegistrationStatus = RU_RegistrationStatus.current.value;
    d.RU_WaterStorage = RU_WaterStorage.current.value;
    d.RU_YieldCapacity = RU_YieldCapacity.current.value;
    d.RU_ActualProduction = RU_ActualProduction.current.value;
    d.RU_SupplyHours = RU_SupplyHours.current.value;
    d.RU_New_Meters = RU_New_Meters.current.value;
    d.RU_NonfunctionalMeters = RU_NonfunctionalMeters.current.value;
    d.PG_County_Officials_Trained_Male =
      PG_County_Officials_Trained_Male.current.value;
    d.PG_County_Officials_Trained_Female =
      PG_County_Officials_Trained_Female.current.value;
    d.PG_County_Officials_Trained_Youth1 =
      PG_County_Officials_Trained_Youth1.current.value;
    d.PG_County_Officials_Trained_Youth2 =
      PG_County_Officials_Trained_Youth2.current.value;
    d.PG_County_Officials_Trained_Total =
      PG_County_Officials_Trained_Total.current.value;
    d.PG_WSP_Committee_Trained_Female =
      PG_WSP_Committee_Trained_Female.current.value;
    d.PG_WSP_Committee_Trained_Youth1 =
      PG_WSP_Committee_Trained_Youth1.current.value;
    d.PG_WSP_Committee_Trained_Youth2 =
      PG_WSP_Committee_Trained_Youth2.current.value;
    d.PG_WSP_Committee_Trained_Total =
      PG_WSP_Committee_Trained_Total.current.value;

    setBody(d);

    setLoading(true);

    fetch(`/api/rmf/${props?.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("Rural");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        Revenue Collection
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NRWM3}
            value={props?.data?.RU_NRWM3}
            label="Non-Revenue Water (m3)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NRW}
            value={props?.data?.RU_NRW}
            label="Percentage of Non-Revenue Water (%)"
          />
        </Grid2>
      </Grid2>

      <Typography variant="body2" gutterBottom>
        Revenue Collection
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={RU_TotalBilled}
            value={props?.data?.RU_TotalBilled}
            label="Total Billing (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={RU_TotalCollected}
            value={props?.data?.RU_TotalCollected}
            label="Total Collection (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={RU_RevenueCollectionEfficiency}
            value={props?.data?.RU_RevenueCollectionEfficiency}
            label="Revenue Collection Efficiency (%)"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        O&M Cost Coverage
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_OperatingCost}
            value={props?.data?.RU_OperatingCost}
            label="Operating Cost (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_CostCoverage}
            value={props?.data?.RU_CostCoverage}
            label="Cost Coverage (%)"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Water Quality Tests
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_BacterialTestDone}
            value={props?.data?.RU_BacterialTestDone}
            label="Bacterial Tests Done"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_BacterialTestsPassed}
            value={props?.data?.RU_BacterialTestsPassed}
            label="Bacterial Tests Passed"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_ChemicalTestDone}
            value={props?.data?.RU_ChemicalTestDone}
            label="Chemical Tests Done"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_ChemicalTestsPassed}
            value={props?.data?.RU_ChemicalTestsPassed}
            label="Chemical Tests Passed"
          />
        </Grid2>
      </Grid2>

      <Typography variant="body2" gutterBottom>
        Drinking Water Systems Constructed or rehabilitated
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NoWaterSystemsConstructed}
            value={props?.data?.RU_NoWaterSystemsConstructed}
            label="Number"
            handleChange={(e) => {
              console.log(e);
              if (parseInt(e) > 0 && e !== "") {
                setShowMultiSelect(true);
              } else {
                setShowMultiSelect(false);
                setBody((prevBody) => ({
                  ...prevBody,
                  RU_ConstructedWaterSystems: "",
                }));
              }
            }}
          />{" "}
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          {showMultiSelect ||
            (props?.data?.RU_NoWaterSystemsConstructed > 0 && (
              <FormControl fullWidth>
                <InputLabel shrink>Select Constructed Water Systems</InputLabel>
                <MultiSelect
                  options={[
                    {
                      value: "Borehole solarization",
                      label: "Borehole solarization",
                    },
                    {
                      value: "Storage tank construction",
                      label: "Storage tank construction",
                    },
                    {
                      value: "Pipeline extension",
                      label: "Pipeline extension",
                    },
                    {
                      value: "Distribution networks",
                      label: "Distribution networks",
                    },
                    {
                      value: "Kiosk construction/rehabilitation",
                      label: "Kiosk construction/rehabilitation",
                    },
                    {
                      value: "Installation of treatment unit",
                      label: "Installation of treatment unit",
                    },
                    {
                      value: "Electro-mechanical works/pump replacement",
                      label: "Electro-mechanical works/pump replacement",
                    },
                    {
                      value: "Spring protection",
                      label: "Spring protection",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                  value={
                    selectedOptions ||
                    props?.data?.RU_ConstructedWaterSystems?.toString()
                      .split("-")
                      .map((value) => ({ value, label: value }))
                  }
                  fullWidth
                  onChange={(selected) => {
                    setSelectedOptions(selected);
                    let constructedWaterSystems = selected
                      .map((option) => option.value)
                      .join("-");
                    if (selected.some((option) => option.value === "Other")) {
                      constructedWaterSystems += `-${otherValue}`;
                    }
                    setBody((prevBody) => ({
                      ...prevBody,
                      RU_ConstructedWaterSystems: constructedWaterSystems,
                    }));
                  }}
                  parseValueFromString={(valueString) =>
                    Array.isArray(valueString)
                      ? valueString
                      : valueString != null
                      ? valueString
                          .toString()
                          .split("-")
                          .map((value) => ({ value, label: value }))
                      : []
                  }
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
                />
              </FormControl>
            ))}
        </Grid2>
        <Grid2 item size={{ xs: 12 }}>
          {selectedOptions.includes("Other") && (
            <Input
              type="text"
              value={otherValue}
              label="Specify Other"
              handleChange={(e) => {
                const value = e.target.value;
                setOtherValue(value);
                setBody((prevBody) => {
                  const constructedWaterSystems = selectedOptions
                    .map((option) => (option === "Other" ? value : option))
                    .join("-");
                  return {
                    ...prevBody,
                    RU_ConstructedWaterSystems: constructedWaterSystems,
                  };
                });
              }}
            />
          )}
        </Grid2>
      </Grid2>

      <Typography variant="body2" gutterBottom>
        Number of new water connections
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCIndividualConnection}
            value={props?.data?.RU_NWCIndividualConnection}
            label="Individual Connections"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCYardTaps}
            value={props?.data?.RU_NWCYardTaps}
            label="Yard taps"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCMDU}
            value={props?.data?.RU_NWCMDU}
            label="MDU (small,medium,large)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCKiosks}
            value={props?.data?.RU_NWCKiosks}
            label="Kiosks"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Number of new beneficiaries
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_Newbeneficiaries_NewConnections}
            value={props?.data?.RU_Newbeneficiaries_NewConnections}
            label="From new connections"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_Beneficiaries_ImprovedService}
            value={props?.data?.RU_Beneficiaries_ImprovedService}
            label="From improved service"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Registration Status
      </Typography>
      <Select
        data={["Current", "Expired", "Not Registered", "Other"]}
        ref={RU_RegistrationStatus}
        value={props?.data?.RU_RegistrationStatus}
        label="Registration Status"
      />
      <Typography variant="body2" gutterBottom>
        Water Storage & Production
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_WaterStorage}
            value={props?.data?.RU_WaterStorage}
            label="Water storage (Cubic Meters)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_YieldCapacity}
            value={props?.data?.RU_YieldCapacity}
            label="Yield Capacity (Cubic Meters)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_ActualProduction}
            value={props?.data?.RU_ActualProduction}
            label="Actual Production (Cubic Meters)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            value={props?.data?.RU_SupplyHours}
            ref={RU_SupplyHours}
            label="Hours of supply (Hrs)"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Meters
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_New_Meters}
            value={props?.data?.RU_New_Meters}
            label="New metered connections"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NonfunctionalMeters}
            value={props?.data?.RU_NonfunctionalMeters}
            label="Non-functional meters activated/replaced"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Number of County and Governmental Officials Trained in corporate
        governance
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_County_Officials_Trained_Male}
            value={props?.data?.PG_County_Officials_Trained_Male}
            label="Male"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_County_Officials_Trained_Female}
            value={props?.data?.PG_County_Officials_Trained_Female}
            label="Female"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_County_Officials_Trained_Youth1}
            value={props?.data?.PG_County_Officials_Trained_Youth1}
            label="Youth I"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_County_Officials_Trained_Youth2}
            value={props?.data?.PG_County_Officials_Trained_Youth2}
            label="Youth II"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_County_Officials_Trained_Total}
            value={props?.data?.PG_County_Officials_Trained_Total}
            label="Total"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Number of WSPs BOD/Management Committee Members Trained in effective
        leadership/management practices
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_WSP_Committee_Trained_Male}
            value={props?.data?.PG_WSP_Committee_Trained_Male}
            label="Male"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_WSP_Committee_Trained_Female}
            value={props?.data?.PG_WSP_Committee_Trained_Female}
            label="Female"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_WSP_Committee_Trained_Youth1}
            value={props?.data?.PG_WSP_Committee_Trained_Youth1}
            label="Youth I"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_WSP_Committee_Trained_Youth2}
            value={props?.data?.PG_WSP_Committee_Trained_Youth2}
            label="Youth II"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={PG_WSP_Committee_Trained_Total}
            value={props?.data?.PG_WSP_Committee_Trained_Total}
            label="Total"
          />
        </Grid2>
      </Grid2>
      {error && <Alert severity="warning">{error}</Alert>}
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Step2B = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [otherValue, setOtherValue] = useState("");
  const [body, setBody] = useState({
    RU_NRWM3: "",
    RU_NRW: "",
    RU_TotalBilled: "",
    RU_TotalCollected: "",
    RU_RevenueCollectionEfficiency: "",
    RU_OperatingCost: "",
    RU_CostCoverage: "",
    RU_BacterialTestDone: "",
    RU_BacterialTestsPassed: "",
    RU_ChemicalTestDone: "",
    RU_ChemicalTestsPassed: "",
    RU_WaterQualityTestDone: "",
    RU_WaterQualityResult: "",
    RU_Comment: "",
    RU_WaterPipelineConstructed: "",
    RU_NoWaterSystemsConstructed: "",
    RU_ConstructedWaterSystems: "",
    RU_OtherConstructedWaterSystems: "",
    RU_NWCIndividualConnection: "",
    RU_NWCYardTaps: "",
    RU_NWCMDU: "",
    RU_NWCKiosks: "",
    RU_Newbeneficiaries_NewConnections: "",
    RU_Beneficiaries_ImprovedService: "",
    RU_Newbeneficiaries_ProtectedSprings: "",
    RU_Beneficiaries_IS_ProtectedSprings: "",
    RU_QualityStandard: "",
    RU_WaterProduced: "",
    RU_RegistrationStatus: "",
    RU_RegulationStatus: "",
    RU_SDM_From: "",
    RU_SDM_To: "",
    RU_WaterStorage: "",
    RU_YieldCapacity: "",
    RU_ActualProduction: "",
    RU_SupplyHours: "",
    RU_New_Meters: "",
    RU_NonfunctionalMeters: "",
    RU_ManagementOrUserCom_Trained_Male: "",
    RU_ManagementOrUserCom_Trained_Female: "",
    RU_ManagementOrUserCom_Trained_YouthI: "",
    RU_ManagementOrUserCom_Trained_YouthII: "",
    RU_ManagementOrUserCom_Trained_Total: "",
    RU_TypeofTraining: "",
  });

  const RU_Newbeneficiaries_ProtectedSprings = useRef();
  const RU_Beneficiaries_IS_ProtectedSprings = useRef();
  const RU_NRWM3 = useRef();
  const RU_NRW = useRef();
  const RU_TotalBilled = useRef();
  const RU_TotalCollected = useRef();
  const RU_RevenueCollectionEfficiency = useRef();
  const RU_OperatingCost = useRef();
  const RU_CostCoverage = useRef();
  const RU_NoWaterSystemsConstructed = useRef();
  const RU_NWCIndividualConnection = useRef();
  const RU_NWCYardTaps = useRef();
  const RU_NWCMDU = useRef();
  const RU_NWCKiosks = useRef();
  const RU_Newbeneficiaries_NewConnections = useRef();
  const RU_Beneficiaries_ImprovedService = useRef();
  const RU_RegistrationStatus = useRef();
  const RU_WaterStorage = useRef();
  const RU_YieldCapacity = useRef();
  const RU_ActualProduction = useRef();
  const RU_SupplyHours = useRef();
  const RU_New_Meters = useRef();
  const RU_NonfunctionalMeters = useRef();
  const RU_ManagementOrUserCom_Trained_Male = useRef();
  const RU_ManagementOrUserCom_Trained_Female = useRef();
  const RU_ManagementOrUserCom_Trained_YouthI = useRef();
  const RU_ManagementOrUserCom_Trained_YouthII = useRef();
  const RU_ManagementOrUserCom_Trained_Total = useRef();
  const RU_WaterQualityTestDone = useRef();
  const RU_RegulationStatus = useRef();
  const RU_TypeofTraining = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.RU_NRWM3 = RU_NRWM3.current.value;
    d.RU_NRW = RU_NRW.current.value;
    d.RU_TotalBilled = RU_TotalBilled.current.value;
    d.RU_TotalCollected = RU_TotalCollected.current.value;
    d.RU_RevenueCollectionEfficiency =
      RU_RevenueCollectionEfficiency.current.value;
    d.RU_OperatingCost = RU_OperatingCost.current.value;
    d.RU_CostCoverage = RU_CostCoverage.current.value;
    d.RU_NoWaterSystemsConstructed = RU_NoWaterSystemsConstructed.current.value;
    d.RU_NWCIndividualConnection = RU_NWCIndividualConnection.current.value;
    d.RU_NWCYardTaps = RU_NWCYardTaps.current.value;
    d.RU_NWCMDU = RU_NWCMDU.current.value;
    d.RU_NWCKiosks = RU_NWCKiosks.current.value;
    d.RU_Newbeneficiaries_NewConnections =
      RU_Newbeneficiaries_NewConnections.current.value;
    d.RU_Beneficiaries_ImprovedService =
      RU_Beneficiaries_ImprovedService.current.value;
    d.RU_RegistrationStatus = RU_RegistrationStatus.current.value;
    d.RU_WaterStorage = RU_WaterStorage.current.value;
    d.RU_YieldCapacity = RU_YieldCapacity.current.value;
    d.RU_ActualProduction = RU_ActualProduction.current.value;
    d.RU_SupplyHours = RU_SupplyHours.current.value;
    d.RU_New_Meters = RU_New_Meters.current.value;
    d.RU_NonfunctionalMeters = RU_NonfunctionalMeters.current.value;
    d.RU_ManagementOrUserCom_Trained_Male =
      RU_ManagementOrUserCom_Trained_Male.current.value;
    d.RU_ManagementOrUserCom_Trained_Female =
      RU_ManagementOrUserCom_Trained_Female.current.value;
    d.RU_ManagementOrUserCom_Trained_YouthI =
      RU_ManagementOrUserCom_Trained_YouthI.current.value;
    d.RU_ManagementOrUserCom_Trained_YouthII =
      RU_ManagementOrUserCom_Trained_YouthII.current.value;
    d.RU_ManagementOrUserCom_Trained_Total =
      RU_ManagementOrUserCom_Trained_Total.current.value;
    d.RU_Newbeneficiaries_ProtectedSprings =
      RU_Newbeneficiaries_ProtectedSprings.current.value;
    d.RU_Beneficiaries_IS_ProtectedSprings =
      RU_Beneficiaries_IS_ProtectedSprings.current.value;
    d.RU_RegulationStatus = RU_RegulationStatus.current.value;
    d.RU_TypeofTraining = RU_TypeofTraining.current.value;
    setBody(d);

    setLoading(true);

    fetch(`/api/rmf/${props?.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("WRM");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const handleTypeofTrainingChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      .map((option) => option.value)
      .join(", ");
    setBody((prevBody) => ({
      ...prevBody,
      RU_TypeofTraining: selectedValues,
    }));
  };

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        Revenue Collection
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NRWM3}
            value={props?.data?.RU_NRWM3}
            label="Non-Revenue Water (m3)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NRW}
            value={props?.data?.RU_NRW}
            label="Percentage of Non-Revenue Water (%)"
          />
        </Grid2>
      </Grid2>

      <Typography variant="body2" gutterBottom>
        Revenue Collection
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={RU_TotalBilled}
            value={props?.data?.RU_TotalBilled}
            label="Total Billing (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={RU_TotalCollected}
            value={props?.data?.RU_TotalCollected}
            label="Total Collection (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={RU_RevenueCollectionEfficiency}
            value={props?.data?.RU_RevenueCollectionEfficiency}
            label="Revenue Collection Efficiency (%)"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        O&M Cost Coverage
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_OperatingCost}
            value={props?.data?.RU_OperatingCost}
            label="Operating Cost (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_CostCoverage}
            value={props?.data?.RU_CostCoverage}
            label="Cost Coverage (%)"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Water Quality Tests
      </Typography>
      <Select
        ref={RU_WaterQualityTestDone}
        value={props?.data?.RU_WaterQualityTestDone}
        label="Water Quality Tests Done"
        data={["Yes", "No"]}
      />

      <Typography variant="body2" gutterBottom>
        Drinking Water Systems Constructed or rehabilitated
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NoWaterSystemsConstructed}
            value={props?.data?.RU_NoWaterSystemsConstructed}
            label="Number"
            handleChange={(e) => {
              console.log(e);
              if (parseInt(e) > 0 && e !== "") {
                setShowMultiSelect(true);
              } else {
                setShowMultiSelect(false);
                setBody((prevBody) => ({
                  ...prevBody,
                  RU_ConstructedWaterSystems: "",
                }));
              }
            }}
          />{" "}
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          {showMultiSelect ||
            (props?.data?.RU_NoWaterSystemsConstructed > 0 && (
              <FormControl fullWidth>
                <InputLabel shrink>Select Constructed Water Systems</InputLabel>
                <MultiSelect
                  options={[
                    {
                      value: "Borehole solarization",
                      label: "Borehole solarization",
                    },
                    {
                      value: "Storage tank construction",
                      label: "Storage tank construction",
                    },
                    {
                      value: "Pipeline extension",
                      label: "Pipeline extension",
                    },
                    {
                      value: "Distribution networks",
                      label: "Distribution networks",
                    },
                    {
                      value: "Kiosk construction/rehabilitation",
                      label: "Kiosk construction/rehabilitation",
                    },
                    {
                      value: "Installation of treatment unit",
                      label: "Installation of treatment unit",
                    },
                    {
                      value: "Electro-mechanical works/pump replacement",
                      label: "Electro-mechanical works/pump replacement",
                    },
                    {
                      value: "Spring protection",
                      label: "Spring protection",
                    },
                    {
                      value: "Other",
                      label: "Other",
                    },
                  ]}
                  value={
                    selectedOptions ||
                    props?.data?.RU_ConstructedWaterSystems?.toString()
                      .split("-")
                      .map((value) => ({ value, label: value }))
                  }
                  fullWidth
                  onChange={(selected) => {
                    setSelectedOptions(selected);
                    let constructedWaterSystems = selected
                      .map((option) => option.value)
                      .join("-");
                    if (selected.some((option) => option.value === "Other")) {
                      constructedWaterSystems += `-${otherValue}`;
                    }
                    setBody((prevBody) => ({
                      ...prevBody,
                      RU_ConstructedWaterSystems: constructedWaterSystems,
                    }));
                  }}
                  parseValueFromString={(valueString) =>
                    Array.isArray(valueString)
                      ? valueString
                      : valueString != null
                      ? valueString
                          .toString()
                          .split("-")
                          .map((value) => ({ value, label: value }))
                      : []
                  }
                />
              </FormControl>
            ))}
        </Grid2>
        <Grid2 item size={{ xs: 12 }}>
          {selectedOptions.includes("Other") && (
            <Input
              type="text"
              value={otherValue}
              label="Specify Other"
              handleChange={(e) => {
                const value = e.target.value;
                setOtherValue(value);
                setBody((prevBody) => {
                  const constructedWaterSystems = selectedOptions
                    .map((option) => (option === "Other" ? value : option))
                    .join("-");
                  return {
                    ...prevBody,
                    RU_ConstructedWaterSystems: constructedWaterSystems,
                  };
                });
              }}
            />
          )}
        </Grid2>
      </Grid2>

      <Typography variant="body2" gutterBottom>
        Number of new water connections
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCIndividualConnection}
            value={props?.data?.RU_NWCIndividualConnection}
            label="Individual Connections"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCYardTaps}
            value={props?.data?.RU_NWCYardTaps}
            label="Yard taps"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCMDU}
            value={props?.data?.RU_NWCMDU}
            label="MDU (small,medium,large)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_NWCKiosks}
            value={props?.data?.RU_NWCKiosks}
            label="Kiosks"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Number of new beneficiaries
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_Newbeneficiaries_NewConnections}
            value={props?.data?.RU_Newbeneficiaries_NewConnections}
            label="From new connections"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_Beneficiaries_ImprovedService}
            value={props?.data?.RU_Beneficiaries_ImprovedService}
            label="From improved service"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Beneficiaries from protected springs and community water points
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_Newbeneficiaries_ProtectedSprings}
            value={props?.data?.RU_Newbeneficiaries_ProtectedSprings}
            label="From new connections"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_Beneficiaries_IS_ProtectedSprings}
            value={props?.data?.RU_Beneficiaries_IS_ProtectedSprings}
            label="From improved service"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Registration & Regulation Status
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Select
            data={["Company", "WUA", "SHG", "CBO", "Not Registered", "Other"]}
            ref={RU_RegistrationStatus}
            value={props?.data?.RU_RegistrationStatus}
            label="Registration Status"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Select
            data={["Regulated", "Unregulated"]}
            ref={RU_RegulationStatus}
            value={props?.data?.RU_RegulationStatus}
            label="Regulation Status"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Water Storage & Production
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_WaterStorage}
            value={props?.data?.RU_WaterStorage}
            label="Water storage (Cubic Meters)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_YieldCapacity}
            value={props?.data?.RU_YieldCapacity}
            label="Yield Capacity (Cubic Meters)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            ref={RU_ActualProduction}
            value={props?.data?.RU_ActualProduction}
            label="Actual Production (Cubic Meters)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 3 }}>
          <Input
            type="number"
            value={props?.data?.RU_SupplyHours}
            ref={RU_SupplyHours}
            label="Hours of supply (Hrs)"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Meters
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_New_Meters}
            value={props?.data?.RU_New_Meters}
            label="New metered connections"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={RU_NonfunctionalMeters}
            value={props?.data?.RU_NonfunctionalMeters}
            label="Non-functional meters activated/replaced"
          />
        </Grid2>
      </Grid2>
      <Typography variant="body2" gutterBottom>
        Rural Water Enterprise Management Committee/water users committee
        members trained
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={RU_ManagementOrUserCom_Trained_Male}
            value={props?.data?.RU_ManagementOrUserCom_Trained_Male}
            label="Male"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={RU_ManagementOrUserCom_Trained_Female}
            value={props?.data?.RU_ManagementOrUserCom_Trained_Female}
            label="Female"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={RU_ManagementOrUserCom_Trained_YouthI}
            value={props?.data?.RU_ManagementOrUserCom_Trained_YouthI}
            label="Youth I"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={RU_ManagementOrUserCom_Trained_YouthII}
            value={props?.data?.RU_ManagementOrUserCom_Trained_YouthII}
            label="Youth II"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2.4 }}>
          <Input
            type="number"
            ref={RU_ManagementOrUserCom_Trained_Total}
            value={props?.data?.RU_ManagementOrUserCom_Trained_Total}
            label="Total"
          />
        </Grid2>
      </Grid2>
      <MultiSelect
        ref={RU_TypeofTraining}
        label="Type of Training"
        options={[
          {
            value: "Effective leadership/managament practices",
            label: "Effective leadership/managament practices",
          },
          { value: "Corporate governance", label: "Corporate governance" },
          { value: "O&M practices", label: "O&M practices" },
          { value: "Financial management", label: "Financial management" },
          { value: "Record keeping", label: "Record keeping" },
          { value: "Customer service", label: "Customer service" },
          { value: "NRW management", label: "NRW management" },
          { value: "Business Plan", label: "Business Plan" },
          { value: "Other", label: "Other" },
        ]}
        value={props?.data?.RU_TypeofTraining}
        name="RU_TypeofTraining"
        setChanged={handleTypeofTrainingChange}
      />
      {error && <Alert severity="warning">{error}</Alert>}
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};
const Step3 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    WRM_PES_Actions: props?.data?.WRM_PES_Actions || "",
    WRM_Trees_Planted: props?.data?.WRM_Trees_Planted || 0,
    WRM_Springs_Protected: props?.data?.WRM_Springs_Protected || 0,
    WRM_Terrace_Kilometers: props?.data?.WRM_Terrace_Kilometers || 0,
    WRM_Riparian_Rehabilitated: props?.data?.WRM_Riparian_Rehabilitated || 0,
    WRM_Forest_Rehabilitated: props?.data?.WRM_Forest_Rehabilitated || 0,
    WRM_WRUAs_SCMP: props?.data?.WRM_WRUAs_SCMP || 0,
    WRM_Tree_Nurseries: props?.data?.WRM_Tree_Nurseries || 0,
    WRM_Riverine_Protected: props?.data?.WRM_Riverine_Protected || 0,
    WRM_Beneficiaries_Improved_WRM:
      props?.data?.WRM_Beneficiaries_Improved_WRM || 0,
    WRM_Beneficiaries_IGA_HH: props?.data?.WRM_Beneficiaries_IGA_HH || 0,
    WRM_Beneficiaries_IGA_Total: props?.data?.WRM_Beneficiaries_IGA_Total || 0,
    WRM_Beneficiaries_IGA_Type: props?.data?.WRM_Beneficiaries_IGA_Type || "",
    WRM_WRUA_Committees_Trained: props?.data?.WRM_WRUA_Committees_Trained || 0,
    WRM_Data_Digital_Solution: props?.data?.WRM_Data_Digital_Solution || "",
    WRM_BeneficiariesImplementation:
      props?.data?.WRM_BeneficiariesImplementation || 0,
    WRM_Incentives_Protection: props?.data?.WRM_Incentives_Protection || "",
    WRM_WRUA_Use_Online_Platform:
      props?.data?.WRM_WRUA_Use_Online_Platform || "",
    WRM_WRUA_Effective_WRM_Practice:
      props?.data?.WRM_WRUA_Effective_WRM_Practice || "",
    WRM_WRUA_Committees_Trained_Male:
      props?.data?.WRM_WRUA_Committees_Trained_Male || 0,
    WRM_WRUA_Committees_Trained_Female:
      props?.data?.WRM_WRUA_Committees_Trained_Female || 0,
    WRM_WRUA_Committees_Trained_Youthii:
      props?.data?.WRM_WRUA_Committees_Trained_Youthii || 0,
    WRM_WRUA_Committees_Trained_Adult:
      props?.data?.WRM_WRUA_Committees_Trained_Adult || 0,
    WRM_WRUA_Committees_Trained_PWD:
      props?.data?.WRM_WRUA_Committees_Trained_PWD || 0,
    WRM_WRUA_Committees_Trained_Total:
      props?.data?.WRM_WRUA_Committees_Trained_Total || 0,
    WRM_WRUA_Committees_Trained_Other:
      props?.data?.WRM_WRUA_Committees_Trained_Other || 0,
  });

  const createDocument = (e) => {
    setError("");
    setLoading(true);

    fetch(`/api/rmf/${props.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("FSPE");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        WRM Details
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Select
            name="WRM_PES_BusinessPlans"
            value={props?.data?.WRM_PES_BusinessPlans}
            setChanged={(v) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_PES_BusinessPlans: v,
              }));
            }}
            label="PES Business Plan developed?"
            data={["Yes", "No"]}
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Select
            name="WRM_SCMP_Reviewed"
            value={props?.data?.WRM_SCMP_Reviewed}
            setChanged={(v) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_SCMP_Reviewed: v,
              }));
            }}
            label="SCMP Reviewed?"
            data={["Yes", "No"]}
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Select
            name="WRM_PES_Actions"
            data={["Yes", "No"]}
            value={props?.data?.WRM_PES_Actions}
            setChanged={(v) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_PES_Actions: v,
              }));
            }}
            label="Action Plan Developed?"
          />
        </Grid2>
      </Grid2>
      <MultiSelect
        sx={{ mb: "10px", backgroundColor: "white" }}
        name="WRM_MeasuresToImproveWRM"
        value={props?.data?.WRM_MeasuresToImproveWRM}
        handleChange={(e) => {
          setBody((prevBody) => ({
            ...prevBody,
            WRM_MeasuresToImproveWRM: e,
          }));
        }}
        label="Measures to Improve WRM"
        options={[
          { value: "soil conservation", label: "Soil Conservation" },
          { value: "upstream reforestation", label: "Upstream Reforestation" },
          {
            value: "wetland restoration and conservation",
            label: "Wetland Restoration and Conservation",
          },
          {
            value: "construction of infiltration ponds",
            label: "Construction of Infiltration Ponds",
          },
          {
            value: "construction of sand dams",
            label: "Construction of Sand Dams",
          },
          {
            value: "establishment of vegetative buffer strips",
            label: "Establishment of Vegetative Buffer Strips",
          },
          {
            value:
              "water allocation to improve equity or address water related risks",
            label:
              "Water Allocation to Improve Equity or Address Water Related Risks",
          },
          {
            value:
              "enforcement of regulations and policies - permitting systems",
            label:
              "Enforcement of Regulations and Policies - Permitting Systems",
          },
          {
            value: "enforcement of regulations and policies",
            label: "Enforcement of Regulations and Policies",
          },
          {
            value: "water quality regulations",
            label: "Water Quality Regulations",
          },
          {
            value: "enforcement of regulations and policies",
            label: "Enforcement of Regulations and Policies",
          },
          {
            value: "collection of water abstraction and user fees",
            label: "Collection of Water Abstraction and User Fees",
          },
          {
            value:
              "practices and innovations that improve water use efficiency, conservation, and water reuse",
            label:
              "Practices and Innovations that Improve Water Use Efficiency, Conservation, and Water Reuse",
          },
          {
            value:
              "reducing untreated wastewater or surface runoff to improve water quality",
            label:
              "Reducing Untreated Wastewater or Surface Runoff to Improve Water Quality",
          },
          { value: "other", label: "Other" },
        ]}
      />
      <Typography mt={1} variant="body2" gutterBottom>
        People Living in Areas where measures to improve WRM are implemented
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_PeopleLivingInWatershed"
            value={props?.data?.WRM_PeopleLivingInWatershed}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_PeopleLivingInWatershed: e,
              }));
            }}
            label="People Living in Watershed area"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_PeopleLivingDownstreamWatershed"
            value={props?.data?.WRM_PeopleLivingDownstreamWatershed}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_PeopleLivingDownstreamWatershed: e,
              }));
            }}
            label="People Living Downstream from a Watershed/sub-catchment area"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_BeneficiariesImplementation"
            value={props?.data?.WRM_BeneficiariesImplementation}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_BeneficiariesImplementation: e,
              }));
            }}
            label="No of Beneficiaries from implementation of measures to improve WRM"
          />
        </Grid2>
      </Grid2>
      <Typography mt={1} variant="body2" gutterBottom>
        WRM Measures
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Trees_Planted"
            value={props?.data?.WRM_Trees_Planted}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Trees_Planted: e,
              }));
            }}
            label="Trees Planted"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Springs_Protected"
            value={props?.data?.WRM_Springs_Protected}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Springs_Protected: e,
              }));
            }}
            label="Springs Protected"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Terrace_Kilometers"
            value={props?.data?.WRM_Terrace_Kilometers}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Terrace_Kilometers: e,
              }));
            }}
            label="Length of Terrace (km)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Riparian_Rehabilitated"
            value={props?.data?.WRM_Riparian_Rehabilitated}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Riparian_Rehabilitated: e,
              }));
            }}
            label="Riparian Rehabilitated (ha)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Forest_Rehabilitated"
            value={props?.data?.WRM_Forest_Rehabilitated}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Forest_Rehabilitated: e,
              }));
            }}
            label="Forest Rehabilitated (ha)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Tree_Nurseries"
            value={props?.data?.WRM_Tree_Nurseries}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Tree_Nurseries: e,
              }));
            }}
            label="No of Tree Nurseries"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            name="WRM_Tree_Nurseries"
            value={props?.data?.WRM_Tree_Nurseries}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Tree_Nurseries: e,
              }));
            }}
            label="Tree Nurseries"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            name="WRM_Riverine_Protected"
            value={props?.data?.WRM_Riverine_Protected}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Riverine_Protected: e,
              }));
            }}
            label="Length of Riverine Protected (km)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 12 }}>
          <Typography variant="body2" gutterBottom>
            No of Beneficiaries
          </Typography>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Beneficiaries_Improved_WRM"
            value={props?.data?.WRM_Beneficiaries_Improved_WRM}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Beneficiaries_Improved_WRM: e,
              }));
            }}
            label="From adoption and implementation of measures to improve WRM"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Beneficiaries_IGA_HH"
            value={props?.data?.WRM_Beneficiaries_IGA_HH}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Beneficiaries_IGA_HH: e,
              }));
            }}
            label="No of Households from adoption and implementation of alternative/enhanced IGA"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            name="WRM_Beneficiaries_IGA_Total"
            value={props?.data?.WRM_Beneficiaries_IGA_Total}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Beneficiaries_IGA_Total: e,
              }));
            }}
            label="From adoption and implementation of alternative/enhanced IGA"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 12 }}>
          <Typography variant="body2" gutterBottom>
            WRUAs Status
          </Typography>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Select
            type="text"
            name="WRM_WRUA_Use_Online_Platform"
            value={props?.data?.WRM_WRUA_Use_Online_Platform}
            setChanged={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Use_Online_Platform: e,
              }));
            }}
            label="WRUA is using online data management platforms/technologies"
            data={["Yes", "No"]}
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Select
            type="number"
            name="WRM_WRUA_Effective_WRM_Practice"
            value={props?.data?.WRM_WRUA_Effective_WRM_Practice}
            setChanged={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Effective_WRM_Practice: e,
              }));
            }}
            label="WRUA/CFA Committee Trained on effective WRM practices"
            data={["Yes", "No"]}
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="text"
            name="WRM_Data_Digital_Solution"
            value={props?.data?.WRM_Data_Digital_Solution}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_Data_Digital_Solution: e,
              }));
            }}
            label="Name of online data management platforms/technologies used"
          />
        </Grid2>
      </Grid2>
      <Typography mt={2} variant="body2" gutterBottom>
        No of WRUA/CFA committe members trained on effective WRM practices
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 2 }}>
          <Input
            type="text"
            name="WRM_WRUA_Committees_Trained_Female"
            value={props?.data?.WRM_WRUA_Committees_Trained_Female}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Committees_Trained_Female: e,
              }));
            }}
            label="Female"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2 }}>
          <Input
            type="text"
            name="WRM_WRUA_Committees_Trained_Male"
            value={props?.data?.WRM_WRUA_Committees_Trained_Male}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Committees_Trained_Male: e,
              }));
            }}
            label="Male"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2 }}>
          <Input
            type="text"
            name="WRM_WRUA_Committees_Trained_Youthii"
            value={props?.data?.WRM_WRUA_Committees_Trained_Youthii}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Committees_Trained_Youthii: e,
              }));
            }}
            label="Youth (10-29)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2 }}>
          <Input
            type="number"
            name="WRM_WRUA_Committees_Trained_Adult"
            value={props?.data?.WRM_WRUA_Committees_Trained_Adult}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Committees_Trained_Adult: e,
              }));
            }}
            label="Adult (30+)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2 }}>
          <Input
            type="number"
            name="WRM_WRUA_Committees_Trained_PWD"
            value={props?.data?.WRM_WRUA_Committees_Trained_PWD}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Committees_Trained_PWD: e,
              }));
            }}
            label="PWD"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 2 }}>
          <Input
            type="number"
            name="WRM_WRUA_Committees_Trained"
            value={props?.data?.WRM_WRUA_Committees_Trained}
            handleChange={(e) => {
              setBody((prevBody) => ({
                ...prevBody,
                WRM_WRUA_Committees_Trained: e,
              }));
            }}
            label="Total"
          />
        </Grid2>
      </Grid2>
      <h6>{error}</h6>
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};

const Step4 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [body, setBody] = useState({
    FPS_PrivateFunding_Own: 0,
    FPS_PrivateFunding_Debt: 0,
    FPS_PrivateFunding_Leveraged: 0,
    FPS_PrivateFunding_Source: "",
    FPS_PhilanthropicFunding: 0,
    FPS_PhilanthropicFunding_Source: "",
    FPS_PublicFunding_CountyGov: 0,
    FPS_PublicFunding_NationalGov: 0,
    FPS_NewBeneficiaries_PrivateFunding: 0,
    FPS_IS_Beneficiaries_PrivateFunding: 0,
    FPS_NewBeneficiaries_PhilanthropicFunding: 0,
    FPS_IS_Beneficiaries_PhilanthropicFunding: 0,
    FPS_NewBeneficiaries_PublicFunding: 0,
    FPS_IS_Beneficiaries_PublicFunding: 0,
  });
  const FPS_PrivateFunding_Own = useRef();
  const FPS_PrivateFunding_Debt = useRef();
  const FPS_PrivateFunding_Leveraged = useRef();
  const FPS_PrivateFunding_Source = useRef();
  const FPS_PhilanthropicFunding = useRef();
  const FPS_PhilanthropicFunding_Source = useRef();
  const FPS_PublicFunding_CountyGov = useRef();
  const FPS_PublicFunding_NationalGov = useRef();
  const FPS_NewBeneficiaries_PrivateFunding = useRef();
  const FPS_IS_Beneficiaries_PrivateFunding = useRef();
  const FPS_NewBeneficiaries_PhilanthropicFunding = useRef();
  const FPS_IS_Beneficiaries_PhilanthropicFunding = useRef();
  const FPS_NewBeneficiaries_PublicFunding = useRef();
  const FPS_IS_Beneficiaries_PublicFunding = useRef();

  const createDocument = (e) => {
    setError("");
    let d = body;
    d.FPS_PrivateFunding_Own = FPS_PrivateFunding_Own.current.value;
    d.FPS_PrivateFunding_Debt = FPS_PrivateFunding_Debt.current.value;
    d.FPS_PrivateFunding_Source = FPS_PrivateFunding_Source.current.value;
    d.FPS_PhilanthropicFunding = FPS_PhilanthropicFunding.current.value;
    d.FPS_PhilanthropicFunding_Source =
      FPS_PhilanthropicFunding_Source.current.value;
    d.FPS_PublicFunding_CountyGov = FPS_PublicFunding_CountyGov.current.value;
    d.FPS_PublicFunding_NationalGov =
      FPS_PublicFunding_NationalGov.current.value;
    d.FPS_NewBeneficiaries_PrivateFunding =
      FPS_NewBeneficiaries_PrivateFunding.current.value;
    d.FPS_IS_Beneficiaries_PrivateFunding =
      FPS_IS_Beneficiaries_PrivateFunding.current.value;
    d.FPS_NewBeneficiaries_PhilanthropicFunding =
      FPS_NewBeneficiaries_PhilanthropicFunding.current.value;
    d.FPS_IS_Beneficiaries_PhilanthropicFunding =
      FPS_IS_Beneficiaries_PhilanthropicFunding.current.value;
    d.FPS_NewBeneficiaries_PublicFunding =
      FPS_NewBeneficiaries_PublicFunding.current.value;
    d.FPS_IS_Beneficiaries_PublicFunding =
      FPS_IS_Beneficiaries_PublicFunding.current.value;
    d.FPS_PrivateFunding_Leveraged = FPS_PrivateFunding_Leveraged.current.value;

    setBody(d);

    setLoading(true);

    fetch(`/api/rmf/${props.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setActive("P&G");
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  return (
    <div>
      <Typography variant="body2" gutterBottom>
        Amount of private funding invested by WSPs/Enterprises
      </Typography>
      <Grid2 container spacing={2}>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={FPS_PrivateFunding_Own}
            value={props?.data?.FPS_PrivateFunding_Own}
            label="Own Capital (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={FPS_PrivateFunding_Debt}
            value={props?.data?.FPS_PrivateFunding_Debt}
            label="Debt (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 4 }}>
          <Input
            type="number"
            ref={FPS_PrivateFunding_Leveraged}
            value={props?.data?.FPS_PrivateFunding_Leveraged}
            label="Leveraged (KSh)"
          />
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 12 }}>
          <Select
            data={["Bank", "MFI", "SACCO", "Other"]}
            ref={FPS_PrivateFunding_Source}
            value={props?.data?.FPS_PrivateFunding_Source}
            label="Source of private funding"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_PhilanthropicFunding}
            value={props?.data?.FPS_PhilanthropicFunding}
            label="Amount of philanthropic funding mobiled by WSPs/Enterprises"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Select
            data={["Corporate CSR", "NGOs", "Foundations", "Other"]}
            ref={FPS_PhilanthropicFunding_Source}
            value={props?.data?.FPS_PhilanthropicFunding_Source}
            label="Source of philanthropic funding"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 12 }}>
          <Typography variant="body2" gutterBottom>
            Amount of public funding invested by WSPs/Enterprises
          </Typography>
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_PublicFunding_CountyGov}
            value={props?.data?.FPS_PublicFunding_CountyGov}
            label="County Government (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_PublicFunding_NationalGov}
            value={props?.data?.FPS_PublicFunding_NationalGov}
            label="National Government/water works agencies (KSh)"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 12 }}>
          <Typography variant="body2" gutterBottom>
            Private funding investment
          </Typography>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_NewBeneficiaries_PrivateFunding}
            value={props?.data?.FPS_NewBeneficiaries_PrivateFunding}
            label="Number of new beneficiaries benefiting from private funding investment"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_IS_Beneficiaries_PrivateFunding}
            value={props?.data?.FPS_IS_Beneficiaries_PrivateFunding}
            label="Number of beneficiaries benefiting from improved service"
          />
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 12 }}>
          <Typography variant="body2" gutterBottom>
            Philanthropic funding investment
          </Typography>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_NewBeneficiaries_PhilanthropicFunding}
            value={props?.data?.FPS_NewBeneficiaries_PhilanthropicFunding}
            label="Number of new beneficiaries benefiting from philanthropic funding investment"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_IS_Beneficiaries_PhilanthropicFunding}
            value={props?.data?.FPS_IS_Beneficiaries_PhilanthropicFunding}
            label="Number of beneficiaries benefiting from improved service"
          />
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 12 }}>
          <Typography variant="body2" gutterBottom>
            Public funding investment
          </Typography>
        </Grid2>

        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_NewBeneficiaries_PublicFunding}
            value={props?.data?.FPS_NewBeneficiaries_PublicFunding}
            label="Number of new beneficiaries benefiting from public funding investment"
          />
        </Grid2>
        <Grid2 item size={{ xs: 12, md: 6 }}>
          <Input
            type="number"
            ref={FPS_IS_Beneficiaries_PublicFunding}
            value={props?.data?.FPS_IS_Beneficiaries_PublicFunding}
            label="Number of beneficiaries benefiting from improved service"
          />
        </Grid2>
      </Grid2>
      {error && <Alert severity="error">{error}</Alert>}
      <Button handleClick={createDocument} value="Next" />
      <br />
      {loading && <Loading />}
    </div>
  );
};
const Step5 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [body, setBody] = useState({
    PG_Type: props?.data?.PG_Type || "",
    PG_CountyLevel_Policy: props?.data?.PG_CountyLevel_Policy || "",
    PG_WSPLevel_Policy: props?.data?.PG_WSPLevel_Policy || "",
    PG_Development_Stage: props?.data?.PG_Development_Stage || "",
  });

  const navigate = useNavigate();

  const updateBody = (key, value) => {
    setBody((prevBody) => ({ ...prevBody, [key]: value }));
  };

  const [pgtype, setPgtype] = useState(body.PG_Type);
  const [countyLevelPolicy, setCountyLevelPolicy] = useState(
    body.PG_CountyLevel_Policy
  );
  const [wspLevelPolicy, setWspLevelPolicy] = useState(body.PG_WSPLevel_Policy);
  const [othername, setOthername] = useState("");
  const [developmentstage, setDevelopmentstage] = useState(
    body.PG_Development_Stage
  );

  useEffect(() => {
    updateBody("PG_Type", pgtype);
  }, [pgtype]);

  useEffect(() => {
    if (pgtype === "County Level") {
      updateBody("PG_CountyLevel_Policy", countyLevelPolicy);
    } else if (pgtype === "WSP Level") {
      updateBody("PG_WSPLevel_Policy", wspLevelPolicy);
    }
  }, [countyLevelPolicy, wspLevelPolicy, pgtype]);

  useEffect(() => {
    updateBody("PG_Development_Stage", developmentstage);
  }, [developmentstage]);

  const createDocument = async () => {
    setLoading(true);
    const response = await fetch(`/api/rmf/${props?.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.status == 401) {
      navigate("/");
    }

    const data = await response.json();
    setLoading(false);

    if (data.success) {
      setError(data.success);
      setTimeout(() => {
        props.setActive("Sign Off");
      }, 2000);
    } else {
      setError(data.error);
    }
  };

  return (
    <div>
      <Stack direction="column" spacing={2}>
        <Typography variant="body2" gutterBottom>
          Policies Developed
        </Typography>
        <Divider />
        <Select
          label="Select Level"
          value={pgtype}
          data={["County Level", "WSP Level"]}
          selectedOption={pgtype}
          setChanged={(v) => setPgtype(v)}
        />
        {pgtype === "County Level" && (
          <Select
            label="Name of county level policy, law, or guideline"
            data={[
              "Disaster risk management plan",
              "Climate change risk management plan",
              "Water policy",
              "Climate change policy",
              "Water and sanitation Act",
              "Natural resource management Act",
              "Rural water management framework policy",
              "CIDP",
              "PES",
              "Other",
            ]}
            value={countyLevelPolicy}
            setChanged={(value) => setCountyLevelPolicy(value)}
          />
        )}
        {pgtype === "WSP Level" && (
          <Select
            label="Name of WSP level policy, law, or guideline"
            data={[
              "Water safety management plan",
              "Performance improvement plan",
              "Business plan",
              "Metering Policy",
              "Pro-poor policy",
              "Social Connection Policy",
              "NRW Policy",
              "Marketing Policy",
              "Customer Service Charter",
              "Other",
            ]}
            value={wspLevelPolicy}
            setChanged={(value) => setWspLevelPolicy(value)}
          />
        )}
        {(countyLevelPolicy.includes("Other") ||
          wspLevelPolicy.includes("Other")) && (
          <Input
            value={othername}
            handleChange={(e) => setOthername(e.target.value)}
            label="If 'Other' above (specify...)"
          />
        )}
        <Select
          label="Development stage"
          data={
            countyLevelPolicy === "Water safety management plan" ||
            countyLevelPolicy === "Disaster risk management plan" ||
            wspLevelPolicy === "Water safety management plan" ||
            wspLevelPolicy === "Disaster risk management plan"
              ? [
                  "Formally proposed",
                  "Adopted",
                  "Implemented",
                  "Institutionalized",
                ]
              : [
                  "Stakeholder engagement/analysis",
                  "Drafting",
                  "Revision of the draft Policy/Bill",
                  "Public Consultation and Debate",
                  "Approval Cabinet Legislative Assembly/BOD",
                  "Full and Effective Implementation",
                  "Rural water management framework policy",
                ]
          }
          value={developmentstage}
          setChanged={(value) => setDevelopmentstage(value)}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <Button handleClick={createDocument} value="Next" />
        <br />
        {loading && <Loading />}
      </Stack>
    </div>
  );
};

const Step6 = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [rep, setRep] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [body, setBody] = useState({
    ERepName: null,
    ERepDesignation: null,
    WKWPRepName: null,
    WKWPRepDesignation: null,
    File: null,
    UserID: null,
  });

  const ERepName = useRef();
  const ERepDesignation = useRef();
  const ERepSignature = useRef();
  const WKWPRepSignature = useRef();
  const myFile = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`/api/auth`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((err) => {});
  }, []);

  const updateDocument = async () => {
    setError("");
    let d = body;
    d.ERepName = ERepName.current.value;
    d.ERepDesignation = ERepDesignation.current.value;
    d.WKWPRepName = selectedRep ? selectedRep.Name : props?.data?.WKWPRepName;
    d.WKWPRepDesignation = selectedRep
      ? selectedRep.Position
      : props?.data?.WKWPRepDesignation;

    if (myFile.current.files.length > 0) {
      d.File = myFile.current.files.length > 0 ? myFile.current.files[0] : null;

      d.File = await convertFileToBase64(d.File);
    }
    d.ERepSignature = ERepSignature.current.toDataURL();
    d.WKWPRepSignature = WKWPRepSignature.current.toDataURL();
    d.UserID = selectedRep ? selectedRep.UserID : props?.data?.UserID;

    setBody(d);
    setLoading(true);

    fetch(`/api/rmf/${props.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/rmf-list";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const saveDocument = async () => {
    setLoading(true);

    fetch(`/api/rmf/${props.id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Status: props?.data?.Status == true ? false : true,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/rmf-list";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  function getFile() {
    setLoading(true);
    fetch(`/api/uploads/${props?.data?.File}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then((data) => {
        const imageURL = URL.createObjectURL(data);
        window.open(imageURL, "_blank");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="taf">
      <h4>Sign Off</h4>
      <hr />
      <h3>Sign Off - WKWP Representative</h3>
      <div className="div2equal">
        <div className="search">
          <Select
            setChanged={(v) => {
              let d = data.filter((obj) => obj.Name === v);
              if (d.length > 0) {
                setSelectedRep(d[0]);
              }
              console.log(selectedRep);
            }}
            data={data.map((obj) => obj.Name)}
            label="Select WKWP Representative"
          />
          <div>
            <h4>
              <b>Name:</b>{" "}
              {selectedRep ? selectedRep.Name : props?.data?.WKWPRepName}
            </h4>
            <h4>
              <b>Designation:</b>{" "}
              {selectedRep
                ? selectedRep.Position
                : props?.data?.WKWPRepDesignation}
            </h4>
          </div>
        </div>
        <SignatureInput
          ref={WKWPRepSignature}
          readOnly={props.data?.Status}
          value={props.data?.WKWPRepSignature}
          label="Entity Represenative"
        />
      </div>

      <h3>Sign Off - Entity Representative</h3>
      <div className="div2equal">
        <div>
          <Input
            value={props.data?.ERepName}
            readOnly={props.data?.Status}
            ref={ERepName}
            type="text"
            label="Name *"
          />
          <br />
          <Input
            value={props.data?.ERepDesignation}
            readOnly={props.data?.Status}
            ref={ERepDesignation}
            type="text"
            label="Designation *"
          />
        </div>
        <SignatureInput
          ref={ERepSignature}
          readOnly={props.data?.Status}
          value={props.data?.ERepSignature}
          label="Entity Represenative Signature"
        />
      </div>

      <h3>Upload Scanned RMF (Optional)</h3>
      {props.data?.File != null && (
        <h4
          onClick={() => {
            getFile();
          }}
          className="view"
        >
          <FontAwesomeIcon icon={faDownload} />
          View uploaded file
        </h4>
      )}
      <div className="file-input">
        <input
          readOnly={props.data?.Status}
          ref={myFile}
          type="file"
          accept=".pdf"
        />
        <FontAwesomeIcon onClick={() => {}} icon={faTrash} className="ic" />
      </div>

      <h6>{error}</h6>
      {props?.data?.Status != true && (
        <Button handleClick={updateDocument} value="Finish" />
      )}
      <br />
      {props?.user.Role == "Admin" && (
        <button
          title="No further edits will be possible for this record. Data will be available for analysis on the dashboard"
          className="btn1"
          onClick={() => {
            saveDocument();
          }}
        >
          {props?.data?.Status == true ? "Turn On Editing" : "Turn Off Editing"}
        </button>
      )}
      {loading && <Loading />}
    </div>
  );
};

const Error = (props) => {
  return (
    <div className="error">
      <p>This form is only available after filling the basic details</p>
    </div>
  );
};
