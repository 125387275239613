import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Divider,
  IconButton,
} from "@mui/material";
import "../../Styles/beneficiary.scss";
import Loading from "../Util/Loading";
import SelectedIndicator from "./SelectedIndicator";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function Indicators() {
  const [indicators, setIndicators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [indicatorDetails, setIndicatorDetails] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetch(`/api/indicatorsmel/unique`, {
      method: "get",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/");
          return;
        }
        return response.json();
      })
      .then((data) => {
        setIndicators(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching indicators:", error);
        setLoading(false);
      });
  }, [refresh]);

  useEffect(() => {
    if (ID !== null) {
      setIndicatorDetails(ID);
      setDialogOpen(true);
    }
  }, [ID]);

  const columns = [
    {
      field: "Code",
      headerName: "Code",
      width: 120,
      renderCell: (params) => (
        <Box>
          <div
            style={{
              backgroundColor: "#002F6C",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              margin: "5px",
            }}
          >
            {params.value}
          </div>
        </Box>
      ),
    },
    { field: "Description", headerName: "Description", flex: 1 },
    { field: "LatestDate", headerName: "Latest Update" },
    { field: "LatestPerformance", headerName: "Value" },
  ];

  return (
    <div className="beneficiary">
      <div className="list">
        <div className="utp">
          <h3>Update KPIs</h3>
        </div>
        <hr />

        <div style={{ width: "100%" }}>
          <DataGrid
            rows={indicators}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row.ID}
            onRowClick={(params) => setID(params.row)}
            autoHeight
          />
        </div>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr={2}
          >
            <DialogTitle>Indicator Details</DialogTitle>
            <IconButton onClick={() => setDialogOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Divider />

          {indicatorDetails ? (
            <SelectedIndicator
              setLoading={setLoading}
              indicatorDetails={indicatorDetails}
              setRefresh={setRefresh}
              refresh={refresh}
              url="indicators"
            />
          ) : (
            <p>Click on an indicator to see its details</p>
          )}
        </Dialog>

        {loading && <Loading />}
      </div>
    </div>
  );
}
