import { useEffect, useRef } from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

export default function BoxItem(props) {
  const vl = useRef();
  const v2 = useRef();
  const v3 = useRef();

  useEffect(() => {
    animateValue(vl.current, 0, props.amount, 1000);
    props.current && animateValue(v2.current, 0, props.current, 1000);
    props.baseline && animateValue(v3.current, 0, props.baseline, 1000);
  }, [props.amount]);

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;

    const hasDecimals = Number.isInteger(end) === false;

    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      let interpolatedValue;
      if (hasDecimals) {
        interpolatedValue = start + progress * (end - start);
      } else {
        interpolatedValue = Math.floor(start + progress * (end - start));
      }
      const formattedValue = parseFloat(interpolatedValue.toFixed(2));
      obj.innerHTML = `${props?.unit ? props.unit : ""} ${withCommas(
        formattedValue
      )} ${props?.percent ? props.percent : ""}`;
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };

    window.requestAnimationFrame(step);
  }

  function withCommas(x) {
    return x?.toString().replace(/\B(?=(?:\d{3})+(?!\d))/g, ",");
  }

  return (
    <Card
      sx={{
        padding: 2,
        borderRadius: 2,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        cursor: "pointer",
        backgroundColor: props.active ? "secondary.light" : "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
      onClick={props.onClick}
    >
      <Box display="flex" gap={2} alignItems="center">
        {props.current ? (
          <Box mb={2}>
            <Typography variant="body2">
              Baseline: <span ref={v3}>{props.baseline}</span>
            </Typography>
            <Typography variant="body2">
              Current: <span ref={v2}>{props.current}</span>
            </Typography>
          </Box>
        ) : (
          <Box mb={2}>
            <img src={props.img} alt="" style={{ maxWidth: "44px" }} />
          </Box>
        )}
        <Box flexGrow={1} textAlign="right">
          <Typography variant="h6" color="secondary" ref={vl}></Typography>
          <Typography variant="body2" color="textSecondary">
            {props.description}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
