import {
  faAd,
  faArrowAltCircleLeft,
  faEdit,
  faPlusCircle,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import "../../../Styles/dataentry.scss";
import Confirm from "../../Util/Confirm";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import Pagination from "../../Util/Pagination";
import Select from "../../Util/Select";

export default function TAFList(props) {
  const [data, setData] = useState(null);
  const [column, setColumn] = useState("Beneficiary");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [county, setCounty] = useState("");
  const [role, setRole] = useState();
  const pathname = window.location.pathname.split("/");

  useEffect(() => {
    setRole(props?.user?.Role);
  }, []);

  useEffect(() => {
    const countyValue =
      pathname.length === 3
        ? null
        : pathname.length === 4
        ? pathname[3]
        : county;
    setCounty(countyValue);
  }, []);

  useEffect(() => {
    const today = new Date();
    setStart(`${today.getFullYear() - 1}-10-01`);
    setEnd(`${today.getFullYear()}-09-30`);

    if (start !== "" && end !== "") {
      getTaf();
    }
  }, [refresh, offset]);

  function getTaf() {
    setLoading(true);
    if (county == null) {
      fetch(`/api/taf/paginated/${offset * 12}/${start}/${end}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else if ((pathname.length = 4 || pathname[3] != "")) {
      fetch(`/api/taf/paginated/${offset * 12}/${start}/${end}/${county}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }

  function quickSearch(v) {
    setLoading(true);
    if (pathname.length > 4) {
      fetch(`/api/taf/quicksearch/${column}/${v}/${county}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      fetch(`/api/taf/quicksearch/${column}/${v}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (start !== "" && end !== "") {
      getTaf();
    }
  }, [start, end]);

  function deleteTAF(id) {
    setLoading(true);
    fetch(`/api/taf/${id}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        props.setLoading(false);
        setRefresh(!refresh);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div className="dataentry">
      <div className="title">
        {role?.Role !== "Guest" && (
          <p
            onClick={() => {
              window.location.href = "/dataentry/taf";
            }}
            className="add"
          >
            <FontAwesomeIcon icon={faPlusCircle} /> New Form
          </p>
        )}
        <Select
          setChanged={(v) => {
            setColumn(v);
          }}
          data={[
            "Beneficiary",
            "BeneficiaryType",
            "County",
            "SubCounty",
            "Ward",
            "Village",
            "Date",
            "TA_Provided",
            "TAThrough",
            "Description",
          ]}
          value="Beneficiary"
          label="Search Column"
        />
        <div className="lsearch">
          <Input
            handleChange={(value) => {
              if (value.length > 0) {
                quickSearch(value);
              }
              if (value.length === 0) {
                setRefresh(!refresh);
              }
            }}
            type="text"
            label="Search ..."
          />
          <FontAwesomeIcon icon={faSearch} className="fa-search" />
        </div>
        <div className="div2equal">
          <Input
            handleChange={(e) => {
              setStart(e);
            }}
            type="date"
            value={start}
            label="Start Date"
          />
          <Input
            handleChange={(e) => {
              setEnd(e);
            }}
            type="date"
            value={end}
            label="End Date"
          />
        </div>
      </div>

      <div className="new">
        <div className="topbar">
          <h4>Technical Assistance Forms</h4>
          <div>
            {pathname.length > 3 && (
              <p
                onClick={() => {
                  window.location.href = "/dataentry/taf-list";
                }}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} /> All Counties
              </p>
            )}
          </div>
        </div>
        <div className="list">
          {data &&
            data?.data?.length > 0 &&
            data?.data?.map((item, i) => {
              return (
                <Item
                  key={i}
                  deleteTAF={deleteTAF}
                  item={item}
                  index={i + offset * 12}
                  role={role}
                />
              );
            })}
        </div>
        <Pagination
          totalItems={data?.total}
          currentPage={offset}
          onPageChange={(e) => {
            setOffset(e);
          }}
        />
        <br />
      </div>
      {loading && <Loading />}
    </div>
  );
}

const Item = (props) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  return (
    <div className="item">
      <div className="ttitem">
        <h6>{props.index + 1}</h6>
        <div>
          <h4>Beneficiary/Partner: {props.item.Beneficiary}</h4>
          <p>
            Beneficiary Type:
            {props.item.BeneficiaryType}
          </p>
        </div>
        <div>
          <p>TA Provided: {props.item.TA_Provided}</p>
          <p>
            TA Through:
            {props.item.TAThrough}
          </p>
        </div>
        <h3
          onClick={() => {
            window.location.href = "/dataentry/taf-list/" + props.item.County;
          }}
        >
          {props.item.County}
        </h3>
        <h5>{props.item.Date}</h5>
        <div className="icons">
          {(props.role?.Role == "Admin" ||
            props.item.UserID == props.role?.UserID) && (
            <FontAwesomeIcon
              className="fa"
              icon={faEdit}
              onClick={() =>
                (window.location.href = `/dataentry/taf-edit/${props.item.ID}`)
              }
              title="Edit/update"
            />
          )}
          {props.item.Status != true &&
            (props.role?.Role == "Admin" ||
              props.item.UserID == props.role?.UserID) && (
              <FontAwesomeIcon
                className="fa-trash"
                icon={faTrash}
                onClick={() => {
                  openConfirm();
                }}
                title="Delete"
              />
            )}
          <FaEye
            className="fa"
            onClick={() =>
              (window.location.href = `/dataentry/preview-taf/${props.item.ID}`)
            }
            title="View"
          />
        </div>
        {showConfirm && (
          <Confirm
            closeConfirm={closeConfirm}
            deleteFunction={() => props.deleteTAF(props.item.ID)}
          />
        )}
      </div>
    </div>
  );
};
