import "../Styles/home.scss";
import Header from "../components/Util/Header";
import Navigation from "../components/Util/Navigation";
import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Settings from "../components/Settings/Settings";
import WPStats from "../components/Stats/WPStats";
import Indicators from "../components/Indicators/Indicators";
import AllCounties from "../components/Counties/AllCounties";
import DataEntry from "../components/DataEntry/DataEntry";
import SingleCounty from "../components/Counties/SingleCounty/SingleCounty";
import TAFNew from "../components/DataEntry/TAF/TAFNew";
import TAFList from "../components/DataEntry/TAF/TAFList";
import WorkPlan from "../components/WorkPlan/WorkPlan";
import CreateWorkPlan from "../components/WorkPlan/CreateWorkPlan";
import ARFNew from "../components/DataEntry/ARF/ARFNew";
import ARFList from "../components/DataEntry/ARF/ARFList";
import RMFNew from "../components/DataEntry/RMF/RMFNew";
import RMFList from "../components/DataEntry/RMF/RMFList";
import ToolBuilder from "../components/ToolBuilder/ToolBuilder";
import NewTool from "../components/ToolBuilder/NewTool";
import UpdateTool from "../components/ToolBuilder/UpdateTool";
import PreviewTAF from "../components/DataEntry/TAF/PreviewTAF";
import PreviewRMF from "../components/DataEntry/RMF/PreviewRMF";
import UserHome from "../components/Users/UserHome";
import PreviewARF from "../components/DataEntry/ARF/PreviewARF";
import Mel from "../components/Mel/Mel";
import ReportsHome from "../components/Reports/ReportsHome";
import CountyAll from "../components/GIS/CountyAll";
import TAFUpdate from "../components/DataEntry/TAF/TAFUpdate";
import ARFUpdate from "../components/DataEntry/ARF/ARFUpdate";
import RMFUpdate from "../components/DataEntry/RMF/RMFUpdate";
import SingleView from "../components/Indicators/SingleView";
import MapTemplate from "../components/GIS/MapTemplate";
import TBData from "../components/ToolBuilder/TBData";

export default function Home(props) {
  const [showing, setShowing] = useState(true);
  const [shownavigation, setShowNavigation] = useState(false);
  const [role, setRole] = useState();
  const [user, setUser] = useState(null);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    setRole(user?.Role);
  }, [user]);

  return (
    <div className="home">
      <div
        style={{ gridTemplateColumns: !showing ? "auto 1fr" : "225px 1fr" }}
        className="main"
      >
        <div className="left_panel">
          <Navigation
            showing={showing}
            setShowing={setShowing}
            shownavigation={shownavigation}
            role={role}
            user={user}
          />
        </div>
        <div className="right_panel">
          <Header
            showing={showing}
            setShowing={setShowing}
            setShowNavigation={setShowNavigation}
            shownavigation={setShowNavigation}
            user={user}
            setUser={setUser}
          />

          <div className="full">
            <Routes>
              <Route
                path="/home"
                element={<WPStats showing={showing} user={user} />}
              />
              <Route path="/indicators" element={<Indicators user={user} />} />
              <Route
                path="/indicators/singleview"
                element={<SingleView user={user} />}
              />
              <Route
                path="/reports"
                element={<ReportsHome showing={showing} user={user} />}
              />
              <Route
                path="/buildtool"
                element={role !== "Guest" && <ToolBuilder user={user} />}
              />
              <Route
                path="/buildtool/new"
                element={role !== "Guest" && <NewTool user={user} />}
              />
              <Route
                path="/buildtool/update/*"
                element={role !== "Guest" && <UpdateTool user={user} />}
              />
              <Route
                path="/buildtool/data/*"
                element={role !== "Guest" && <TBData user={user} />}
              />
              <Route path="/dataentry" element={<DataEntry user={user} />} />
              <Route
                path="/dataentry/taf"
                element={role !== "Guest" && <TAFNew user={user} />}
              />
              <Route
                path="/dataentry/taf-list"
                element={<TAFList role={role} user={user} />}
              />
              <Route
                path="/dataentry/taf-edit/*"
                element={role !== "Guest" && <TAFUpdate user={user} />}
              />
              <Route
                path="/dataentry/preview-taf/*"
                element={<PreviewTAF user={user} />}
              />
              <Route
                path="/dataentry/arf"
                element={role !== "Guest" && <ARFNew user={user} />}
              />
              <Route
                path="/dataentry/arf-list"
                element={<ARFList role={role} user={user} />}
              />
              <Route
                path="/dataentry/arf-edit/*"
                element={role !== "Guest" && <ARFUpdate user={user} />}
              />
              <Route
                path="/dataentry/preview-arf/*"
                element={<PreviewARF user={user} />}
              />
              <Route
                path="/dataentry/rmf"
                element={role !== "Guest" && <RMFNew user={user} />}
              />
              <Route
                path="/dataentry/rmf-list"
                element={<RMFList role={role} user={user} />}
              />
              <Route
                path="/dataentry/rmf-edit/*"
                element={<RMFUpdate user={user} />}
              />
              <Route
                path="/dataentry/preview-rmf/*"
                element={<PreviewRMF user={user} />}
              />
              <Route
                path="/gis"
                element={<CountyAll showing={showing} user={user} />}
              />
              <Route
                path="/gis/export"
                element={<MapTemplate showing={showing} user={user} />}
              />
              <Route path="/counties" element={<AllCounties user={user} />} />
              <Route
                path="/counties/:id"
                element={<SingleCounty user={user} />}
              />
              <Route
                path="/counties/:id/*"
                element={<MapTemplate user={user} />}
              />
              <Route
                path="/wp"
                element={<WorkPlan role={role} user={user} />}
              />
              <Route
                path="/wp/new"
                element={role !== "Guest" && <CreateWorkPlan user={user} />}
              />
              <Route
                path="/users"
                element={
                  role !== "Regular User" &&
                  role !== "Guest" && <UserHome role={role} user={user} />
                }
              />
              <Route
                path="/mel"
                element={
                  role !== "Regular User" &&
                  role !== "Guest" && <Mel user={user} />
                }
              />
              <Route path="/settings" element={<Settings user={user} />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}
