import {
  faArrowRight,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import "../../../Styles/dataentry.scss";
import Button from "../../Util/Button";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import MultiSelect from "../../Util/MultiSelect";
import SignatureInput from "../../Util/SignatureInput";
import Select from "../../Util/Select";
import { useNavigate } from "react-router-dom";

export default function TAFUpdate(props) {
  const [active, setActive] = useState("Basic Details");
  const [data, setData] = useState(null);

  const pathname = window.location.pathname.split("/");
  const id = pathname[3];
  const navigate = useNavigate();
  useEffect(() => {
    fetch(`/api/taf/${id}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          navigate("/");
          return;
        }
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
      })
      .catch((e) => {});
  }, [active]);

  return (
    <div className="dataentry">
      <div className="new">
        <div className="topbar">
          <h4>Update Technical Assistance Form</h4>
          <p
            onClick={() => {
              window.location.href = "/dataentry/taf-list";
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} /> List
          </p>
        </div>
        <div className="rmf">
          <Step1 data={data} setActive={setActive} id={id} />
        </div>
      </div>
    </div>
  );
}

const Step1 = (props) => {
  const TATypes = [
    { value: "Operational", label: "Operational" },
    { value: "Management", label: "Management" },
    { value: "Engineering", label: "Engineering" },
    { value: "Capacity Building", label: "Capacity Building" },
    { value: "Finance", label: "Finance" },
    { value: "Data & Digital Solutions", label: "Data & Digital Solutions" },
  ];

  const TAThroughList = [
    { value: "Training", label: "Training" },
    { value: "Grant", label: "Grant" },
    {
      value: "Meeting/Coaching/Mentoring",
      label: "Meeting/Coaching/Mentoring",
    },
    { value: "Other", label: "Other" },
  ];

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [beneficiaryList, setBeneficiaryList] = useState();
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [TATypeOptions, setTATypeOptions] = useState("");
  const [TAThroughOptions, setTAThroughOptions] = useState("");
  const [so, setSO] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [body, setBody] = useState({
    Beneficiary: null,
    BeneficiaryType: null,
    County: null,
    SubCounty: null,
    Ward: null,
    Village: null,
    Date: null,
    Longitude: null,
    Latitude: null,
    ContactName: null,
    ContactPhone: null,
    Designation: null,
    TA_Provided: null,
    TAThrough: null,
    TADescription: null,
    ERepName: null,
    ERepDesignation: null,
    WKWPRepName: null,
    WKWPRepDesignation: null,
    File: null,
    UserID: null,
  });

  const Date = useRef();
  const Longitude = useRef();
  const Latitude = useRef();
  const ContactName = useRef();
  const ContactPhone = useRef();
  const Designation = useRef();
  const TA_Provided = useRef();
  const TAThrough = useRef();
  const TADescription = useRef();
  const ERepName = useRef();
  const ERepDesignation = useRef();
  const ERepSignature = useRef();
  const WKWPRepName = useRef();
  const WKWPRepDesignation = useRef();
  const WKWPRepSignature = useRef();
  const myFile = useRef();
  const [role, setRole] = useState(null);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    setRole(props?.user?.Role);

    fetch(`/api/auth`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          navigate("/");
          return;
        }
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((err) => {});
  }, []);

  const handleTATypeChange = (e) => {
    let string = e.join(", ");
    setTATypeOptions(string);
  };

  const handleTAThroughChange = (e) => {
    let string = e.join(", ");
    setTAThroughOptions(string);
  };

  const pathname = window.location.pathname.split("/");
  const id = pathname[3];

  const searchBeneficiary = (q) => {
    fetch(`/api/waterproviders/search/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setBeneficiaryList(data);
      })
      .catch((e) => {});
  };

  function searchRepName(role, q) {
    fetch(`/api/auth/seachbyname/${role}/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        console.log(data);
        setSO(data);
      })
      .catch((e) => {});
  }

  const SoList = (params) => {
    return (
      <div
        className="so_list"
        onClick={() => {
          setSelectedRep(params.item);
          setSO(null);
        }}
      >
        <h4>{params.item.Name}</h4>
        <p>Phone: {params.item.Phone}</p>
        <p>Position: {params.item.Position}</p>
      </div>
    );
  };

  const updateDocument = async () => {
    setError("");
    let d = body;
    d.Beneficiary = selectedBeneficiary?.Name;
    d.BeneficiaryType = selectedBeneficiary?.Type;
    d.County = selectedBeneficiary?.County;
    d.SubCounty = selectedBeneficiary?.SubCounty;
    d.Ward = selectedBeneficiary?.Ward;
    d.Village = selectedBeneficiary?.Village;
    d.Date = Date.current.value;
    d.Longitude = Longitude.current.value;
    d.Latitude = Latitude.current.value;
    d.ContactName = ContactName.current.value;
    d.ContactPhone = ContactPhone.current.value;
    d.Designation = Designation.current.value;
    d.TA_Provided = TATypeOptions;
    d.TAThrough = TAThroughOptions;
    d.TADescription = TADescription.current.value;
    d.ERepName = ERepName.current.value;
    d.ERepDesignation = ERepDesignation.current.value;
    d.WKWPRepName = selectedRep ? selectedRep.Name : props?.data?.WKWPRepName;
    d.WKWPRepDesignation = selectedRep
      ? selectedRep.Position
      : props?.data?.WKWPRepDesignation;
    if (myFile.current.files.length > 0) {
      d.File = myFile.current.files.length > 0 ? myFile.current.files[0] : null;

      d.File = await convertFileToBase64(d.File);
    }
    d.ERepSignature = ERepSignature.current.toDataURL();
    d.WKWPRepSignature = WKWPRepSignature.current.toDataURL();
    d.UserID = selectedRep ? selectedRep.UserID : props?.data?.UserID;
    console.log(d);
    setBody(d);

    setLoading(true);
    fetch(`/api/taf/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/taf-list";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  function getFile() {
    setLoading(true);
    fetch(`/api/uploads/${data.File}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 401) {
          navigate("/");
          return;
        }
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then((data) => {
        const imageURL = URL.createObjectURL(data);
        window.open(imageURL, "_blank");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const saveDocument = async () => {
    setLoading(true);

    fetch(`/api/taf/${id}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Status: props?.data?.Status == true ? false : true,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/taf-list";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const BenList = (params) => {
    return (
      <div
        onClick={() => {
          setSelectedBeneficiary(params.item);
          setBeneficiaryList(null);
        }}
        className="so_list"
      >
        <h4>{params.item.Name}</h4>
        <p>Type of Beneficiary: {params.item.Type}</p>
        <p>County: {params.item.County}</p>
        <hr height="1" />
      </div>
    );
  };

  return (
    <div className="dataentry">
      <div className="new">
        <div className="taf">
          <div className="search">
            <Input
              type="text"
              readOnly={props?.data?.Status}
              handleChange={(e) => {
                searchBeneficiary(e);
              }}
              label="Name of Beneficiary *"
              hint="Type to search"
              value={
                selectedBeneficiary?.Name
                  ? selectedBeneficiary.Name
                  : props?.data?.Beneficiary
              }
            />
            {beneficiaryList && (
              <div className="search_list">
                {beneficiaryList.map((item, i) => {
                  return <BenList key={i} item={item} />;
                })}
              </div>
            )}
          </div>
          <Input
            ref={Date}
            readOnly={props?.data?.Status}
            type="date"
            label="Date of Visit/TA *"
            value={props?.data?.Date}
          />
          <div className="div2equal">
            <Input
              ref={Latitude}
              readOnly={props?.data?.Status}
              value={props?.data?.Latitude}
              type="number"
              label="Latitude (Use negative for values South of equator) *"
            />
            <Input
              ref={Longitude}
              readOnly={props?.data?.Status}
              value={props?.data?.Longitude}
              type="number"
              label="Longitude *"
            />
          </div>
          <div className="div3equal">
            <Input
              ref={ContactName}
              type="text"
              readOnly={props?.data?.Status}
              label="Contact Person Name *"
              value={props?.data?.ContactName}
            />
            <Input
              ref={ContactPhone}
              type="number"
              readOnly={props?.data?.Status}
              label="Contact Person Phone *"
              value={props?.data?.ContactPhone}
            />
            <Input
              ref={Designation}
              type="text"
              readOnly={props?.data?.Status}
              label="Contact Person Designation *"
              value={props?.data?.Designation}
            />
          </div>
          <div className="div2equal">
            <MultiSelect
              ref={TA_Provided}
              label="Type of TA Provided *"
              options={TATypes}
              name="TAType"
              readOnly={props?.data?.Status}
              value={props?.data?.TA_Provided}
              setChanged={handleTATypeChange}
            />
            <MultiSelect
              ref={TAThrough}
              label="TA Through *"
              readOnly={props?.data?.Status}
              options={TAThroughList}
              name="TAThrough"
              value={props?.data?.TAThrough}
              setChanged={handleTAThroughChange}
            />
          </div>
          <br />
          <br />
          <Input
            ref={TADescription}
            type="textarea"
            readOnly={props?.data?.Status}
            label="Brief Description of TA Provided *"
            value={props?.data?.TADescription}
          />
          <h3>Sign Off - WKWP Representative</h3>
          <div className="div2equal">
            <div className="search">
              <Select
                setChanged={(v) => {
                  let d = data.filter((obj) => obj.Name === v);
                  if (d.length > 0) {
                    setSelectedRep(d[0]);
                  }
                  console.log(selectedRep);
                }}
                readOnly={props?.data?.Status}
                data={data.map((obj) => obj.Name)}
                label="Select WKWP Representative"
              />
              <div>
                <h4>
                  <b>Name:</b>{" "}
                  {selectedRep ? selectedRep.Name : props?.data?.WKWPRepName}
                </h4>
                <h4>
                  <b>Designation:</b>{" "}
                  {selectedRep
                    ? selectedRep.Position
                    : props?.data?.WKWPRepDesignation}
                </h4>
              </div>
            </div>
            <SignatureInput
              ref={WKWPRepSignature}
              value={props?.data?.WKWPRepSignature}
              readOnly={props?.data?.Status}
              label="Entity Represenative"
            />
          </div>

          <h3>Sign Off - Entity Representative</h3>
          <div className="div2equal">
            <div>
              <Input
                value={props?.data?.ERepName}
                ref={ERepName}
                readOnly={props?.data?.Status}
                type="text"
                label="Name *"
              />
              <br />
              <Input
                value={props?.data?.ERepDesignation}
                ref={ERepDesignation}
                readOnly={props?.data?.Status}
                type="text"
                label="Designation *"
              />
            </div>
            <SignatureInput
              ref={ERepSignature}
              readOnly={props?.data?.Status}
              value={props?.data?.ERepSignature}
              label="Entity Represenative Signature"
            />
          </div>

          <h3>Upload Scanned TAF (Optional)</h3>
          {props?.data?.File != null && (
            <h4
              onClick={() => {
                getFile();
              }}
              className="view"
            >
              <FontAwesomeIcon icon={faDownload} />
              View uploaded file
            </h4>
          )}
          <div className="file-input">
            <input ref={myFile} type="file" accept=".pdf" />
            <FontAwesomeIcon onClick={() => {}} icon={faTrash} className="ic" />
          </div>

          <h6>{error}</h6>
          {props?.data?.Status != true && (
            <Button handleClick={updateDocument} value="Finish" />
          )}
          <br />
          {role == "Admin" && (
            <button
              title="No further edits will be possible for this record. Data will be available for analysis on the dashboard"
              className="btn1"
              onClick={() => {
                saveDocument();
              }}
            >
              {props?.data?.Status == true
                ? "Turn On Editing"
                : "Turn Off Editing"}
            </button>
          )}
        </div>
        {loading && <Loading />}
      </div>
    </div>
  );
};

const Error = (props) => {
  return (
    <div className="error">
      <p>This form is only available after filling the basic details</p>
    </div>
  );
};
