import { useEffect, useRef, useState } from "react";
import "../../../Styles/dataentry.scss";
import Button from "../../Util/Button";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import Select from "../../Util/Select";
import SignatureInput from "../../Util/SignatureInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

export default function UpdateARFDetails(props) {
  const topics = [
    "Governance",
    "Technical",
    "Financial management",
    "Service delivery",
    "Human resources - Staff behaviour",
    "Monitoring systems",
    "Project planning and management of implementation",
    "Financial management",
    "Regulatory compliance/Enforcement of policies",
    "Adherence to stakeholder mandates",
    "Equity/Client orientation",
    "Adequacy for the context",
  ];

  const subTopics = [
    "Training of management committees on leadership and management",
    "Awareness creation on water sector reforms and their effect on water service provision",
    "Training of the management committees on operation and maintenance of the water facilities",
    "Training of rural water management committees on financial management",
    "Training of the rural water enterprises on customer care",
    "WRM advocacy and Stakeholder engagement",
    "Groundwater and surface data collection and management methodologies",
    "WRUA Organizational Governance and Leadership",
    "Financial Management and Resource Mobilization",
    "Training on PES",
    "Riparian and Catchment restoration and conservation",
    "Agricultural best management practices",
    "Water Resource protection and pollution control",
    "Climate change mitigation ",
    "Water infrastructure development ",
    "Alternative Income Generating Activities",
  ];

  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const [countyList, setCountyList] = useState([]);
  const [subcountyList, setSubCountyList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [selCounty, setSelCounty] = useState(null);
  const [selSubCounty, setSelSubCounty] = useState(null);
  const [rep, setRep] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [body, setBody] = useState({
    ActivityName: null,
    ActivityOrganizer: null,
    County: null,
    SubCounty: null,
    Ward: null,
    Village: null,
    Longitude: null,
    Latitude: null,
    FacilitatorName: null,
    FacilitatorContact: null,
    FacilitatorOrganisation: null,
    ActivityType: null,
    ActivitySector: null,
    TrainingTopics: null,
    TrainingSubTopics: null,
    ActivityDescription: null,
    Date: null,
    NumberOfYouths: null,
    AdultsABove30: null,
    MaleAtendees: null,
    FemaleAtendees: null,
    PWDAtendees: null,
    ERepName: null,
    ERepDesignation: null,
    WKWPRepName: null,
    WKWPRepDesignation: null,
    File: null,
    UserID: null,
  });
  const ActivityName = useRef();
  const ActivityOrganizer = useRef();
  const County = useRef();
  const SubCounty = useRef();
  const Ward = useRef();
  const Village = useRef();
  const Longitude = useRef();
  const Latitude = useRef();
  const FacilitatorName = useRef();
  const FacilitatorContact = useRef();
  const FacilitatorOrganisation = useRef();
  const ActivityType = useRef();
  const ActivitySector = useRef();
  const TrainingTopics = useRef();
  const TrainingSubTopics = useRef();
  const ActivityDescription = useRef();
  const Date = useRef();
  const NumberOfYouths = useRef();
  const AdultsABove30 = useRef();
  const MaleAtendees = useRef();
  const FemaleAtendees = useRef();
  const PWDAtendees = useRef();
  const ERepName = useRef();
  const ERepDesignation = useRef();
  const ERepSignature = useRef();
  const WKWPRepSignature = useRef();
  const myFile = useRef();
  const [role, setRole] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setRole(props?.user?.Role);

    fetch(`/api/auth`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        console.log(data);
        setData(data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    fetch("/api/counties/uniquecounties", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setCountyList(data);
        if (data.length > 0) {
          setSelCounty(data[0]);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (selCounty) {
      fetch(`/api/counties/getsubcounties/${selCounty}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setSubCountyList(data);
          if (data.length > 0) setSelSubCounty(data[0]);
        })
        .catch((e) => {});
    }
  }, [selCounty]);

  useEffect(() => {
    if (selSubCounty) {
      fetch(`/api/counties/getwards/${selSubCounty}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWardList(data);
        })
        .catch((e) => {});
    }
  }, [selSubCounty]);

  const updateActivity = async () => {
    setError("");
    let d = body;
    d.ActivityName = ActivityName.current.value;
    d.ActivityOrganizer = ActivityOrganizer.current.value;
    d.County = County.current.value;
    d.SubCounty = SubCounty.current.value;
    d.Ward = Ward.current.value;
    d.Village = Village.current.value;
    d.Date = Date.current.value;
    d.Longitude = Longitude.current.value;
    d.Latitude = Latitude.current.value;
    d.FacilitatorName = FacilitatorName.current.value;
    d.FacilitatorContact = FacilitatorContact.current.value;
    d.FacilitatorOrganisation = FacilitatorOrganisation.current.value;
    d.ActivityType = ActivityType.current.value;
    d.ActivitySector = ActivitySector.current.value;
    d.TrainingTopics = TrainingTopics.current.value;
    d.TrainingSubTopics = TrainingSubTopics.current.value;
    d.ActivityDescription = ActivityDescription.current.value;
    d.NumberOfYouths = NumberOfYouths.current.value;
    d.AdultsABove30 = AdultsABove30.current.value;
    d.MaleAtendees = MaleAtendees.current.value;
    d.FemaleAtendees = FemaleAtendees.current.value;
    d.PWDAtendees = PWDAtendees.current.value;
    d.ERepName = ERepName.current.value;
    d.ERepDesignation = ERepDesignation.current.value;
    d.WKWPRepName = selectedRep ? selectedRep.Name : props?.data?.WKWPRepName;
    d.WKWPRepDesignation = selectedRep
      ? selectedRep.Position
      : props?.data?.WKWPRepDesignation;
    if (myFile.current.files.length > 0) {
      d.File = myFile.current.files.length > 0 ? myFile.current.files[0] : null;

      d.File = await convertFileToBase64(d.File);
    }
    d.ERepSignature = ERepSignature.current.toDataURL();
    d.WKWPRepSignature = WKWPRepSignature.current.toDataURL();
    d.UserID = selectedRep ? selectedRep.UserID : props?.data?.UserID;
    setBody(d);

    setLoading(true);
    console.log(d);

    fetch(`/api/arf/${props?.data.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/arf-list";
          }, 3000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  function getFile() {
    setLoading(true);
    fetch(`/api/uploads/${props?.data.File}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then((data) => {
        const imageURL = URL.createObjectURL(data);
        window.open(imageURL, "_blank");
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }

  const saveDocument = async () => {
    setLoading(true);

    fetch(`/api/arf/${props?.data?.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        Status: props?.data?.Status == true ? false : true,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/dataentry/arf-list";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="taf">
      <Input
        ref={ActivityName}
        readOnly={props?.data?.Status}
        value={props?.data?.ActivityName}
        label="Activity Name *"
      />
      <Input
        ref={ActivityOrganizer}
        readOnly={props?.data?.Status}
        value={props?.data?.ActivityOrganizer}
        label="Activity Organizer/Convener *"
      />

      <div className="div2equal">
        <Select
          ref={County}
          data={countyList}
          readOnly={props?.data?.Status}
          value={props?.data?.County}
          label="County *"
          setChanged={(e) => {
            setSelCounty(e);
          }}
        />
        <Select
          ref={SubCounty}
          readOnly={props?.data?.Status}
          value={props?.data?.SubCounty}
          label="Sub-County *"
          setChanged={(e) => {
            setSelSubCounty(e);
          }}
          data={subcountyList}
        />
      </div>
      <div className="div2equal">
        <Select
          ref={Ward}
          readOnly={props?.data?.Status}
          value={props?.data?.Ward}
          label="Ward *"
          data={wardList}
        />
        <Input
          ref={Village}
          readOnly={props?.data?.Status}
          value={props?.data?.Village}
          type="text"
          label="Village/Town *"
        />
      </div>
      <div className="div2equal">
        <Input
          ref={Latitude}
          readOnly={props?.data?.Status}
          value={props?.data?.Latitude}
          type="number"
          label="Latitude (Use negative for values South of equator) *"
        />
        <Input
          ref={Longitude}
          readOnly={props?.data?.Status}
          value={props?.data?.Longitude}
          type="number"
          label="Longitude *"
        />
      </div>
      <div className="div3equal">
        <Input
          ref={FacilitatorName}
          readOnly={props?.data?.Status}
          value={props?.data?.FacilitatorName}
          type="text"
          label="Name of Facilitator *"
        />
        <Input
          ref={FacilitatorContact}
          readOnly={props?.data?.Status}
          value={props?.data?.FacilitatorContact}
          type="number"
          label="Facilitator Phone *"
        />
        <Input
          ref={FacilitatorOrganisation}
          readOnly={props?.data?.Status}
          value={props?.data?.FacilitatorOrganisation}
          type="text"
          label="Organization *"
        />
      </div>
      <div className="div2equal">
        <Select
          ref={ActivityType}
          readOnly={props?.data?.Status}
          value={props?.data?.ActivityType}
          label="Activity Type *"
          data={[
            "",
            "Training",
            "Workshop",
            "Meeting",
            "Technical Assistance",
            "Other",
          ]}
        />
        <Input
          ref={Date}
          readOnly={props?.data?.Status}
          value={props?.data?.Date}
          type="date"
          label="Date *"
        />
      </div>

      <Input
        ref={ActivitySector}
        readOnly={props?.data?.Status}
        value={props?.data?.ActivitySector}
        label="Activity Sector *"
      />

      <h4>If Activity Type is Training ... </h4>
      <hr />
      <Select
        ref={TrainingTopics}
        readOnly={props?.data?.Status}
        value={props?.data?.ActivityType}
        label="Training Topic Covered (optional) *"
        data={topics}
      />
      <Select
        ref={TrainingSubTopics}
        readOnly={props?.data?.Status}
        value={props?.data?.ActivityType}
        label="Sub Topics Covered (optional) *"
        data={subTopics}
      />
      <hr />
      <br />
      <Input
        ref={ActivityDescription}
        readOnly={props?.data?.Status}
        value={props?.data?.ActivityDescription}
        type="textarea"
        label="Brief Description of Activity Covered *"
      />
      <h4>Activity Participants...</h4>
      <hr />
      <div className="div2equal">
        <Input
          ref={NumberOfYouths}
          readOnly={props?.data?.Status}
          type="number"
          label="Number of Youths"
          placeholder="Number of youths"
          value={props?.data?.NumberOfYouths}
        />
        <Input
          ref={AdultsABove30}
          readOnly={props?.data?.Status}
          type="number"
          label="Number of Adults above 30"
          placeholder="Number of Adults above 30"
          value={props?.data?.AdultsABove30}
        />
      </div>
      <div className="div3equal">
        <Input
          ref={MaleAtendees}
          readOnly={props?.data?.Status}
          type="number"
          label="Number male atendees"
          placeholder="Number male atendees"
          value={props?.data?.MaleAtendees}
        />
        <Input
          ref={FemaleAtendees}
          readOnly={props?.data?.Status}
          type="number"
          label="Number of female atendees"
          placeholder="Number of female atendees"
          value={props?.data?.FemaleAtendees}
        />
        <Input
          ref={PWDAtendees}
          readOnly={props?.data?.Status}
          type="number"
          label="Number of PWD atendees"
          placeholder="Number of PWD atendees"
          value={props?.data?.PWDAtendees}
        />
      </div>
      <h3>Sign Off - WKWP Representative</h3>
      <div className="div2equal">
        <div className="search">
          <Select
            setChanged={(v) => {
              let d = data.filter((obj) => obj.Name === v);
              if (d.length > 0) {
                setSelectedRep(d[0]);
              }
              console.log(selectedRep);
            }}
            data={data.map((obj) => obj.Name)}
            label="Select WKWP Representative"
          />
          <div>
            <h4>
              <b>Name:</b>{" "}
              {selectedRep ? selectedRep.Name : props?.data?.WKWPRepName}
            </h4>
            <h4>
              <b>Designation:</b>{" "}
              {selectedRep
                ? selectedRep.Position
                : props?.data?.WKWPRepDesignation}
            </h4>
          </div>
        </div>
        <SignatureInput
          ref={WKWPRepSignature}
          readOnly={props?.data?.Status}
          value={props?.data?.WKWPRepSignature}
          label="Entity Represenative"
        />
      </div>

      <h3>Sign Off - Entity Representative</h3>
      <div className="div2equal">
        <div>
          <Input
            value={props?.data?.ERepName}
            readOnly={props?.data?.Status}
            ref={ERepName}
            type="text"
            label="Name *"
          />
          <br />
          <Input
            value={props?.data?.ERepDesignation}
            readOnly={props?.data?.Status}
            ref={ERepDesignation}
            type="text"
            label="Designation *"
          />
        </div>
        <SignatureInput
          ref={ERepSignature}
          value={props?.data?.ERepSignature}
          readOnly={props?.data?.Status}
          label="Entity Represenative Signature"
        />
      </div>

      <h3>Upload Scanned ARF (Optional)</h3>
      {props?.data?.File != null && (
        <h4
          onClick={() => {
            getFile();
          }}
          className="view"
        >
          <FontAwesomeIcon icon={faDownload} /> View uploaded file
        </h4>
      )}
      <div className="file-input">
        <input
          readOnly={props?.data?.Status}
          ref={myFile}
          type="file"
          accept=".pdf"
        />
        <FontAwesomeIcon onClick={() => {}} icon={faTrash} className="ic" />
      </div>

      <h6>{error}</h6>

      {props?.data?.Status != true && (
        <Button handleClick={updateActivity} value="Submit" />
      )}
      <br />
      {role == "Admin" && (
        <button
          title="No further edits will be possible for this record. Data will be available for analysis on the dashboard"
          className="btn1"
          onClick={() => {
            saveDocument();
          }}
        >
          {props?.data?.Status == true ? "Turn On Editing" : "Turn Off Editing"}
        </button>
      )}
      {loading && <Loading />}
    </div>
  );
}
