import {
  faBars,
  faBarsStaggered,
  faBoxes,
  faChartBar,
  faClipboard,
  faEdit,
  faGear,
  faHome,
  faMap,
  faSignOut,
  faTasks,
  faTimes,
  faUserCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import wkwp_logo from "../../assets/imgs/wkwp_logo.png";
import { useNavigate } from "react-router-dom";
export default function Header(props) {
  const [nav, setNav] = useState(false);
  const [details, setDetails] = useState("");
  const [login, setLogin] = useState("");
  const [logout, setLogout] = useState("");
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/api/auth/mydetails", {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/");
        }
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Failed to fetch user details");
        }
      })
      .then((data) => {
        props.setUser(data.user);
        console.log("Expiry:", data.expiry);
        updateLogoutTime(data.expiry);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });

    const interval = setInterval(() => {
      fetch("/api/auth/mydetails", {
        method: "GET",
        credentials: "include",
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to fetch user details");
          }
        })
        .then((data) => {
          updateLogoutTime(data.expiry);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const lg = convertTime(props?.user?.iat).split("GMT")[0];
    setDetails(props?.user?.Name);
    setEmail(props?.user?.Email);
    setPosition(props?.user?.Position);
    setRole(props?.user?.Role);
    // setLogin(lg.substring(lg.length - 9, lg.length));
    // setLogout(lo.substring(lo.length - 9, lo.length));
  }, [props.user]);

  function convertTime(dt) {
    const date = new Date(dt * 1000);
    return date.toString();
  }

  function updateLogoutTime(expiry) {
    const currentTime = Math.floor(Date.now() / 1000);
    const timeLeft = expiry - currentTime;

    if (timeLeft <= 0) {
      navigate("/");
    } else {
      const minutesLeft = Math.floor(timeLeft / 60);
      setLogout(`${minutesLeft} minutes`);
    }
  }

  return (
    <>
      <div className="header">
        <div className="lt">
          <FontAwesomeIcon
            className="fa-bars"
            onClick={() => {
              setNav(true);
            }}
            icon={faBars}
          />
        </div>
        <p>
          <FontAwesomeIcon
            icon={faUserCircle}
            className="user"
            onClick={() => setShowUserPopup(true)}
          />
          {details}
          <span>
            <b> Logout Time: </b> {logout}
          </span>
        </p>
        <img src={wkwp_logo} alt="" />
      </div>
      {nav && <MobileHeader setNav={setNav} />}
    </>
  );
}

const UserPopup = (props) => {
  return (
    <div className="userPopup">
      <h2>User Information</h2>
      <p>Name: {props.details}</p>
      <p>Email: {props.email}</p>
      <p>Position: {props.position}</p>
      <p>Role: {props.role}</p>
      <p>Login Time: {props.login}</p>
      <p>Logout Time: {props.logout}</p>
      <button onClick={props.closePopup}>Close</button>
    </div>
  );
};

const MobileHeader = (props) => {
  const pathname = window.location.pathname.split("/");

  return (
    <div className="mobheader">
      <FontAwesomeIcon
        onClick={() => {
          props.setNav(false);
        }}
        icon={faTimes}
        className="fa-times"
      />
      <img src={wkwp_logo} alt="" />
      <h1>MEL-MIS</h1>

      <hr />
      <Item
        txt="Home"
        url="/home"
        active={pathname[1]}
        link="home"
        icon={faHome}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Indicators"
        url="/indicators"
        link="indicators"
        active={pathname[1]}
        icon={faChartBar}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/reports"
        link="reports"
        txt="Reports"
        active={pathname[1]}
        icon={faBoxes}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/mel"
        txt="MEL"
        link="mel"
        active={pathname[1]}
        icon={faClipboard}
        code="&#xf0ea;"
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/gis"
        txt="GIS"
        link="gis"
        active={pathname[1]}
        icon={faMap}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Counties"
        url="/counties"
        link="counties"
        active={pathname[1]}
        icon={faBarsStaggered}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/dataentry"
        txt="Data Entry"
        link="dataentry"
        active={pathname[1]}
        icon={faEdit}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/buildtool"
        txt="Tool Builder"
        link="buildtool"
        active={pathname[1]}
        icon={faClipboard}
        code="&#xf0ea;"
        options={[]}
        showing={props.showing}
      />

      <Item
        url="/wp"
        txt="Workplans"
        link="wp"
        active={pathname[1]}
        icon={faTasks}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Users"
        url="/users"
        link="users"
        active={pathname[1]}
        icon={faUsers}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Settings"
        url="/settings"
        link="settings"
        active={pathname[1]}
        icon={faGear}
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Signout"
        active={pathname[1]}
        icon={faSignOut}
        url="/logout"
        options={[]}
        showing={props.showing}
      />
    </div>
  );
};

const Item = (params) => {
  return (
    <div
      onClick={() => {
        if (params.options?.length === 0) {
          window.location.href = params.url;
        }
        if (params.url === "/logout") {
          localStorage.removeItem("gdfhgfhtkngdfhgfhtkn");
          window.location.href = "/";
        }
      }}
      onMouseEnter={() => {}}
      className={params.link === params.active ? "active" : "item"}
    >
      <FontAwesomeIcon icon={params.icon} />
      <p>{params.txt}</p>
    </div>
  );
};
