import {
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { FiInfo } from "react-icons/fi";
import Input from "../Util/Input";
import Pagination from "../Util/Pagination";
import Select from "../Util/Select";
import WaveLoading from "../Util/WaveLoading";

export default function DailyWP(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  let dayNames = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
  const { getMonths } = require("../../assets/data/data.js");
  const [year, setYear] = useState("2023");
  const [offset, setOffset] = useState(0);
  const [today, setToday] = useState(null);
  const [month, setMonth] = useState(null);
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    setToday(d);
    let x = [];
    const mi = getMonths
      .map((item) => {
        x.push(item.month);
        return item.code;
      })
      .indexOf(date[1]);

    getMonths.map((item) => {
      x.push(item.month);
    });
    setMM(x);
    if (mi !== -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    let d = [];
    getMonths.map((item) => {
      d.push(item["month"]);
    });
    setMM(d);
    const mi = getMonths
      .map((item) => {
        return item.month;
      })
      .indexOf(month);

    if (mi !== -1) {
      let x = [];
      const dtoday = parseInt(today.split("-")[2]);

      const size =
        getMonths[mi].days - dtoday < 12 ? getMonths[mi].days - dtoday : 12;
      for (
        let index = dtoday === 1 ? dtoday : dtoday - 1;
        index < size + dtoday + 1;
        index++
      ) {
        const day = `${year}-${mi + 1}-${index < 10 ? "0" + index : index}`;
        let date = new Date(day);
        let dayOfWeek = date.getDay();
        x.push({ day: dayNames[dayOfWeek], date: index, today: day });
      }
      if (x.length < 14) {
        const sz = 14 - x.length;
        for (let index = 0; index < sz; index++) {
          x.push({ day: "", date: "", today: "day" });
        }
      }
      setDD(x);
    }
  }, [month, year, today]);

  useEffect(() => {
    if (today !== null) {
      setData(null);
      setLoading(true);
      fetch(`/api/workplan/searchdate/${today}/${offset}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          console.log(data);
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [today, offset, props.refresh]);

  useEffect(() => {
    if (props.download && data && data?.data?.length > 0) {
      downloadCSV(data.data, "daily workplans.csv");
      props.setDownload(false);
    }
  }, [props.download]);

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  function addDaysToDate(date, daysToAdd) {
    const result = new Date(date);
    result.setDate(result.getDate() + daysToAdd);
    return result.toISOString().split("T")[0];
  }

  function minusDaysToDate(date, daysToAdd) {
    const result = new Date(date);
    result.setDate(result.getDate() - daysToAdd);
    return result.toISOString().split("T")[0];
  }

  return (
    <div className="dailywp">
      <div className="month">
        <h4>
          {month}, {year}
        </h4>
        <Select
          setChanged={(v) => {
            setMonth(v);
          }}
          data={mm}
          value={month}
        />
        <Select
          setChanged={(v) => {
            setYear(v);
          }}
          value={year}
          data={["2023", "2024", "2025", "2026"]}
        />
      </div>

      <div className="dates">
        <FaAngleLeft
          onClick={() => {
            let d = minusDaysToDate(today, 1);
            setToday(d);
          }}
          className="fa"
          color="#002F6C"
        />
        {dd.length > 0 &&
          dd.map((item, i) => {
            return (
              <DateItem key={i} item={item} setToday={setToday} today={today} />
            );
          })}
        <FaAngleRight
          onClick={() => {
            let d = addDaysToDate(today, 1);
            setToday(d);
          }}
          className="fa"
          color="#002F6C"
        />
      </div>

      <div className="dlist">
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <SOItem
                key={i}
                item={item}
                setRefresh={props.setRefresh}
                setLoading={setLoading}
                refresh={props.refresh}
              />
            );
          })}
      </div>
      <Pagination
        totalItems={data?.total}
        currentPage={offset}
        onPageChange={setOffset}
      />
      {loading && <WaveLoading />}
    </div>
  );
}

const DateItem = (props) => {
  return (
    <div
      onClick={() => {
        props.setToday(props.item.today);
      }}
      className={props.item.today === props.today ? "active" : "dateitem"}
    >
      <p>{props.item.date}</p>
      <h4>{props.item.day}</h4>
    </div>
  );
};

const SOItem = (props) => {
  const colors = ["#FFF1E8", "#D7F3FF", "#FFF1F1", "#B5CB99", "#F1F4FF"];
  const [color, setColor] = useState("#FFF8C9");
  const [showDetails, setShowingDetails] = useState(false);
  const [showEdit, setShowingEdit] = useState(false);
  const [init, setInit] = useState("");
  useEffect(() => {
    const nm = props.item.Name.split(" ");
    if (nm.length > 1) {
      setInit(nm[0].substring(0, 1) + nm[1].substring(0, 1));
    } else setInit(nm[0].substring(0, 1));
  }, []);

  useEffect(() => {
    switch (props.item.Reason) {
      case "Normal":
        setColor("#FFF8C9");
        break;
      case "Inspection":
        setColor(colors[0]);
        break;
      case "Meeting":
        setColor(colors[1]);
        break;
      case "Training":
        setColor(colors[2]);
      case "Maintenance":
        setColor(colors[3]);
        break;
      case "Monitoring":
        setColor(colors[4]);
        break;
      default:
        break;
    }
  }, [props.item]);

  function deleteWP() {
    props.setLoading(true);
    fetch(`/api/workplan/${props.item.ID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(true);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(true);
      });
  }

  function formatItemList(items) {
    var itemListArray = items.split(",");
    var formattedItemList = [];
    for (var i = 0; i < itemListArray.length; i++) {
      var item = itemListArray[i].trim();
      formattedItemList.push(i + 1 + ". " + item);
    }

    return formattedItemList.join("\n");
  }

  return (
    <>
      <div className="soitem">
        <div className="update">
          <AiOutlineDelete
            onClick={() => {
              deleteWP();
            }}
            color="white"
            size={24}
            className="pencil delete"
          />
          {/* <FiEdit
            onClick={() => {
              setShowingEdit(true);
            }}
            color="white"
            size={24}
            className="pencil"
          /> */}
          <FiInfo
            onClick={() => {
              setShowingDetails(true);
            }}
            color="white"
            size={24}
            className="pencil"
          />
        </div>

        <div className="title">
          <div className="left">
            <h2>{init}</h2>
          </div>
          <div>
            <h4>{props.item.Name}</h4>
            <p>
              <b>Phone: </b> {props.item.Phone}
            </p>
            <p>
              <b>Employee No: </b> {props.item.EmployeeNo}
            </p>
          </div>
        </div>

        <h4>Workplan Details</h4>
        <hr />
        <div className="div3equal">
          <p className="task">
            <b>Partner: </b> {props.item.Partner}
          </p>
          <p>
            <b>Date: </b> {props.item.Date}
          </p>
          <p>
            <b>Venue: </b> {props.item.Venue}
          </p>
          <p>
            <b>Mel Indicator: </b>
            {props.item.MelIndicator}
          </p>
          <p>
            <b>Who / Lead: </b>
            {props.item.Lead}
          </p>
        </div>
        <h4>Resorces Needed</h4>
        <hr />
        {formatItemList(props.item.ResourcesNeeded)}

        <h4>Supervision</h4>
        <hr />
        <p>
          <b>Supervisor: </b>
          {props.item.supervisor}
        </p>
        <p>
          <b>Position: </b> {props.item.supervisorposition},{" "}
          {props.item.Address}
        </p>
      </div>
      {showEdit && (
        <EditPopup
          setShowing={setShowingEdit}
          ID={props.item.ID}
          Reason={props.item.Reason}
          setRefresh={props.setRefresh}
          setLoading={props.setLoading}
          refresh={props.refresh}
        />
      )}
      {showDetails && (
        <DetailsPopup
          setShowing={setShowingDetails}
          item={props.item}
          setRefresh={props.setRefresh}
          setLoading={props.setLoading}
          refresh={props.refresh}
        />
      )}
    </>
  );
};

const EditPopup = (props) => {
  const rs = useRef();
  const rfdate = useRef();

  function updateWP() {
    props.setLoading(true);
    fetch(`/api/workplan/${props.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ Date: rfdate.current.value }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(true);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(true);
      });
  }
  return (
    <div className="editpopup">
      <div className="card">
        <FontAwesomeIcon
          onClick={() => {
            props.setShowing(false);
          }}
          className="fa-times"
          icon={faXmark}
        />
        <h3>Update Work Plan</h3>
        <hr />
        <div className="content">
          <Input type="date" label="Date" ref={rfdate} />
          <button
            onClick={() => {
              updateWP();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const DetailsPopup = (props) => {
  const approval = useRef();
  const spremarks = useRef();
  const [data, setData] = useState(null);

  useEffect(() => {
    const cols = Object.entries(props.item);

    setData(props.item);
  }, []);

  function updateWP() {
    props.setLoading(true);
    fetch(`/api/workplan/${props.item.ID}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        SupervisorComments: spremarks.current.value,
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        props.setLoading(true);
        props.setRefresh(!props.refresh);
      })
      .catch((e) => {
        props.setLoading(true);
      });
  }
  return (
    <div className="detailspopup">
      <div className="card">
        <FontAwesomeIcon
          onClick={() => {
            props.setShowing(false);
          }}
          className="fa-times"
          icon={faXmark}
        />
        <h3>Work Plan Details</h3>
        <hr />
        <div className="content">
          {data && (
            <>
              <Row ikey={"Partner"} item={data.Partner} />
              <Row ikey={"MEL Indicator"} item={data.MelIndicator} />
              <Row ikey={"Description"} item={data.Description} />
              <Row ikey={"Date"} item={data.Date} />
              <Row ikey={"Venue"} item={data.Venue} />
              <Row ikey={"ResourcesNeeded"} item={data.ResourcesNeeded} />
            </>
          )}

          <div className="section">
            <hr />
            <h4>Officer</h4>
            {data && (
              <>
                <Row ikey={"Name"} item={data.Name} />
                <Row ikey={"Phone"} item={data.Phone} />
                <Row ikey={"Remarks"} item={data.TaskRemarks} />
              </>
            )}
          </div>

          <div className="section">
            <hr />
            <h4>Supervisor</h4>
            {data && (
              <>
                <Row ikey={"Name"} item={data.supervisor} />
                <Row ikey={"Position"} item={data.supervisorposition} />
                <Row ikey={"Phone"} item={data.Phone} />
              </>
            )}
            <Row ikey={"SupervisorComments"} item={data?.SupervisorComments} />
            <br />
            <Input
              type="textarea"
              placeholder={data?.SupervisorComments}
              label="Supervisor Remarks"
              ref={spremarks}
            />
            <button
              onClick={() => {
                updateWP();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Row = (params) => {
  return (
    <div className="prow">
      <p>{params.ikey}</p>
      <p>{params.item}</p>
    </div>
  );
};
