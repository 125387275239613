import { useEffect, useRef, useState } from "react";
import "../../Styles/workplan.scss";
import Input from "../Util/Input";
import Select from "../Util/Select";
import WaveLoading from "../Util/WaveLoading";
import { FaArrowRight } from "react-icons/fa6";
import { param } from "jquery";

export default function CreateWorkPlan(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fo, setFO] = useState(null);
  const [so, setSO] = useState(null);
  const [fodata, setFOData] = useState(null);
  const [sodata, setSOData] = useState(null);
  const [su, setSU] = useState(null);
  const [sudata, setSUData] = useState(null);
  const [userid, setUserId] = useState(null);

  const [countyList, setCountyList] = useState([]);
  const [subcountyList, setSubCountyList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [selCounty, setSelCounty] = useState(null);
  const [selSubCounty, setSelSubCounty] = useState(null);

  const rfPartner = useRef();
  const rfdate = useRef();
  const rfMelIndicator = useRef();
  const rfVenue = useRef();
  const rfLead = useRef();
  const rfDescription = useRef();
  const rfComments = useRef();
  const rfsupervisorid = useRef();
  const rfResources = useRef();

  const [body, setBody] = useState({
    UserID: null,
    Partner: null,
    SupervisorID: null,
    Date: null,
    Lead: null,
    MelIndicator: null,
    Description: null,
    Venue: null,
    ResourcesNeeded: null,
    SupervisorComments: null,
  });
  const [showing, setShowing] = useState(true);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setShowing(false);
    } else {
      setShowing(true);
    }
  };

  useEffect(() => {
    setUserId(props?.user?.UserID);
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  function searchByNameMobile(role, q) {
    fetch(`/api/mobile/seachbyName/${role}/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        console.log(data);
        setFO(data);
      })
      .catch((e) => {});
  }

  function searchByNameWeb(role, q) {
    fetch(`/api/auth/seachbyname/${role}/${q}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setSO(data);
      })
      .catch((e) => {});
  }

  useEffect(() => {
    fetch("/api/counties/uniquecounties", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setCountyList(data);
        if (data.length > 0) {
          setSelCounty(data[0]);
        }
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    if (selCounty) {
      fetch(`/api/counties/getsubcounties/${selCounty}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setSubCountyList(data);
          if (data.length > 0) setSelSubCounty(data[0]);
        })
        .catch((e) => {});
    }
  }, [selCounty]);

  useEffect(() => {
    if (selSubCounty) {
      fetch(`/api/counties/getwards/${selSubCounty}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setWardList(data);
        })
        .catch((e) => {});
    }
  }, [selSubCounty]);

  const createWorkplan = (e) => {
    setError("");
    let d = body;
    d.Partner = rfPartner.current.value;
    d.MelIndicator = rfMelIndicator.current.value;
    d.Description = rfDescription.current.value;
    d.Venue = rfVenue.current.value;
    d.ResourcesNeeded = rfResources.current.value;
    d.SupervisorComments = rfComments.current.value;
    d.Date = rfdate.current.value;
    d.Lead = rfLead.current.value;
    setBody(d);

    const keysToExclude = ["Description", "SupervisorComments"];
    const cols = Object.keys(d);

    const chck = Object.keys(d)
      .filter((key) => !keysToExclude.includes(key))
      .map((key) => d[key]);
    let valid = true;
    chck.map((item, i) => {
      if (item === null || item === "") {
        valid = false;
        if (!valid) return setError(`${cols[i]} is required!`);
      }
    });
    if (!valid) return;

    setLoading(true);

    fetch("/api/workplan/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(d),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/wp";
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
        setError("Oops! Something went wrong!");
      });
  };

  const List = (params) => {
    return (
      <div
        onClick={() => {
          let d = body;
          d.Task = params.item.OrgID;
          setBody(d);
          setSUData(params.item);
          setSU(null);
        }}
        className="so_list"
      >
        <h2>{params.item.Organization} </h2>
        <h5>
          {params.item.City}, {params.item.Address}
        </h5>
      </div>
    );
  };

  const SOList = (params) => {
    return (
      <div
        onClick={() => {
          let d = body;
          if (params.officer === "field") {
            d.UserID = params.item.UserID;
            setFOData(params.item);

            console.log(params.item);
          } else {
            if (params.officer === "supervisor") {
              d.SupervisorID = params.item.UserID;
              setSOData(params.item);
            }
          }
          setBody(d);
          setFO(null);
          setSO(null);
        }}
        className="so_list"
      >
        <h2>{params.item.Name}</h2>
        <h5>
          <b>Phone: {params.item.Phone}</b>
        </h5>
        <h5>
          <b>Category: {params.item.Category}</b>
        </h5>
      </div>
    );
  };

  return (
    <div className="workplan">
      <div className="container">
        <div className="createworkplan">
          <div className="cpcontainer">
            <div className="tp">
              <h3>Create Work Plan</h3>
              <h4
                onClick={() => {
                  window.location.href = "/wp";
                  // props.setCreating(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <FaArrowRight className="fa-right" /> Work Plans
              </h4>
            </div>
            <div className="bdy">
              <div className="div2equal">
                <div className="section">
                  <h4>Search Officer</h4>
                  <hr />
                  <div className="div2equal">
                    <div className="search">
                      <Input
                        type="text"
                        handleChange={(e) => {
                          searchByNameMobile("WKWP Staff", e);
                        }}
                        label="Name"
                        hint="Type to search"
                      />
                      {fo && (
                        <div className="search_list">
                          <SOList item={fo} officer="field" />

                          {/* {fo.map((item, i) => {
                            return (
                              <SOList key={i} item={item} officer="field" />
                            );
                          })} */}
                        </div>
                      )}
                    </div>

                    <div>
                      {fodata && (
                        <SOItem
                          title="Selection"
                          clearEvent={() => {
                            let d = body;
                            d.UserID = null;
                            setBody(d);
                            setFOData(null);
                          }}
                          data={fodata}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="section">
                  <h4>Search Supervisor</h4>
                  <hr />
                  <div className="div2equal">
                    <div className="search">
                      <Input
                        type="text"
                        handleChange={(e) => {
                          searchByNameWeb("Admin", e);
                        }}
                        label="Name"
                        hint="Type to search"
                        ref={rfsupervisorid}
                      />
                      {so && (
                        <div className="search_list">
                          {so.map((item, i) => {
                            return (
                              <SOList
                                key={i}
                                item={item}
                                officer="supervisor"
                              />
                            );
                          })}
                        </div>
                      )}
                    </div>

                    <div>
                      {sodata && (
                        <SOItem
                          title="Selection"
                          clearEvent={() => {
                            let d = body;
                            d.SupervisorID = null;
                            setBody(d);
                            setSOData(null);
                          }}
                          data={sodata}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="section">
                <h4>Workplan Details</h4>
                <hr />
                <div className="div2equal">
                  <Input
                    type="text"
                    label="Market Actor / Partner"
                    ref={rfPartner}
                  />
                  <Input
                    type="date"
                    label="Date"
                    ref={rfdate}
                    value={new Date().toISOString().split("T")[0]}
                    max={true}
                  />
                </div>
                <div className="div2equal">
                  <Input
                    type="text"
                    label="Corresponding MEL Indictor"
                    hint="Mel Indicator"
                    ref={rfMelIndicator}
                  />
                  <Input type="text" label="Venue" hint="Venue" ref={rfVenue} />
                </div>
                <div className="div2equal">
                  <Input type="text" label="Who/ Lead" ref={rfLead} />
                  <Input
                    type="text"
                    label="Resources Needed"
                    ref={rfResources}
                  />
                </div>
                <div className="div2equal">
                  <Input
                    type="textarea"
                    label="Objective of Activity/ Description of Activity"
                    ref={rfDescription}
                  />
                  <Input
                    type="textarea"
                    label="Supervisor Comments"
                    ref={rfComments}
                  />
                </div>
              </div>

              <h6>{error}</h6>

              <button
                onClick={() => {
                  createWorkplan();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        {loading && <WaveLoading />}
      </div>
    </div>
  );
}

const Item = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h4>{props.title}</h4>
            <i
              onClick={() => {
                props.clearEvent();
              }}
              className="fa fa-times"
            ></i>
          </div>

          <hr />
          <p>{props.data.Organization}</p>
          <p>
            {props.data.City}, {props.data.Address}
          </p>
        </div>
      )}
    </>
  );
};

const SOItem = (props) => {
  return (
    <>
      {props.data && (
        <div className="item">
          <div>
            <h4>{props.title}</h4>
            <i
              onClick={() => {
                props.clearEvent();
              }}
              className="fa fa-times"
            ></i>
          </div>

          <hr />
          <p>Name: {props.data.Name}</p>
          <p>Employee No:{props.data.EmployeeNo}</p>
          <p>Category: {props.data.Category}</p>
        </div>
      )}
    </>
  );
};
