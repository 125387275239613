import {
  faArrowAltCircleLeft,
  faEdit,
  faFloppyDisk,
  faPlusCircle,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import "../../../Styles/dataentry.scss";
import Confirm from "../../Util/Confirm";
import Input from "../../Util/Input";
import Loading from "../../Util/Loading";
import Pagination from "../../Util/Pagination";
import Select from "../../Util/Select";

export default function RMFList(props) {
  const [data, setData] = useState(null);
  const [column, setColumn] = useState("SectorStakeholder");
  const [v, setV] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [offset, setOffset] = useState(0);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [county, setCounty] = useState("");
  const [role, setRole] = useState();

  const pathname = window.location.pathname.split("/");

  useEffect(() => {
    setRole(props?.user?.Role);
  }, []);

  // console.log(role);

  useEffect(() => {
    const countyValue =
      pathname.length === 3
        ? null
        : pathname.length === 4
        ? pathname[3]
        : county;
    setCounty(countyValue);
  }, [pathname, county]);

  useEffect(() => {
    const today = new Date();
    setStart(`${today.getFullYear() - 1}-10-01`);
    setEnd(`${today.getFullYear()}-09-30`);

    if (start !== "" && end !== "") {
      getRMF();
    }
  }, [refresh, offset]);

  function getRMF() {
    if (v === "") {
      setLoading(true);

      if (county == null) {
        fetch(`/api/rmf/paginated/${offset * 12}/${start}/${end}`, {
          method: "get",
          credentials: "include",
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);
            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      } else if ((pathname.length = 4 || pathname[3] != "")) {
        fetch(`/api/rmf/paginated/${offset * 12}/${start}/${end}/${county}`, {
          method: "get",
          credentials: "include",
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setLoading(false);
            setData(data);
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    } else {
      quickSearch(v);
    }
  }

  function quickSearch(v) {
    setLoading(true);
    if (pathname.length > 4) {
      fetch(`/api/rmf/quicksearch/${column}/${v}/${offset * 12}/${county}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    } else {
      fetch(`/api/rmf/quicksearch/${column}/${v}/0`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setLoading(false);
          setData(data);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (start !== "" && end !== "") {
      getRMF();
    }
  }, [start, end]);

  function deleteRMF(rmfID) {
    setLoading(true);
    fetch(`/api/rmf/${rmfID}`, {
      method: "DELETE",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setLoading(false);
        setRefresh(!refresh);
      })
      .catch((err) => {});
  }

  function approveRMF(rmfid) {
    setLoading(true);
    fetch(`/api/rmf/${rmfid}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ IsApproved: true }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setRefresh(refresh);

        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div className="dataentry">
      <div className="title">
        {props.role?.Role !== "Guest" && (
          <p
            onClick={() => {
              localStorage.removeItem("editing");
              window.location.href = "/dataentry/rmf";
            }}
            className="add"
          >
            <FontAwesomeIcon icon={faPlusCircle} /> New Form
          </p>
        )}
        <Select
          setChanged={(v) => {
            setColumn(v);
          }}
          data={[
            "SectorStakeholder",
            "StakeholderType",
            "County",
            "SubCounty",
            "Ward",
            "Village",
            "Date",
            "TA_Provided",
            "TAThrough",
            "Description",
          ]}
          value={column}
          label="Search Column"
        />
        <div className="lsearch">
          <Input
            handleChange={(value) => {
              if (value.length > 0) {
                quickSearch(value);
              }
              if (value.length === 0) {
                setRefresh(!refresh);
              }
            }}
            type="text"
            label="Search ..."
          />
          <FontAwesomeIcon icon={faSearch} className="fa-search" />
        </div>
        <div className="div2equal">
          <Input
            handleChange={(e) => {
              setStart(e);
            }}
            type="date"
            value={start}
            label="Start Date"
          />
          <Input
            handleChange={(e) => {
              setEnd(e);
            }}
            type="date"
            value={end}
            label="End Date"
          />
        </div>
      </div>

      <div className="new">
        <div className="topbar">
          <h4>Result Monitoring Forms</h4>
          <div>
            {pathname.length > 3 && (
              <p
                onClick={() => {
                  window.location.href = "/dataentry/rmf-list";
                }}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} /> All Counties
              </p>
            )}
          </div>
        </div>
        <div className="list">
          {data &&
            data?.data?.length > 0 &&
            data?.data?.map((item, i) => {
              return (
                <Item
                  key={i}
                  item={item}
                  index={i + offset * 12}
                  deleteRMF={deleteRMF}
                  approveRMF={approveRMF}
                  role={role}
                />
              );
            })}
        </div>
        <Pagination
          totalItems={data?.total}
          currentPage={offset}
          onPageChange={(e) => {
            setOffset(e);
          }}
        />
        <br />
      </div>

      {/* deleteFunction={deleteRMF()} */}
      {loading && <Loading />}
    </div>
  );
}

const Item = (props) => {
  const [showConfirm, setShowConfirm] = useState(false);

  console.log(props);

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };
  return (
    <div className="item">
      <div className="ttitem">
        <h6>{props.index + 1}</h6>
        <div>
          <h4>Sector Stakeholder: {props.item.SectorStakeholder}</h4>
          <p>
            Stakeholder Type:
            {props.item.StakeholderType}
          </p>
        </div>
        <div>
          <p>TA Provided: {props.item.TA_Provided}</p>
          <p>
            TA Through:
            {props.item.TAThrough}
          </p>
        </div>
        <h3
          onClick={() => {
            window.location.href = "/dataentry/rmf-list/" + props.item.County;
          }}
        >
          {props.item.County}
        </h3>
        <h5>{props.item.Date}</h5>
        <div className="icons">
          {(props?.role?.Role == "Admin" ||
            props.item.UserID == props?.role?.UserID) && (
            <FontAwesomeIcon
              className="fa"
              icon={faEdit}
              onClick={() =>
                (window.location.href = `/dataentry/rmf-edit/${props.item.ID}`)
              }
              title="Edit RMF"
            />
          )}

          {props.item.Status != true &&
          (props?.role?.Role == "Admin" ||
            props.item.UserID == props?.role?.UserID) ? (
            <FontAwesomeIcon
              className="fa"
              icon={faTrash}
              style={{ color: props.item.Status == true ? "grey" : "#0064B6" }}
              onClick={() => {
                openConfirm();
              }}
              title="Delete RMF"
            />
          ) : (
            <div className="fa"></div>
          )}
          <FaEye
            className="fa"
            onClick={() =>
              (window.location.href = `/dataentry/preview-rmf/${props.item.ID}`)
            }
            title="View RMF"
          />
        </div>
        {showConfirm && (
          <Confirm
            closeConfirm={closeConfirm}
            deleteFunction={() => props.deleteRMF(props.item.ID)}
          />
        )}
      </div>
    </div>
  );
};
