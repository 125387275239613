import React from "react";
import {
  Box,
  Pagination as MUIPagination,
  PaginationItem,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Pagination = ({ totalItems, currentPage, onPageChange }) => {
  const totalPages = Math.max(Math.ceil(totalItems / 12), 1);
  const maxVisiblePages = 5;
  const halfVisiblePages = Math.floor(maxVisiblePages / 2);
  let startPage = Math.max(currentPage - halfVisiblePages, 1);
  let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  if (endPage - startPage < maxVisiblePages - 1) {
    startPage = Math.max(endPage - maxVisiblePages + 1, 1);
  }

  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index
  );

  const handlePageChange = (event, pageNumber) => {
    onPageChange(pageNumber - 1);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
      <MUIPagination
        count={totalPages}
        page={currentPage + 1}
        onChange={handlePageChange}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            components={{
              previous: ArrowBack,
              next: ArrowForward,
            }}
          />
        )}
      />
    </Box>
  );
};

export default Pagination;
