import {
  faBarsStaggered,
  faBoxes,
  faChartBar,
  faClipboard,
  faEdit,
  faGear,
  faHome,
  faMap,
  faSignOut,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import contactDev from "../../assets/imgs/ContactDev.png";
import logo from "../../assets/imgs/usaid_logo.png";

export default function Navigation(props) {
  const pathname = window.location.pathname.split("/");
  const navigate = useNavigate();

  const Item = (params) => {
    const [showing, setShowing] = useState(false);

    return (
      <div
        style={{
          backgroundColor: showing ? "#60606010" : "transparent",
        }}
      >
        <div
          onClick={() => {
            if (params.options?.length === 0) {
              navigate(params.url);
            }
            if (params.url === "/logout") {
              localStorage.removeItem("gdfhgfhtkngdfhgfhtkn");
              navigate("/");
            }
          }}
          className={params.link === params.active ? "active" : "item"}
          style={{
            padding: params.showing ? "1em" : "5x 0 5px 0",
            gridTemplateColumns: params.showing ? "20px auto" : "auto",
          }}
        >
          <FontAwesomeIcon icon={params.icon} />
          {params.showing && <p>{params.txt}</p>}
        </div>
        {showing &&
          params.options.length > 0 &&
          params.options.map((item, i) => {
            return (
              <a key={i} href={params.url + "/" + item}>
                {item}
              </a>
            );
          })}
      </div>
    );
  };

  return (
    <div
      style={{ width: props.showing ? "225px" : "fit-content" }}
      className="navigation"
    >
      <div className="logo">
        <img
          style={{ maxWidth: props.showing ? "200px" : "30px" }}
          src={logo}
          alt=""
        />
      </div>
      <div className="line-container">
        <div className="circle-point start-point"></div>
        <div className="circle-point end-point"></div>
      </div>
      <Item
        txt="Home"
        url="/home"
        active={pathname[1]}
        link="home"
        icon={faHome}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Indicators"
        url="/indicators"
        link="indicators"
        active={pathname[1]}
        icon={faChartBar}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/reports"
        link="reports"
        txt="Reports"
        active={pathname[1]}
        icon={faBoxes}
        options={[]}
        showing={props.showing}
      />
      {props.role !== "Regular User" && props.role !== "Guest" && (
        <Item
          url="/mel"
          txt="MEL"
          link="mel"
          active={pathname[1]}
          icon={faClipboard}
          code="&#xf0ea;"
          options={[]}
          showing={props.showing}
        />
      )}

      <Item
        url="/gis"
        txt="GIS"
        link="gis"
        active={pathname[1]}
        icon={faMap}
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Counties"
        url="/counties"
        link="counties"
        active={pathname[1]}
        icon={faBarsStaggered}
        options={[]}
        showing={props.showing}
      />
      <Item
        url="/dataentry"
        txt="Data Entry"
        link="dataentry"
        active={pathname[1]}
        icon={faEdit}
        options={[]}
        showing={props.showing}
      />
      {props.role !== "Regular User" && props.role !== "Guest" && (
        <Item
          url="/buildtool"
          txt="Tool Builder"
          link="buildtool"
          active={pathname[1]}
          icon={faClipboard}
          code="&#xf0ea;"
          options={[]}
          showing={props.showing}
        />
      )}

      <Item
        url="/wp"
        txt="Workplans"
        link="wp"
        active={pathname[1]}
        icon={faTasks}
        options={[]}
        showing={props.showing}
      />
      {props.role !== "Regular User" && props.role !== "Guest" && (
        <Item
          txt="Users"
          url="/users"
          link="users"
          active={pathname[1]}
          icon={faUsers}
          options={[]}
          showing={props.showing}
        />
      )}
      <Item
        txt="Settings"
        url="/settings"
        link="settings"
        active={pathname[1]}
        icon={faGear}
        code="&#xf013;"
        options={[]}
        showing={props.showing}
      />
      <Item
        txt="Signout"
        active={pathname[1]}
        icon={faSignOut}
        url="/logout"
        options={[]}
        showing={props.showing}
      />
      {props.showing && (
        <div className="sysname">
          <h2>MEL-MIS</h2>
        </div>
      )}
      <div className="contactdev">
        <img
          style={{ maxWidth: props.showing ? "200px" : "30px" }}
          src={contactDev}
          alt=""
        />
        <div className="contactdevtxt">
          <div>
            <p className="txt">Developed by</p>
            <p className="osl">Oakar Services</p>
            <p className="devlink">Contact Developer</p>
          </div>
        </div>
      </div>
    </div>
  );
}
