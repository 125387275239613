import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export default function CustomButton(props) {
  return (
    <Box display="flex" my={1} justifyContent="center">
      <Button variant="contained" color="primary" onClick={props.handleClick}>
        {props.value}
      </Button>
    </Box>
  );
}
