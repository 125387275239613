import { faBoltLightning, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import "../../../Styles/preview.scss";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import usaidLogo from "../../../assets/imgs/usaid_logo.png";
import wkwpLogo from "../../../assets/imgs/wkwp_logo.png";

export default function PreviewTAF(props) {
  const pathname = window.location.pathname.split("/");
  const [data, setData] = useState(null);
  const [wkwkw_sig, setWkwpSig] = useState(null);
  const [rep_sig, setRepSig] = useState(null);
  const divRef = useRef();

  const beneficiaryType = ["WSP", "RWE", "County", "WRUA", "Other"];
  const TATypes = [
    "Operational",
    "Management",
    "Engineering",
    "Capacity Building",
    "Finance",
    "Data & Digital Solutions",
  ];
  const TAThroughList = [
    "Training",
    "Grant",
    "Meeting/Coaching/Mentoring",
    "Other",
  ];



  const downloadPdfDocument = async () => {
    // Get references to the page elements
    const page1Element = document.getElementById("page1");
    const page2Element = document.getElementById("page2");

    // Create canvases for each page
    const canvas1 = document.createElement("canvas");
    const canvas2 = document.createElement("canvas");

    canvas1.width = page1Element.offsetWidth;
    canvas1.height = page1Element.offsetHeight;

    canvas2.width = page2Element.offsetWidth;
    canvas2.height = page2Element.offsetHeight;

    // Use html2canvas to capture the content of each page
    const promise1 = html2canvas(page1Element, { scale: 1, canvas: canvas1 });
    const promise2 = html2canvas(page2Element, { scale: 1, canvas: canvas2 });

    // Wait for both promises to resolve
    Promise.all([promise1, promise2]).then((canvases) => {
      const pdf = new jsPDF("p", "mm", "a4");
      // Add the first page to the PDF
      pdf.addImage(
        canvases[0].toDataURL("image/png"),
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );
      // Add a new page to the PDF
      pdf.addPage();
      // Add the second page to the PDF
      pdf.addImage(
        canvases[1].toDataURL("image/png"),
        "JPEG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );
      // Save the PDF
      pdf.save("TAF.pdf");
    });
  };

  useEffect(() => {
    fetch(`/api/taf/${pathname[3]}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (data) {
      getWKWPSig(data?.WKWPRepSignature);
      getERepWPSig(data?.ERepSignature);
    }
  }, [data]);

  function getWKWPSig(file) {
    fetch(`/api/uploads/${file}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        const dataurl = await convertFileToBase64(data);
        setWkwpSig(dataurl);
      })
      .catch((e) => {});
  }

  function getERepWPSig(file) {
    fetch(`/api/uploads/${file}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.blob();
        else throw Error("");
      })
      .then(async (data) => {
        const dataurl = await convertFileToBase64(data);
        setRepSig(dataurl);
      })
      .catch((e) => {});
  }

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="preview">
      <div className="btn">
        <button
          onClick={() => {
            downloadPdfDocument();
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Download PDF
        </button>
      </div>

      <div ref={divRef} id="pdfContent" className="bg">
        <div className="a4potrait" id="page1">
          <div className="logos">
            <img src={usaidLogo} alt="" />
            <img className="wkwp" src={wkwpLogo} alt="" />
          </div>
          <h3>Technical Assistance and monitoring visit form</h3>
          <div className="purpose">
            <h5>
              The purpose of the tool, who uses the tool and the frequency of
              filling the tool.
            </h5>
            <p>
              The purpose of the{" "}
              <em>(Technical Assistance and Monitoring Form)</em> is to record
              technical assistance (TA) provided to the project beneficiaries to
              improve their operations (effectiveness and efficiency). The tool
              provides evidence of TA/support given to the beneficiary over the
              project life. The tool will be used on all monitoring visits. The
              WKWP staff offering the technical assistance will fill in details
              of the TA provided on the form. For accountability purposes, the
              beneficiary receiving the service also signs and stamps the tool
              confirming that they received the service/technical assistance.
              Once complete, the form should be scanned and send to the MEL team
              while the original form is filed in a box file at the county
              offices accordingly.
            </p>
          </div>

          <div className="form-content">
            <p className="b-btm">
              Name of Partner/Beneficiary: <span>{data?.Beneficiary}</span>
            </p>
            <div className="check-boxes b-btm">
              <p>Type of Partner/Beneficiary</p>

              {beneficiaryType &&
                beneficiaryType.map((item, index) => {
                  if (data?.BeneficiaryType === item) {
                    return <CheckBox key={index} label={item} checked={true} />;
                  } else {
                    return (
                      <CheckBox key={index} label={item} checked={false} />
                    );
                  }
                })}
            </div>
            <p className="b-btm">
              Sub-county: <span>{data?.SubCounty}</span>
            </p>
            <p className="b-btm">
              Ward: <span>{data?.Ward}</span>
            </p>
            <p className="b-btm">
              Village/Town: <span>{data?.Village}</span>
            </p>

            <p className="b-btm">
              Date of Visit/TA was provided: (dd/mm/yyyy):{" "}
              <span>{data?.Date}</span>
            </p>

            <div className="dv2 b-btm">
              <p>
                Longitude: <span>{data?.Longitude}</span>
              </p>
              <p>
                Latitude: <span>{data?.Latitude}</span>
              </p>
            </div>
            <div className="dv3 b-btm">
              <p>
                Contact Person Name: <span>{data?.ContactName}</span>
              </p>
              <p>
                Phone: <span>{data?.ContactPhone}</span>
              </p>
              <p>
                Designation: <span>{data?.Designation}</span>
              </p>
            </div>

            <div className="check-boxes b-btm">
              <p>Type of TA provided:</p>
              {TATypes &&
                TATypes.map((item, index) => {
                  const isChecked = data?.TA_Provided.includes(item);
                  return (
                    <CheckBox key={index} label={item} checked={isChecked} />
                  );
                })}
            </div>
            <div className="check-boxes b-btm">
              <p>TA through:</p>
              {TAThroughList &&
                TAThroughList.map((item, index) => {
                  const isChecked = data?.TAThrough.includes(item);
                  return (
                    <CheckBox key={index} label={item} checked={isChecked} />
                  );
                })}
            </div>
          </div>
        </div>

        <div className="a4potrait" id="page2">
          <div className="logos">
            <img src={usaidLogo} alt="" />
            <img className="wkwp" src={wkwpLogo} alt="" />
          </div>
          <div className="desc">
            <h5>
              Brief Description TA provided: (Please describe the TA/purpose of
              the visit. For meetings, trainings events use the participants
              list)
            </h5>
            {data?.TADescription}
          </div>
          <div className="signature">
            <div>
              <h5>Entity Representative</h5>
              <p>
                Name: <span className="b-btm">{data?.ERepName}</span>
              </p>
              <p>
                Designation:{" "}
                <span className="b-btm">{data?.ERepDesignation}</span>
              </p>
              <p>
                Signature/official stamp:{" "}
                <span className="b-btm">
                  <img src={rep_sig} />
                </span>
              </p>
            </div>
            <div>
              <h5>WKWP Representative</h5>
              <p>
                Name: <span className="b-btm">{data?.WKWPRepName}</span>
              </p>
              <p>
                Designation:{" "}
                <span className="b-btm">{data?.WKWPRepDesignation}</span>
              </p>
              <p>
                Signature/official stamp:{" "}
                <span className="b-btm">
                  <img src={wkwkw_sig} />
                </span>
              </p>
            </div>
          </div>
          <h4>
            Note: Signed copies of this form must be kept in a file at the
            respective offices with an accompanying excel template that collates
            the quarterly numbers for the purposes of periodic reporting
          </h4>
        </div>
      </div>
    </div>
  );
}

const CheckBox = (props) => {
  return (
    <>
      {props.checked ? (
        <div>
          <input type="checkbox" checked />{" "}
          <label htmlFor={props.label}>{props.label}</label>
        </div>
      ) : (
        <div>
          <input type="checkbox" disabled />{" "}
          <label htmlFor={props.label}>{props.label}</label>
        </div>
      )}
    </>
  );
};
