import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Select from "../Util/Select";
import WaveLoading from "../Util/WaveLoading";
import RippleLoading from "../Util/RippleLoading.jsx";

export default function MonthWP(props) {
  const { getMonths } = require("../../assets/data/data.js");
  const [data, setData] = useState([]);
  const [year, setYear] = useState("2023");
  const [today, setToday] = useState("");
  const [month, setMonth] = useState("January");
  const [mm, setMM] = useState([]);
  const [dd, setDD] = useState([]);

  useEffect(() => {
    const date = new Date().toISOString().split("T")[0].split("-");
    setYear(date[0]);
    const d = new Date().toISOString().split("T")[0];
    setToday(d);
    const mi = getMonths
      .map((item) => {
        return item.code;
      })
      .indexOf(date[1]);
    if (mi !== -1) {
      setMonth(getMonths[mi].month);
    }
  }, []);

  useEffect(() => {
    let d = [];
    getMonths.map((item) => {
      d.push(item["month"]);
    });
    setMM(d);
    const mi = getMonths
      .map((item) => {
        return item.month;
      })
      .indexOf(month);

    if (mi !== -1) {
      let x = [];
      for (let index = 1; index < getMonths[mi].days + 1; index++) {
        if (index === 1) {
          const d = new Date(`${year}-${getMonths[mi].code}-01`);

          for (let index = 0; index < d.getDay(); index++) {
            x.push(null);
          }
          const dd = index < 10 ? "0" + index : index.toString();
          const date =
            year.toString() + "-" + getMonths[mi].code.toString() + "-" + dd;
          x.push({
            date: date,
          });
        } else {
          const dd = index < 10 ? "0" + index : index.toString();
          const date =
            year.toString() + "-" + getMonths[mi].code.toString() + "-" + dd;
          x.push({
            date: date,
          });
        }
      }
      if (x.length < 35) {
        const size = 35 - x.length;
        for (let index = 0; index < size; index++) {
          x.push(null);
        }
      }
      setDD(x);
    }
  }, [month, year, props.refresh]);

  useEffect(() => {
    if (props.download && data) {
      downloadCSV(data, "monthly work plan summary.csv");
      props.setDownload(false);
    }
  }, [props.download]);

  function downloadCSV(jsonArray, filename) {
    const headerRow = Object.keys(jsonArray[0]).join(",") + "\n";
    const csvContent = jsonArray
      .map((row) => {
        return Object.values(row).join(",");
      })
      .join("\n");
    const fullCSV = headerRow + csvContent;
    const blob = new Blob([fullCSV], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  }

  return (
    <div className="monthwp">
      <div className="month">
        <h4>
          {month}, {year}
        </h4>
        <Select
          setChanged={(v) => {
            setMonth(v);
          }}
          data={mm}
          value={month}
        />
        <Select
          setChanged={(v) => {
            setYear(v);
          }}
          value={year}
          data={["2023", "2024", "2025", "2026"]}
        />
      </div>
      <div className="mlist">
        <div className="dow">
          <h4>Sun</h4>
          <h4>Mon</h4>
          <h4>Tue</h4>
          <h4>Wed</h4>
          <h4>Thu</h4>
          <h4>Fri</h4>
          <h4>Sat</h4>
        </div>
        <div className="calendar">
          {dd.map((item, i) => {
            return (
              <Day
                key={i}
                item={item}
                today={today}
                data={data}
                setData={setData}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

const Day = (props) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDetails, setShowingDetails] = useState(false);

  useEffect(() => {
    if (props.item !== null && data === null) {
      setLoading(true);
      setData(null);
      fetch(`/api/workplan/searchsingledate/${props.item.date}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setData(data);
          if (data) {
            let x = props.data;
            x.push({
              Date: props.item.date,
              NoofCounties: data.Counties,
              NumberofStaff: data.Staff,
              Type: data.Type,
              WPs: data.WPs,
            });
            props.setData(x);

            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [data, props, props.item]);

  return (
    <>
      {props.item === null ? (
        <div
          style={{
            backgroundColor: "#60606010",
          }}
          className="day mob"
        ></div>
      ) : (
        <div
          onClick={() => {
            setShowingDetails(true);
          }}
          className="day"
        >
          <h6>{props.item.date.split("-")[2]}</h6>

          {data && data?.WPs > 0 && (
            <>
              <h4>Work plans: {data ? data?.WPs : 0}</h4>
              <h4>Staff: {data ? data?.Staff : 0}</h4>
              <br />
            </>
          )}
          {loading && <RippleLoading />}
        </div>
      )}
      {showDetails && [props.item] && (
        <DetailsPopup
          setShowing={setShowingDetails}
          item={props.item}
          setRefresh={props.setRefresh}
          setLoading={props.setLoading}
          refresh={props.refresh}
        />
      )}
    </>
  );
};

const DetailsPopup = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props.item !== null && data === null) {
      props.setLoading(true);
      setData(null);
      fetch(`/api/workplan/searchdateall/${props.item.date}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else throw Error();
        })
        .then((data) => {
          setData(data);
          props.setLoading(false);
        })
        .catch((e) => {
          props.setLoading(false);
        });
    }
  }, []);

  return (
    <div className="dailypopup">
      {data && (
        <div className="card">
          <FontAwesomeIcon
            onClick={() => {
              props.setShowing(false);
            }}
            className="fa-times"
            icon={faXmark}
          />
          <h3>{props.item.date} Work Plans</h3>
          <hr />
          <div className="content">
            <Row data={data} />
          </div>
        </div>
      )}
    </div>
  );
};

const Row = (props) => {
  const colors = ["#FFF1E8", "#D7F3FF", "#FFF1F1", "#B5CB99", "#F1F4FF"];
  const [color, setColor] = useState("#FFF8C9");

  return (
    <div>
      <div className="row">
        <p>No.</p>
        <p>Staff</p>
        <p>Type</p>
        <p>Stakeholder</p>
        <p>County</p>
      </div>
      {props.data?.map((item, index) => {
        return (
          <div className="row" key={index}>
            <p>{index + 1}</p>
            <p>{item.Name}</p>
            <p>{item.Type}</p>
            <p>{item.Stakeholder}</p>
            <p>{item.County}</p>
          </div>
        );
      })}
    </div>
  );
};
