import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#002F6C", // Replace this with your desired primary color
      light: "#0064B6",
      // #40679e FF6600
    },
    secondary: {
      main: "#BA0C2F", // Replace this with your desired primary color
    },
  },
});

export default theme;
