import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "../../../Styles/dataentry.scss";
import UpdateARFDetails from "./UpdateARFDetails";
import UpdateParticipants from "./UpdateParticipants";

export default function ARFUpdate(props) {
  const [active, setActive] = useState("Activity Details");
  const [activityID, setActiveID] = useState(null);
  const [editing, setEditing] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  const pathname = window.location.pathname.split("/");
  const id = pathname[3];

  useEffect(() => {
    const e = localStorage.getItem("arfediting");
   
    setEditing(e);
  }, [active]);

  useEffect(() => {
    setLoading(true);
    fetch(`/api/arf/${id}`, {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, [editing, active]);

  return (
    <div className="dataentry">
      <div className="new">
        <div className="topbar">
          <h4>Update Activity Registration Form</h4>
          <p
            onClick={() => {
              window.location.href = "/dataentry/arf-list";
            }}
          >
            <FontAwesomeIcon icon={faArrowRight} /> List
          </p>
        </div>
        <div className="arf">
          {active === "Activity Details" && (
            <UpdateARFDetails
              data={data}
              setActive={setActive}
              editing={editing}
            />
          )}
        </div>
      </div>
    </div>
  );
}
