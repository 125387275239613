import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Divider,
  Card,
  CardContent,
  Box,
  Chip,
} from "@mui/material";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../Util/Loading";
import { Close, Delete } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
export default function SelectedIndicator(props) {
  const [clicked, setClicked] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (props?.indicatorDetails) {
      props.setLoading(true);
      fetch(`/api/indicatorsmel/code/${props?.indicatorDetails?.Code}`, {
        method: "get",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 401) {
            navigate("/");
            return;
          }
          return res.json();
        })
        .then((data) => {
          props.setLoading(false);
          console.log(data);
          setData(data);
        })
        .catch((error) => {
          props.setLoading(false);
        });
    }
  }, [props?.indicatorDetails, refresh]);

  const Item = (params) => {
    const handleDelete = (id) => {
      fetch(`/api/indicatorsmel/delete/${id}`, {
        method: "delete",
        credentials: "include",
      })
        .then((res) => {
          if (res.status === 401) {
            navigate("/");
            return;
          }
          return res.json();
        })
        .then((data) => {
          if (data.success) {
            params.setRefresh(!params.refresh);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    return (
      <Card
        sx={{
          p: 2,
          borderRadius: 2,
          boxShadow: "0px 4px 10px 0px #0000001A",
          my: 1,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Chip label={params?.item?.Date} />
          <Typography variant="body2" color="textPrimary">
            {params?.item?.Performance}
          </Typography>
          <IconButton
            size="small"
            onClick={() => {
              handleDelete(params?.item?.ID);
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      </Card>
    );
  };

  return (
    <>
      <DialogContent>
        <Typography variant="h6" color="primary.main">
          {props?.indicatorDetails?.Code}
        </Typography>
        <Typography variant="body2">
          {props?.indicatorDetails?.Description}
        </Typography>
        <br />
        <Typography mt={2} variant="title" color="primary.main">
          Performance
        </Typography>
        <Divider sx={{ mb: 2 }} />

        {data &&
          data.length > 0 &&
          data.map((item, i) => {
            return (
              <Item
                key={i}
                item={item}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            );
          })}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setClicked(true);
          }}
        >
          Update Performance
        </Button>
      </DialogActions>
      {clicked && (
        <UpdatePopup
          setClicked={setClicked}
          setRefresh={setRefresh}
          refresh={refresh}
          indicatorDetails={props.indicatorDetails}
        />
      )}
    </>
  );
}

const UpdatePopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const performance = useRef();
  const date = useRef();
  const navigate = useNavigate();
  
  const updateBeneficiary = () => {
    const body = {
      Code: props.indicatorDetails.Code,
      Performance: performance.current.value,
      Date: date.current.value,
    };

    let valid = true;
    const v = Object.values(body);

    v.map((i) => {
      if (i === "" || i === null) {
        valid = false;
      }
    });

    if (!valid) return setError("All fields are required!");

    setError("");

    setLoading(true);
    fetch(`/api/indicatorsmel/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.status === 401) {
          navigate("/");
          return;
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            props.setClicked(false);
            props.setRefresh(!props.refresh);
          }, 2000);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={true} onClose={() => props.setClicked(false)}>
      <DialogTitle>
        {props?.indicatorDetails?.Code}
        <IconButton
          onClick={() => {
            props.setClicked(false);
          }}
          style={{ position: "absolute", right: 8, top: 8 }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <TextField
            inputRef={date}
            defaultValue={props?.indicatorDetails?.Date}
            type="date"
            label="Date *"
            fullWidth
            margin="normal"
          />
          <TextField
            inputRef={performance}
            defaultValue={props?.indicatorDetails?.Performance}
            type="number"
            label="Performance *"
            fullWidth
            margin="normal"
          />
          <Typography color="error">{error}</Typography>
        </form>
        {loading && <Loading />}
      </DialogContent>
      <DialogActions>
        <Button onClick={updateBeneficiary} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
